import {
  lazy,
  Fragment,
  Suspense,
  type ReactNode,
  type FC,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Divider,
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  UserAvatarWithNickname,
  selectHasSCForbiddenTag,
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  useLayoutSelector,
  selectPageHeaderVariant1LogoImgSrc,
  selectPageHeaderVariant2LogoImgSrc,
} from '@entities/layouts';
import {
  NotificationsSidebar,
  NotificationsSidebarButton
} from '@entities/messages';
import {
  BuyCoinsButton
} from '@features/buy-coins';
import {
  closeSidebarMenu
} from '../model';
import {
  SidebarMenu
} from './sidebar-menu.component';
import {
  SidebarMenuButton
} from './sidebar-menu-button.component';

const AppLogo = lazy(() => import('@shared/ui').then((module) => ({ default: module.AppLogo })));

export type PageHeaderProps = {
  mobileToggleCoinsSlot?: ReactNode;
  regularToggleCoinsSlot?: ReactNode;
};

export const PageHeader: FC<PageHeaderProps> = ({
  mobileToggleCoinsSlot,
  regularToggleCoinsSlot,
}) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down(912));

  const dispatch = useDispatch();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const hasSCForbiddenTag = useSessionSelector(selectHasSCForbiddenTag);
  const variant1LogoImgSrc = useLayoutSelector(selectPageHeaderVariant1LogoImgSrc);
  const variant2LogoImgSrc = useLayoutSelector(selectPageHeaderVariant2LogoImgSrc);

  const handleCloseNotificationsSidebar = (): void => {
    dispatch(closeSidebarMenu());
  };

  return (
    <Stack
      component='header'
      justifyContent='center'
      alignItems='center'
      sx={({ palette }) => ({
        zIndex: 5,
        width: '100%',
        paddingInline: isMobile ? 2 : 10,
        backgroundColor: palette.common[475],
      })}>
      <SidebarMenu notificationsSidebarButtonSlot={<NotificationsSidebarButton onClick={handleCloseNotificationsSidebar} />} />
      <NotificationsSidebar />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          maxWidth: 1280,
          height: 68,
          gap: 0.5
        }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          gap={1.25}>
          {isMobile ? (
            variant1LogoImgSrc && (
              <Suspense>
                <AppLogo
                  src={variant1LogoImgSrc}
                  sx={{
                    width: 60,
                    height: 30,
                  }}
                />
              </Suspense>
            )) : (
            <Fragment>
              <SidebarMenuButton />
              {variant2LogoImgSrc && (
                <Suspense>
                  <AppLogo
                    src={variant2LogoImgSrc}
                    sx={{
                      width: 210,
                      height: 35.4,
                    }}
                  />
                </Suspense>
              )}
            </Fragment>
          )}
        </Stack>
        {mobileToggleCoinsSlot}
        {isAuthenticated && (
          <Stack
            direction='row'
            alignItems='center'
            gap={2}>
            <Stack
              direction='row'
              alignItems='center'
              gap={0.5}>
              {regularToggleCoinsSlot}
              {!hasSCForbiddenTag && <BuyCoinsButton>Buy</BuyCoinsButton>}
            </Stack>
            {!isMobile && (
              <Fragment>
                {(!mobileToggleCoinsSlot && hasSCForbiddenTag) && <Divider orientation='vertical' sx={{ marginBlock: 1 }} />}
                <UserAvatarWithNickname />
                {!hasSCForbiddenTag && <Divider orientation='vertical' sx={{ marginBlock: 1 }} />}
                {!hasSCForbiddenTag && <NotificationsSidebarButton onClick={handleCloseNotificationsSidebar} />}
              </Fragment>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

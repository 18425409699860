import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  paymentsApiSlice
} from '../api';
import {
  mapPaymentMethodOrderSet
} from '../lib';
import {
  type PaymentMethod,
  type PaymentsState
} from './types';

const initialState: PaymentsState = {
  paymentMethodOrderSet: [],
  paymentMethod: null,
  recentlyUsedPaymentMethod: null,
  type: null,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    selectPaymentMethod: (state: PaymentsState, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
    },
    storeRecentlyUsedPaymentMethod: (state: PaymentsState, action: PayloadAction<PaymentMethod>) => {
      state.recentlyUsedPaymentMethod = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore,
      ({ recentlyUsedPaymentMethod }: PaymentsState) => ({ ...initialState, recentlyUsedPaymentMethod })
    );
    builder.addMatcher(
      paymentsApiSlice.endpoints.getPaymentMethodOrderSets.matchFulfilled,
      // TODO: fix any
      (state: PaymentsState, action: PayloadAction<any>) => {
        if (action.payload) {
          // TODO: fix any
          state.paymentMethodOrderSet = action.payload.data.map(mapPaymentMethodOrderSet) as any;
        }
      }
    );
  },
});

export const {
  selectPaymentMethod,
  storeRecentlyUsedPaymentMethod
} = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;
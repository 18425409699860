import {
  FC,
  Fragment,
  PropsWithChildren
} from 'react';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '../model';

// eslint-disable-next-line
export const AuthenticatedOnlyContent: FC<PropsWithChildren<{}>> = ({ children }) => {
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  return isAuthenticated ? (
    <Fragment>{children}</Fragment>
  ) : null;
};

import {
  memo,
  type FC
} from 'react';
import {
  Typography,
} from '@mui/joy';
import {
  CoinPackageVariantEnum
} from '../lib';

export type FreeSweepCoinLabelProps = {
  variant: CoinPackageVariantEnum;
  smallLabel?: boolean
};

export const FreeSweepCoinLabel: FC<FreeSweepCoinLabelProps> = memo(({ variant, smallLabel }) => (
  <Typography
    sx={({ palette }) => ({
      fontSize: smallLabel ? 14 : 18,
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: ({
        [CoinPackageVariantEnum.Default]: palette.common[300],
        [CoinPackageVariantEnum.WelcomeRed]: palette.common.error,
        [CoinPackageVariantEnum.MostPopular]: palette.common[700],
        [CoinPackageVariantEnum.WelcomeYellow]: palette.common[1085],
      }[variant ?? CoinPackageVariantEnum.Default])
    })}>
    free sc
  </Typography>
));
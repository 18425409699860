import {
  useRef,
  type FC,
  useEffect
} from 'react';
import _PaymentIQCashier, {
  type IPiqCashierApiMethods,
  type Environment
} from 'paymentiq-cashier-bootstrapper';
import {
  Stack,
  Typography,
  styled,
} from '@mui/joy';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  type CoinPackage
} from '@entities/coins';
import {
  type CheckoutResult
} from '@entities/payments';
import {
  mapCashierConfig
} from '../lib';
import {
  ENVIRONMENT,
  MERCHANT_ID
} from '../config';

const Container = styled(Stack)(({ theme }) => ({
  minHeight: 650,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common[925],
}));

const CardContent = styled(Stack)(({ theme }) => ({
  padding: 24,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.down(490)]: {
    paddingTop: 16,
    paddingInline: 16,
    paddingBottom: 32,
  },
  [theme.breakpoints.down(390)]: {
    paddingInline: 8,
    paddingBottom: 16,
  },
}));

const CardHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: 23
}));

const CheckoutIframe = styled('div')(() => ({
  width: '100%',
  height: '100%',
  border: 'none',
  backgroundColor: 'red',
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export type PaymentProcessorPaymentIQProps = {
  partyId: string;
  sessionKey: string;
  purchase: CoinPackage;
  onClose?: () => void;
  onStart?: () => void;
  onSuccess?: (result?: CheckoutResult) => void;
  onFail?: (result?: CheckoutResult) => void;
};

export const PaymentProcessorPaymentIQ: FC<PaymentProcessorPaymentIQProps> = ({
  onFail,
  onStart,
  onClose,
  onSuccess,
  partyId,
  purchase,
  sessionKey,
}) => {
  const { id, originPrice = 0 } = purchase;
  const cashierRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cashierRef.current) {
      const config = mapCashierConfig({
        environment: ENVIRONMENT as Environment,
        merchantId: MERCHANT_ID,
        sessionId: sessionKey,
        packageId: id.toString(),
        amount: originPrice.toString(),
        userId: partyId.toString(),
        containerHeight: `${cashierRef.current?.clientHeight}px`,
        containerWidth: `${cashierRef.current?.clientWidth}px`,
      });

      new _PaymentIQCashier('#cashier',
        config,
        (api: IPiqCashierApiMethods) => {
          api.on({
            cashierInitLoad: () => onStart?.(),
            success: () => onSuccess?.({ amount: originPrice.toString() }),
            failure: () => onFail?.(),
            validationFailed: () => onFail?.(),
          });
        });
    }
    // eslint-disable-next-line
  }, [cashierRef]);

  return (
    <Container>
      <CardContent>
        <CardHeader>
          <Typography
            level='h3'
            fontSize='1.25rem'>
            Checkout
          </Typography>
          {onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </CardHeader>
        <Stack
          ref={cashierRef}
          direction='column'
          width='100%'
          height='100%'>
          <CheckoutIframe id='cashier' />
        </Stack>
      </CardContent>
    </Container>
  );
};
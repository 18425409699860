import {
  FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  Button,
  type ButtonProps
} from '@shared/ui';

export type BuyCoinsButtonProps = Pick<ButtonProps, 'color' | 'size' | 'children'>
export const BuyCoinsButton: FC<BuyCoinsButtonProps> = ({
  children,
  ...props
}) => {
  const navigate = useNavigate();
  const handleNavigate = (): void => {
    navigate('/coin-store', { replace: true });
  };
  return (
    <Button
      {...props}
      size='md'
      color='success'
      onClick={handleNavigate}>
      {children}
    </Button>
  );
};
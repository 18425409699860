import {
  useState,
  useEffect,
  type FC,
} from 'react';
import {
  Skeleton,
  Stack,
  styled,
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  CheckoutWorldPayForm,
  type CheckoutResult,
} from '@entities/payments';
import {
  type FullProfile,
} from '@entities/session';
import {
  CoinPackage
} from '@entities/coins';
import {
  type PreparePurchasePackageResponse,
} from '../api';
import {
  useLazyPreparePurchasePackageQuery,
} from '../api';

const Container = styled(Stack)(({ theme }) => ({
  minHeight: 650,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common[925],
}));

type AccountBillingDetails = {
  billingAddress1: Maybe<string>;
  billingCity: Maybe<string>;
  billingCountry: Maybe<string>;
  billingEmail: Maybe<string>;
  billingFirstName: Maybe<string>;
  billingLastName: Maybe<string>;
  billingPhone: Maybe<string>;
  billingState: Maybe<string>;
  billingPostalCode: Maybe<string>;
};

type PreparedPurchaseData = Partial<PreparePurchasePackageResponse>;

export type PaymentProcessorWorldPayProps = {
  purchase: CoinPackage;
  playerProfile: Maybe<FullProfile>;
  onClose?: () => void;
  onStart?: () => void;
  onFail?: (result?: CheckoutResult) => void;
  onSuccess?: (result?: CheckoutResult) => void;
};

export const PaymentProcessorWorldPay: FC<PaymentProcessorWorldPayProps> = ({
  onFail,
  onStart,
  onClose,
  onSuccess,
  purchase,
  playerProfile,
}) => {

  const {
    id,
    originCurrency,
    originPrice = 0,
    brandId
  } = purchase;

  const accountBillingDetails: AccountBillingDetails = {
    billingAddress1: playerProfile?.address,
    billingCity: playerProfile?.city,
    billingCountry: playerProfile?.country,
    billingEmail: playerProfile?.email,
    billingFirstName: playerProfile?.firstName,
    billingLastName: playerProfile?.lastName,
    billingPhone: playerProfile?.phone,
    billingState: playerProfile?.province,
    billingPostalCode: playerProfile?.postalCode
  };

  const toast = useToaster();
  const [preparedPurchaseData, setPreparedPurchaseData] = useState<Maybe<PreparedPurchaseData>>();
  const [preparePurchasePackageQuery] = useLazyPreparePurchasePackageQuery();

  useEffect(() => {
    const processDeposit = async (
      currency: string = 'USD',
      amount: number = 0,
      brandId: number = 0,
      packageId: number = 0,): Promise<void> => {
      () => onStart?.();

      const preparePurchasePackageResponse = await preparePurchasePackageQuery({
        paymentMethod: PaymentMethodKeyEnum.WorldPayCNPCard,
        packageId,
        currency,
        brandId,
        amount,
      });

      const { status, ...preparePurchasePackageProps } = preparePurchasePackageResponse.data as PreparePurchasePackageResponse;
      const isSuccess = [OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(status);
      if (isSuccess) setPreparedPurchaseData(preparePurchasePackageProps);
      else toast.error({ message: status, autoHideDuration: 5000 });
    }

    const isAllowToProcessDeposit = id && originCurrency && originPrice && brandId;
    if (isAllowToProcessDeposit) processDeposit(originCurrency, originPrice, brandId, id);

    return () => {
      setPreparedPurchaseData(null);
    };
    // eslint-disable-next-line
  }, [id, brandId, originPrice, originCurrency]);

  return (
    <Container>
      {preparedPurchaseData && (
        <CheckoutWorldPayForm
          amount={originPrice}
          billingInfo={accountBillingDetails}
          checkoutInfo={{
            paymentId: preparedPurchaseData?.paymentId ?? 0,
            payPageId: preparedPurchaseData?.payPageId ?? '',
            merchantTxId: preparedPurchaseData?.merchantTxId ?? 0,
            reportGroup: preparedPurchaseData?.reportGroupId ?? '',
          }}
          onCheckoutSuccess={onSuccess}
          onCheckoutFail={onFail}
          onClose={onClose}
        />
      )}
    </Container>
  );
};
import {
  mapper
} from '@shared/lib';
import {
  GamesPageState
} from '../model';

export type MapGamesPageState = (data: any) => GamesPageState & {
  categoryIds: Array<number>
};
export const mapGamesPageState: MapGamesPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  {
    name: 'categoryIds',
    from: 'data.attributes.categories.data',
    transform: (data: Array<any>) => data.map(({ id }) => id)
  }
]);

import {
  FC,
  useState,
  useEffect
} from 'react';
import {
  Stack,
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  CheckoutResult,
  CheckoutWorldPayForm
} from '@entities/payments';
import {
  selectFullProfile,
  useSessionSelector,
} from '@entities/session';
import {
  type PrepareWithdrawalResponse,
  type ProcessWithdrawalResponse,
  useLazyCancelWithdrawalQuery,
  useLazyProcessWithdrawalQuery,
  useLazyPrepareWithdrawalQuery
} from '../api';
import {
  WorldPayDebitCardRestrictions
} from './worldpay-debit-card-restrictions.component';

export type ConfirmCoinRedemptionWorldPayFormProps = {
  redeemAmount: number
  onClose?(): void
  onStart?: (amount: string | number) => void;
  onFail: (redeemResult?: CheckoutResult) => void;
  onSuccess: (redeemResult?: CheckoutResult) => void;
};

export const ConfirmCoinRedemptionWorldPayFrom: FC<ConfirmCoinRedemptionWorldPayFormProps> = ({
  redeemAmount,
  onFail,
  onClose,
  onStart,
  onSuccess,
}) => {
  const toast = useToaster();
  const userProfile = useSessionSelector(selectFullProfile);

  const [prepareWithdrawalQuery] = useLazyPrepareWithdrawalQuery();
  const [processWithdrawalQuery] = useLazyProcessWithdrawalQuery();
  const [cancelWithdrawal] = useLazyCancelWithdrawalQuery();

  const [processWithdrawal, setProcessWithdrawal] = useState<Maybe<Partial<ProcessWithdrawalResponse>>>(null);

  useEffect(() => {
    const processWithdrawal = async (amount: number): Promise<void> => {
      onStart?.(amount);

      const paymentMethod = PaymentMethodKeyEnum.WorldPayCNPCard;

      const prepareWithdrawalResponse = await prepareWithdrawalQuery({ paymentMethod });
      const { status: prepareWithdrawalStatus, conversionId } =
        prepareWithdrawalResponse.data as PrepareWithdrawalResponse;

      if (
        ![OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(
          prepareWithdrawalStatus
        )
      ) {
        toast.error({ message: prepareWithdrawalStatus, autoHideDuration: 5000 });
        await handleClose();
        return;
      }

      const processWithdrawalResponse = await processWithdrawalQuery({ amount, conversionId, paymentMethod });
      const { status: processWithdrawalStatue, ...processWithdrawal } =
        processWithdrawalResponse.data as ProcessWithdrawalResponse;

      if (
        ![OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite].includes(
          processWithdrawalStatue
        )
      ) {
        toast.error({ message: processWithdrawalStatue, autoHideDuration: 5000 });
        await handleClose();
        return;
      } else {
        setProcessWithdrawal(processWithdrawal);
      }
    };

    if (redeemAmount) processWithdrawal(redeemAmount);

    // eslint-disable-next-line
  }, []);

  const handleClose = async (): Promise<void> => {
    await cancelWithdrawal({ paymentId: processWithdrawal?.paymentId });
    onClose?.();
  };

  const handleRedeemFail = (depositResult?: { amount: string; cardNumber?: string; referenceId?: string }): void => {
    onFail?.(depositResult);
  };

  return (
    <Stack
      sx={({ breakpoints, palette }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 488,
        minHeight: 700,
        backgroundColor: palette.common[925],
        [breakpoints.down(491)]: {
          borderRadius: 0,
          boxShadow: 'none',
          minHeight: 'initial',
        },
      })}>
      {processWithdrawal && (
        <CheckoutWorldPayForm
          amount={redeemAmount}
          submitButtonLabelSlot={`Redeem ${formatCurrency(redeemAmount)}`}
          disclaimerSlot={<WorldPayDebitCardRestrictions />}
          checkoutInfo={{
            merchantTxId: processWithdrawal?.merchantTxId ?? 0,
            paymentId: processWithdrawal?.paymentId ?? 0,
            payPageId: processWithdrawal?.payPageId ?? '',
            reportGroup: processWithdrawal?.reportGroupId ?? '',
          }}
          billingInfo={{
            billingAddress1: userProfile?.address,
            billingCity: userProfile?.city,
            billingCountry: userProfile?.country,
            billingEmail: userProfile?.email,
            billingFirstName: userProfile?.firstName,
            billingLastName: userProfile?.lastName,
            billingPhone: userProfile?.phone,
            billingState: userProfile?.province,
            billingPostalCode: userProfile?.postalCode,
          }}
          onCheckoutFail={handleRedeemFail}
          onCheckoutSuccess={onSuccess}
          onClose={handleClose}
        />
      )}
    </Stack>
  );
};

import {
  mapper,
  mapActionItem,
  mapCarouselSliderItem,
} from '@shared/lib';
import {
  mapGameCategory
} from '@entities/games';
import {
  type LobbyPageState
} from '../model';
import {
  mapPlayInThreeSteps
} from './map-play-in-three-steps';

type MapLobbyPageState = (data: any) => LobbyPageState;
export const mapLobbyPageState: MapLobbyPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  {
    name: 'playInThreeSteps',
    from: 'data.attributes.playInThreeSteps',
    transform: (playInThreeStepsData: any) => mapPlayInThreeSteps(playInThreeStepsData),
  },
  {
    name: 'slider',
    from: 'data.attributes.carouselSlider',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapCarouselSliderItem),
  },
  {
    name: 'gamesByCategoriesBlockTop',
    from: 'data.attributes.gamesByCategoriesBlockTop',
    transform: (gameCategoriesData: Maybe<Array<{ categ: { data: any } }>>) => {
      return gameCategoriesData?.map(({ categ }) => mapGameCategory(categ.data));
    }
  },
  {
    name: 'gamesByCategoriesBlockBottom',
    from: 'data.attributes.gamesByCategoriesBlockBottom',
    transform: (gameCategoriesData: Maybe<Array<{ categ: { data: any } }>>) => {
      return gameCategoriesData?.map(({ categ }) => mapGameCategory(categ.data));
    }
  },
  {
    name: 'tournamentWidgetTitle',
    from: 'data.attributes.tournamentWidgetTitle'
  },
  {
    name: 'action',
    from: 'data.attributes.action',
    transform: (data: Array<any>) => {
      if (!Array.isArray(data)) return null;
      return data?.map(actionItem => mapActionItem(actionItem));
    }
  },
]);

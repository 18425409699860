import {
  mapper
} from '@shared/lib';
import {
  mapTextWithInlineLink
} from '@shared/lib';
import {
  LobbyPagePlayInThreeSteps
} from '../model';
import {
  mapHomePageStepPlayItem
} from './map-play-in-three-step-item';

type MapPlayInThreeSteps = (data: any) => LobbyPagePlayInThreeSteps;
export const mapPlayInThreeSteps: MapPlayInThreeSteps = mapper([
  {
    name: 'title',
    from: 'textWithInlineLink',
    transform: (titleData: any) => mapTextWithInlineLink(titleData),
  },
  {
    name: 'steps',
    from: 'steps',
    transform: (stepsData: Array<any>) => stepsData?.map(mapHomePageStepPlayItem)
  }
]);
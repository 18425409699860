import {
  useContext
} from 'react';
import {
  ToastContext
} from './context';

export const useToaster = () => {
  const context = useContext(ToastContext);
  return context;
};
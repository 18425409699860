import * as Yup from 'yup';

export const checkoutAptPayFormSchema = Yup.object().shape({
  bankName: Yup.string().required('Bank Name is required'),
  bankAccountNumber: Yup.string()
    .matches(/^\d{5,17}$/, 'ACH Account Number must be between 5 and 17 digits long')
    .required('ACH Account Number is required'),
  bankTransitNumber: Yup.string()
    .matches(/^\d{9}$/, 'ACH Routing Number must be a 9-digit ABA routing number')
    .required('ACH Routing Number is required'),
  bankAccountType: Yup.string()
    .oneOf(['checking', 'saving'], 'Account Type must be either checking or saving')
    .required('Account Type is required'),
});


import { mapper } from '@shared/lib';
import { type Footer } from '../model';
import { mapPageFooterNavigationItem } from './map-page-footer-navigation-item';
import { mapLogo } from './map-logo';
import { mapFollowUs } from './map-follow-us';

type MapPageFooter = (data: any) => Footer;
export const mapPageFooter: MapPageFooter = mapper([
  { name: 'promotionLegalNote', from: 'promotionLegalNote' },
  {
    name: 'logo',
    from: 'logo',
    transform: (logoData: any) => mapLogo(logoData),
  },
  {
    name: 'followUs',
    from: 'followUs',
    transform: (followUs: any) => mapFollowUs(followUs),
  },
  {
    name: 'navigationItems',
    from: 'navigationItems',
    transform: (navigationItemsData: any[]) => navigationItemsData?.map(mapPageFooterNavigationItem),
  },
  { name: 'disclaimer', from: 'disclaimer' },
]);
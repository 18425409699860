import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  selectUserStatus,
  sessionSlice,
  type SessionState
} from '@entities/session';
import {
  PromotionCard
} from '@entities/promotions';
import {
  promotionsPageSlice,
  type PromotionsPageState
} from './slice'

type RootState = {
  [promotionsPageSlice.name]: PromotionsPageState;
  [sessionSlice.name]: SessionState;
};

export const selectPromotionsPageHeading = (state: RootState): Maybe<string> => (
  state[promotionsPageSlice.name]?.heading
);

export const selectPromotionsPageMetaTitle = (state: RootState): Maybe<string> => (
  state[promotionsPageSlice.name]?.title
);

export const selectPromotionsPageMetaDescription = (state: RootState): Maybe<string> => (
  state[promotionsPageSlice.name]?.description
);

export const selectPromotionsPageMetaKeywords = (state: RootState): Maybe<string> => (
  state[promotionsPageSlice.name]?.keywords
);

export const selectPromotionsPageMetaCanonical = (state: RootState): Maybe<string> => (
  state[promotionsPageSlice.name]?.canonical
);

export const selectPromotionCards = createSelector(
  selectUserStatus,
  (state: RootState): Array<PromotionCard> => (
    state[promotionsPageSlice.name]?.promotionCards ?? []
  ),
  (userState, promotionItem) => {
    const result = promotionItem.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });
    return result;
  }
);

export const usePromotionsPageSelector: TypedUseSelectorHook<RootState> = useSelector;
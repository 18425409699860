import {
  lazy,
  memo,
  Suspense,
  type FC,
} from 'react'
import {
  type ToastColor
} from '../lib'

const InfoOutlinedIcon = lazy(() => import('@mui/icons-material/InfoOutlined'));
const TaskAltOutlinedIcon = lazy(() => import('@mui/icons-material/TaskAltOutlined'));

type ToastIconProps = {
  type: ToastColor
};

export const ToastIcon: FC<ToastIconProps> = memo(({ type }) => {
  const icon = ({
    danger: <InfoOutlinedIcon sx={{ fontSize: '1.25rem' }} />,
    success: <TaskAltOutlinedIcon sx={{ fontSize: '1.25rem' }} />
  })[type];
  return (
    <Suspense>
      {icon}
    </Suspense>
  );
});
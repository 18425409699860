import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  TournamentStatusEnum,
  type TournamentsPayload,
} from '../model';
import {
  tournamentsApiSlice
} from './slice';

export const fetchTournaments = createAsyncThunk<
  TournamentsPayload,
  { isAuth: boolean; vipStatus?: string }
>(
  'fetch-tournaments',
  async ({ isAuth, vipStatus }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      dayjs.extend(utc);

      const brandId = 1;
      const endDate = dayjs()
        .add(14, 'days')
        .format('YYYY-MM-DD');

      const queryParams = new URLSearchParams({
        endDate: endDate,
        brandId: brandId.toString()
      });
      queryParams.append('status_in', TournamentStatusEnum.Active);
      queryParams.append('status_in', TournamentStatusEnum.Pending);

      const baseEndpoint = isAuth
        ? tournamentsApiSlice.endpoints.getPrivateTournaments
        : tournamentsApiSlice.endpoints.getPublicTournaments;

      const result = await dispatch(baseEndpoint
        .initiate({ queryParams: queryParams.toString() }))
        .unwrap();

      if (!result)
        return rejectWithValue(new Error('Fail to fetch tournaments'));

      return fulfillWithValue({ tournaments: result, vipStatus });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

import {
  Middleware
} from '@reduxjs/toolkit';
import {
  isDefined
} from '@shared/lib';
import {
  SessionState,
  sessionSlice
} from '@entities/session';

export const keepReturnToPathAfterSignInMiddleware: Middleware =
  ({ getState }) => next => (action: unknown) => {
    const state = getState() as { [sessionSlice.name]: SessionState };

    const isNotAuthenticated = !isDefined(state[sessionSlice.name].shortProfile);
    const isPersistRehydrate = (action as { type: string }).type === 'persist/REHYDRATE';

    if (isNotAuthenticated && isPersistRehydrate) {
      const currentPath = location.pathname;
      const searchParams = location.search;

      if (currentPath !== '/login') {
        const returnTo = `${currentPath}${searchParams}`;
        sessionStorage.setItem('returnTo', returnTo);
      }
    }
    return next(action);
  };

import {
  memo,
  type FC
} from 'react';
import {
  Typography
} from '@mui/joy';
import {
  CoinPackageVariantEnum
} from '../lib';

export type CoinPackageTitleProps = {
  text?: Maybe<string>;
  variant?: CoinPackageVariantEnum;
};

export const CoinPackageTitle: FC<CoinPackageTitleProps> = memo(({ text, variant }) => text && (
  <Typography
    sx={({ palette }) => ({
      top: 0,
      left: 0,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '18px',
      fontStyle: 'normal',
      borderBottomRightRadius: 8,
      paddingBlock: .5,
      paddingInline: 2.5,
      position: 'absolute',
      background: ({
        [CoinPackageVariantEnum.WelcomeRed]: palette.common.error,
        [CoinPackageVariantEnum.MostPopular]: palette.common[825],
        [CoinPackageVariantEnum.WelcomeYellow]: palette.common[1060],
        [CoinPackageVariantEnum.Default]: 'inherit',
      }[variant ?? CoinPackageVariantEnum.Default])
    })}>
    {text}
  </Typography >
));
import {
  FC
} from 'react';
import {
  Stack,
  StackProps,
  Box,
  Typography
} from '@mui/joy';

export type PaymentMethodsCardProps = StackProps & {
  label: string;
};

export const PaymentMethodsCard: FC<PaymentMethodsCardProps> = ({
  label,
  children,
  ...props
}) => (
  <Stack gap='8px' {...props}>
    <Typography
      sx={{
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px'
      }}>
      {label}
    </Typography>
    <Box
      sx={{
        gap: '8px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(156px, 1fr))'
      }}>
      {children}
    </Box>
  </Stack>
)
import {
  ComponentType
} from 'react';
import {
  GtmProvider
} from '@features/collect-analytics';
import {
  GTM_ID
} from '@shared/config';

export const withGtm = <P extends object>(
  WrappedComponent: ComponentType<P>
) => (props: P) => (
  <GtmProvider gtmId={GTM_ID}>
    <WrappedComponent {...props} />
  </GtmProvider>
);
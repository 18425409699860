import {
  SESSION_TAG,
  baseOmegaApi,
} from '@shared/api';
import {
  type OmegaApiResponse
} from '@shared/api';
import {
  type Challenge
} from '../model';

export type GetChallengesResponse = Partial<OmegaApiResponse> & {
  data: Array<Challenge>;
};

export const challengesApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getChallenges: build.query<GetChallengesResponse, void>({
      query: () => ({ url: '/ips/challenge' }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: true, auth: true }
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useLazyGetChallengesQuery
} = challengesApiSlicer;

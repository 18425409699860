import {
  type FC
} from 'react'
import {
  Box,
  CircularProgress
} from '@mui/joy'
import {
  selectShowLoader,
  useCircularLoaderSelector
} from '../circular-loader';
export const ScreenLoaderOverlay: FC = () => {
  const showLoader = useCircularLoaderSelector(selectShowLoader);
  return (
    <Box sx={({ palette }) => ({
      position: 'fixed',
      inset: 0,
      zIndex: 1000,
      backgroundColor: palette.common[900],
      ':before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        backgroundColor: !showLoader ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
      }
    })} >
      {!showLoader &&
        <CircularProgress
          value={75}
          size='md'
          thickness={4}
          sx={({ palette }) => ({
            color: palette.common[300],
            '--CircularProgress-linecap': 'auto',
            '--CircularProgress-trackColor': 'inherit',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          })}
        />}
    </Box>
  );
};
import {
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  Navigate,
} from 'react-router-dom';
import {
  selectKycStatus,
  useSessionSelector,
  UserKycStatusEnum,
} from '@entities/session';
import {
  useBuyCoinsSelector,
  selectBuyCoinsRequirements,
} from '../model';

export type BuyCoinsAccessOnlyRouteProps = PropsWithChildren & {
  denyAccessOnlyIfKycFailed?: boolean;
};

export const BuyCoinsAccessOnlyRoute: FC<BuyCoinsAccessOnlyRouteProps> = ({ children, denyAccessOnlyIfKycFailed = false }) => {
  const kycStatus = useSessionSelector(selectKycStatus)
  const isKycFailed = kycStatus === UserKycStatusEnum.Failed;
  const { accessRestricted, kycRequired } = useBuyCoinsSelector(selectBuyCoinsRequirements);

  if (denyAccessOnlyIfKycFailed) return isKycFailed ? <Navigate to='/confirm-identity' replace /> : children;
  else return accessRestricted || kycRequired ? <Navigate to='/confirm-identity' replace /> : children;
};
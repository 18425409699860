import {
  baseOmegaApi
} from '@shared/api';
import {
  Filter
} from '@shared/lib';
import {
  mapTournaments,
  mapTournamentRankings,
} from '../lib';
import {
  type Tournament,
  type TournamentRanking,
} from '../model';

export const tournamentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicTournaments: build.query<Array<Tournament>, Filter>({
      query: (args) => ({
        url: '/ips/getTournaments',
        params: args,
      }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => mapTournaments(response),
    }),
    getPrivateTournaments: build.query<Array<Tournament>, Filter>({
      query: (args) => ({
        url: '/ips/getMyTournament',
        params: args,
      }),
      extraOptions: { auth: true, showLoader: false },
      transformResponse: (response: any) => mapTournaments(response),
    }),
    getTournamentRanking: build.query<Array<TournamentRanking>, Filter>({
      query: (args) => ({
        url: '/ips/getTournamentRanking',
        params: args,
      }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => mapTournamentRankings(response),
    })
  }),
});

export const {
  useGetPrivateTournamentsQuery,
  useLazyGetPrivateTournamentsQuery,
  useGetPublicTournamentsQuery,
  useLazyGetPublicTournamentsQuery,
  useGetTournamentRankingQuery,
  useLazyGetTournamentRankingQuery,
} = tournamentsApiSlice;

import {
  mapper
} from '@shared/lib';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  maskCardDigitsIfClean
} from '@entities/payments';
import {
  type PaymentInformation
} from '../api';

export type PaymentInfoSimplified = {
  paymentInformationId: string;
  accountNumber: string;
  paymentMethod: string;
};

export type MapPaymentInformation = (data: PaymentInformation) => PaymentInfoSimplified;
export const mapPaymentInformation: MapPaymentInformation = mapper([
  {
    name: 'paymentInformationId',
    from: 'id'
  },
  {
    name: 'accountNumber',
    from: 'detailsAsMap.ACCOUNT_NUMBER',
    transform: (accountNumber: string) => maskCardDigitsIfClean(accountNumber)
  },
  {
    name: 'paymentMethod',
    from: 'paymentMethods',
    transform: (methods: Array<PaymentMethodKeyEnum>) => methods[0]
  }
]);
import {
  mapper
} from '@shared/lib';
import {
  type LoyaltyStatus
} from '../model';
import {
  mapLevel
} from './map-loyalty-level';

type MapLoyaltyStatus = (data: any) => LoyaltyStatus
export const mapLoyaltyStatus: MapLoyaltyStatus = mapper([
  {
    name: 'accumulatedLoyaltyPoint',
    from: 'accumulatedLoyaltyPoint',
    transform: Number
  },
  {
    name: 'currentLoyaltyPoint',
    from: 'currentLoyaltyPoint',
    transform: Number
  },
  {
    name: 'currentVIPLevel',
    from: 'currentVIPLevel',
    transform: mapLevel
  },
  {
    name: 'remainingPointsForNextLevel',
    from: 'remainingPointsForNextLevel',
    transform: Number
  },
  {
    name: 'totalPointsForNextLevel',
    from: 'totalPointsForNextLevel',
    transform: Number
  },
])
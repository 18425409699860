import {
  lazy,
  useMemo,
  Suspense,
  ReactElement,
  type FC,
} from 'react';
import {
  Stack,
  Typography,
  useTheme
} from '@mui/joy';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  UserKycStatusEnum,
  selectKycStatus,
  useSessionSelector,
  selectRegistrationStatus,
} from '../model';
import {
  useGetUserDocumentsQuery
} from '../api';

const ErrorOutlineIcon = lazy(() => import('@mui/icons-material/ErrorOutline'));
const CheckCircleOutlineIcon = lazy(() => import('@mui/icons-material/CheckCircleOutline'));

export type StatusInfo = {
  message: string;
  color: string;
  icon?: ReactElement;
};

export const UserVerificationIndicator: FC = () => {
  const theme = useTheme();
  const registrationStatus = useSessionSelector(selectRegistrationStatus);
  const kycStatus = useSessionSelector(selectKycStatus);

  const { data } = useGetUserDocumentsQuery(undefined, { refetchOnMountOrArgChange: true });

  const withUploadedDocuments = useMemo(() => {
    const isUnknownKycStatus = kycStatus === UserKycStatusEnum.Unknown;
    const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;
    const hasUploadedDocs = Boolean(data?.userDocuments?.length);
    return (isUnknownKycStatus && isPlayer && hasUploadedDocs) ? ':with-uploaded-documents' : '';
    // eslint-disable-next-line
  }, [data?.userDocuments?.length, kycStatus, registrationStatus]);

  const statusMapper = useMemo<Record<string, StatusInfo>>(() => ({
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Unknown}`]: {
      message: 'Please complete your sign up by confirming your personal details in the player profile section, as seen on your ID',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Open}`]: {
      message: 'Please complete your sign up by confirming your personal details in the player profile section, as seen on your ID',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <ErrorOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Pending}`]: {
      message: 'Please complete your sign up by confirming your personal details in the player profile section, as seen on your ID',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Pass}`]: {
      message: 'Please complete your sign up by confirming your personal details in the player profile section, as seen on your ID',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.QuickReg}:${UserKycStatusEnum.Failed}`]: {
      message: 'Your identity verification was not successful. Please contact support for more information or restart ID verification process',
      color: theme.palette.common.error,
      icon: (
        <Suspense>
          <ErrorOutlineIcon sx={{ fontSize: 16, color: theme.palette.common.error }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Unknown}`]: {
      message: 'Please confirm your identity by undergoing ID verification. Please contact Customer Support for assistance',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Open}`]: {
      message: 'Please confirm your identity by undergoing ID verification.  Please contact Customer Support for assistance',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Unknown}:with-uploaded-documents`]: {
      message: 'You identity is now being verified',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Pending}`]: {
      message: 'You identity is now being verified',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Failed}`]: {
      message: 'Your identity verification wasn`t successful. Please contact Customer Support for assistance',
      color: theme.palette.common.error,
      icon: (
        <Suspense>
          <ErrorOutlineIcon sx={{ fontSize: 16, color: theme.palette.common.error }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Pass}`]: {
      message: 'Your account is verified',
      color: theme.palette.common[825],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[825] }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Suspended}`]: {
      message: 'Your account has been suspended for security reasons',
      color: theme.palette.common.error,
      icon: (
        <Suspense>
          <ErrorOutlineIcon sx={{ fontSize: 16, color: theme.palette.common.error }} />
        </Suspense>
      )
    },
    [`${UserRegistrationStatusEnum.Player}:${UserKycStatusEnum.Requested}`]: {
      message: 'Additional documents are required to verify your identity. Please contact Customer Support for assistance',
      color: theme.palette.common[1060],
      icon: (
        <Suspense>
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[1060] }} />
        </Suspense>
      )
    },
    // eslint-disable-next-line
  }), [registrationStatus, kycStatus, theme.palette.common]);

  const status = statusMapper[`${registrationStatus}:${kycStatus}${withUploadedDocuments}`];

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      gap={.5}>
      {status?.icon}
      <Typography
        sx={{
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: status?.color
        }}>
        {status?.message}
      </Typography>
    </Stack>
  );
};
import {
  mapper
} from '@shared/lib';
import {
  type BonusInfo
} from '../model';

type MapBonusInfo = (data: any) => BonusInfo;
export const mapBonusInfo: MapBonusInfo = mapper([
  { name: 'id' },
  { name: 'status' },
  { name: 'expiryDate' },
  { name: 'triggerDate' },
  { name: 'triggerType' },
  { name: 'amountWageredNumber' },
  { name: 'wagerRequirementNumber' },
  { name: 'planId', from: 'bonusPlanId' },
  { name: 'planName', from: 'bonusPlanName' },
]);

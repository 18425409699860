import {
  lazy,
  Suspense
} from 'react';
import {
  Navigate,
  createBrowserRouter,
  type RouteObject,
} from 'react-router-dom';
import {
  featureToggles
} from '@shared/config';
import {
  ScreenLoaderOverlay
} from '@shared/ui';
import {
  WithPullingChallenges
} from '@entities/challenges';
import {
  WithPullingPlayerInfo,
  WithPullingUserBalance,
  AuthenticatedOnlyRoute,
  ForbiddenTagBlockerRoute,
} from '@entities/session';
import {
  NonAuthenticatedOnlyRoute,
} from '@entities/session';
import {
  WithUserConsentsConfirmationProvider,
  WithPublicConsentsConfirmationProvider,
} from '@entities/consents';
import {
  BuyCoinsAccessOnlyRoute,
} from '@features/buy-coins';
import {
  RedeemCoinsAccessOnlyRoute
} from '@features/redeem-coins';
import {
  WithdrawCoinsPage
} from '@pages/withdraw-coins';

const BehaviorWithKeepFooterPageLayout = lazy(() => import('./layouts/behavior-with-keep-footer-page-layout.component')
  .then(module => ({ default: module.BehaviorWithKeepFooterPageLayout })));
const BehaviorWithoutStickyMenuPageLayout = lazy(() => import('./layouts/behavior-without-sticky-menu-page-layout.component')
  .then(module => ({ default: module.BehaviorWithoutStickyMenuPageLayout })));
const AuthenticatedLayoutNoStickyNoMobileFooter = lazy(() => import('./layouts/authenticated-layout-no-sticky-no-mobile-footer.component')
  .then(module => ({ default: module.AuthenticatedLayoutNoStickyNoMobileFooter })));
const BehaviorPageLayout = lazy(() => import('./layouts/behavior-page-layout.component')
  .then(module => ({ default: module.BehaviorPageLayout })));
const GamePageLayoutPageLayout = lazy(() => import('./layouts/game-page-layout.component')
  .then(module => ({ default: module.GamePageLayoutPageLayout })));

const SomethingWrongPage = lazy(() => import('@pages/something-wrong-page')
  .then(module => ({ default: module.SomethingWrongPage })));
const DynamicPage = lazy(() => import('@pages/dynamic')
  .then(module => ({ default: module.DynamicPage })));
const LandingPage = lazy(() => import('@pages/landing')
  .then(module => ({ default: module.LandingPage })));
const LobbyPage = lazy(() => import('@pages/lobby')
  .then(module => ({ default: module.LobbyPage })));
const AccountPage = lazy(() => import('@pages/account')
  .then(module => ({ default: module.AccountPage })));
const GamesPage = lazy(() => import('@pages/games')
  .then(module => ({ default: module.GamesPage })));
const TournamentsPage = lazy(() => import('@pages/tournaments')
  .then(module => ({ default: module.TournamentsPage })));
const TournamentDetailsPage = lazy(() => import('@pages/tournament-details')
  .then(module => ({ default: module.TournamentDetailsPage })));
const PromotionsPage = lazy(() => import('@pages/promotions')
  .then(module => ({ default: module.PromotionsPage })));
const PromotionDetailsPage = lazy(() => import('@pages/promotion-details')
  .then(module => ({ default: module.PromotionDetailsPage })));
const SignInPage = lazy(() => import('@pages/sign-in')
  .then(module => ({ default: module.SignInPage })));
const SignUpPage = lazy(() => import('@pages/sign-up')
  .then(module => ({ default: module.SignUpPage })));
const SignUpConfirmationPage = lazy(() => import('@pages/sign-up-confirmation')
  .then(module => ({ default: module.SignUpConfirmationPage })));
const ConfirmIdentityPage = lazy(() => import('@pages/confirm-identity')
  .then(module => ({ default: module.ConfirmIdentityPage })));
const SignUpSuccessConfirmationPage = lazy(() => import('@pages/sign-up-success-confirmation')
  .then(module => ({ default: module.SignUpSuccessConfirmationPage })));
const SignUpCompleteProfilePage = lazy(() => import('@pages/sign-up-complete-profile')
  .then(module => ({ default: module.SignUpCompleteProfilePage })));
const CoinStorePage = lazy(() => import('@pages/coin-store')
  .then(module => ({ default: module.CoinStorePage })));
const CheckoutPage = lazy(() => import('@pages/checkout')
  .then(module => ({ default: module.CheckoutPage })));
const ResetPasswordPage = lazy(() => import('@pages/reset-password')
  .then(module => ({ default: module.ResetPasswordPage })));
const ResetPasswordConfirmationPage = lazy(() => import('@pages/reset-password-confirmation')
  .then(module => ({ default: module.ResetPasswordConfirmationPage })));
const NewPasswordPage = lazy(() => import('@pages/new-password')
  .then(module => ({ default: module.NewPasswordPage })));
const PlayGamePage = lazy(() => import('@pages/play-game')
  .then(module => ({ default: module.PlayGamePage })));
const LuxeClubPage = lazy(() => import('@pages/luxe-club')
  .then(module => ({ default: module.LuxeClubPage })));
const ChallengesPage = lazy(() => import('@pages/challenges')
  .then(module => ({ default: module.ChallengesPage })));

const behaviorWithKeepFooterLayoutRoutes: Array<RouteObject> = [
  {
    path: '/',
    element: <Suspense><BehaviorWithKeepFooterPageLayout /></Suspense>,
    errorElement: <Suspense><SomethingWrongPage /></Suspense>,
    children: [
      {
        index: true,
        element: (
          <NonAuthenticatedOnlyRoute>
            <Suspense>
              <LandingPage />
            </Suspense >
          </NonAuthenticatedOnlyRoute >
        )
      },
      {
        path: '/:dynamicPageName',
        element: (
          <WithPullingUserBalance>
            <Suspense>
              <DynamicPage />
            </Suspense>
          </WithPullingUserBalance>
        ),
      },
    ],
  },
];

const behaviorWithoutStickyMenuLayoutRoutes: Array<RouteObject> = [
  {
    path: '/',
    element: <Suspense><BehaviorWithoutStickyMenuPageLayout /></Suspense>,
    errorElement: <Suspense><SomethingWrongPage /></Suspense>,
    children: [
      {
        path: '/sign-up',
        element: (
          <NonAuthenticatedOnlyRoute>
            <WithPublicConsentsConfirmationProvider>
              <Suspense>
                <SignUpPage />
              </Suspense>
            </WithPublicConsentsConfirmationProvider>
          </NonAuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/sign-up/confirmation',
        element: (
          <NonAuthenticatedOnlyRoute>
            <Suspense>
              <SignUpConfirmationPage />
            </Suspense>
          </NonAuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/confirm-identity',
        element: (
          <AuthenticatedOnlyRoute>
            <Suspense>
              <ConfirmIdentityPage />
            </Suspense>
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  },
];

const authenticatedLayoutNoStickyNoMobileFooterRoutes: Array<RouteObject> = [
  {
    path: '/',
    element: <Suspense><AuthenticatedLayoutNoStickyNoMobileFooter /></Suspense>,
    errorElement: <Suspense><SomethingWrongPage /></Suspense>,
    children: [
      {
        path: '/sign-up/success-confirmation',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingPlayerInfo addScreenLoaderOverlay key='success-confirmation'>
              <Suspense fallback={<ScreenLoaderOverlay />}>
                <SignUpSuccessConfirmationPage />
              </Suspense>
            </WithPullingPlayerInfo>
          </AuthenticatedOnlyRoute>
        )
      },
      {
        path: '/sign-up/complete-profile',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingPlayerInfo addScreenLoaderOverlay key='complete-profile'>
              <Suspense fallback={<ScreenLoaderOverlay />}>
                <SignUpCompleteProfilePage />
              </Suspense>
            </WithPullingPlayerInfo>
          </AuthenticatedOnlyRoute>
        )
      },
      {
        path: '/coin-store',
        element: (
          <AuthenticatedOnlyRoute>
            <ForbiddenTagBlockerRoute>
              <BuyCoinsAccessOnlyRoute denyAccessOnlyIfKycFailed>
                <Suspense>
                  <CoinStorePage />
                </Suspense>
              </BuyCoinsAccessOnlyRoute>
            </ForbiddenTagBlockerRoute>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/redeem',
        element: (
          <AuthenticatedOnlyRoute>
            <ForbiddenTagBlockerRoute>
              <RedeemCoinsAccessOnlyRoute>
                <Suspense>
                  <WithdrawCoinsPage />
                </Suspense>
              </RedeemCoinsAccessOnlyRoute>
            </ForbiddenTagBlockerRoute>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/checkout',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingPlayerInfo addScreenLoaderOverlay key='checkout'>
              <ForbiddenTagBlockerRoute>
                <BuyCoinsAccessOnlyRoute>
                  <Suspense>
                    <CheckoutPage />
                  </Suspense>
                </BuyCoinsAccessOnlyRoute>
              </ForbiddenTagBlockerRoute>
            </WithPullingPlayerInfo>
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  },
];

const behaviorLayoutRoutes: Array<RouteObject> = [
  {
    path: '/',
    element: <Suspense><BehaviorPageLayout /></Suspense>,
    errorElement: <Suspense><SomethingWrongPage /></Suspense>,
    children: [
      {
        path: '/login',
        element: (
          <NonAuthenticatedOnlyRoute>
            <WithUserConsentsConfirmationProvider>
              <Suspense>
                <SignInPage />
              </Suspense>
            </WithUserConsentsConfirmationProvider>
          </NonAuthenticatedOnlyRoute>
        )
      },
      {
        path: '/reset-password',
        element: (
          <NonAuthenticatedOnlyRoute>
            <Suspense>
              <ResetPasswordPage />
            </Suspense>
          </NonAuthenticatedOnlyRoute>
        )
      },
      {
        path: '/reset-password/confirmation',
        element: (
          <NonAuthenticatedOnlyRoute>
            <Suspense>
              <ResetPasswordConfirmationPage />
            </Suspense>
          </NonAuthenticatedOnlyRoute>
        )
      },
      {
        path: '/reset-password/new-password',
        element: (
          <NonAuthenticatedOnlyRoute>
            <Suspense>
              <NewPasswordPage />
            </Suspense>
          </NonAuthenticatedOnlyRoute>
        )
      },
      {
        path: '/lobby',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingUserBalance>
              <Suspense fallback={<ScreenLoaderOverlay />}>
                <LobbyPage />
              </Suspense>
            </WithPullingUserBalance>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/account',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingUserBalance>
              <Suspense>
                <AccountPage />
              </Suspense>
            </WithPullingUserBalance>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/luxe-club',
        element: (
          <AuthenticatedOnlyRoute>
            <ForbiddenTagBlockerRoute>
              <WithPullingUserBalance>
                <Suspense>
                  <LuxeClubPage />
                </Suspense>
              </WithPullingUserBalance>
            </ForbiddenTagBlockerRoute>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/games',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingUserBalance>
              <Suspense fallback={<ScreenLoaderOverlay />}>
                <GamesPage />
              </Suspense>
            </WithPullingUserBalance>
          </AuthenticatedOnlyRoute>
        ),
      },
      ...(featureToggles.challenges ? [{
        path: '/challenges',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingUserBalance>
              <WithPullingChallenges>
                <Suspense>
                  <ChallengesPage />
                </Suspense>
              </WithPullingChallenges>
            </WithPullingUserBalance>
          </AuthenticatedOnlyRoute>
        ),
      },] : []),
      {
        path: '/races',
        element: (
          <AuthenticatedOnlyRoute>
            <WithPullingUserBalance>
              <Suspense>
                <TournamentsPage />
              </Suspense>
            </WithPullingUserBalance>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/races/:tournamentSlug',
        element: (
          <WithPullingUserBalance>
            <Suspense>
              <TournamentDetailsPage />
            </Suspense>
          </WithPullingUserBalance>
        ),
      },
      {
        path: '/promotions',
        element: (
          <AuthenticatedOnlyRoute>
            <ForbiddenTagBlockerRoute>
              <WithPullingUserBalance>
                <Suspense>
                  <PromotionsPage />
                </Suspense>
              </WithPullingUserBalance>
            </ForbiddenTagBlockerRoute>
          </AuthenticatedOnlyRoute>
        ),
      },
      {
        path: '/promotions/:promotionSlug',
        element: (
          <AuthenticatedOnlyRoute>
            <ForbiddenTagBlockerRoute>
              <WithPullingUserBalance>
                <Suspense>
                  <PromotionDetailsPage />
                </Suspense>
              </WithPullingUserBalance>
            </ForbiddenTagBlockerRoute>
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  },
];

const gamePageLayoutLayoutRoutes: Array<RouteObject> = [
  {
    path: '/games',
    element: <Suspense><GamePageLayoutPageLayout /></Suspense>,
    errorElement: <Suspense><SomethingWrongPage /></Suspense>,
    children: [
      {
        path: '/games/:id',
        element: (
          <AuthenticatedOnlyRoute>
            <Suspense>
              <PlayGamePage />
            </Suspense>
          </AuthenticatedOnlyRoute>
        ),
      },
    ],
  },
];

export const appRouter = createBrowserRouter([
  ...behaviorWithKeepFooterLayoutRoutes,
  ...behaviorWithoutStickyMenuLayoutRoutes,
  ...authenticatedLayoutNoStickyNoMobileFooterRoutes,
  ...behaviorLayoutRoutes,
  ...gamePageLayoutLayoutRoutes,
  {
    path: '*',
    element: <Navigate to='/lobby' replace />
  }
]);
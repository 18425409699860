import { baseOmegaApi } from '@shared/api';
import {
  ChangePassword,
  ChangePasswordSuccessResponse,
  ChangePasswordErrorResponse
} from './types';

export const changePasswordApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.query<ChangePasswordErrorResponse | ChangePasswordSuccessResponse, ChangePassword>({
      query: (params) => ({ url: '/ips/updatePassword', params }),
      extraOptions: { auth: true, showLoader: false },
    })
  }),
});

export const {
  useLazyChangePasswordQuery,
} = changePasswordApiSlicer;
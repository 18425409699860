import {
  baseApi
} from '@shared/api';

export type GenerateKycTokenOrVerificationLink = {
  partyId: string;
};

export type GenerateKycTokenDataResponse = {
  token?: Maybe<string>;
  error?: Maybe<string>;
};

export type GenerateKycVerificationLinkDataResponse = {
  link?: Maybe<Url>;
  error?: Maybe<string>;
};

export const beginKycVerificationApiSlicer = baseApi.injectEndpoints({
  endpoints: build => ({
    generateKycToken: build.mutation<GenerateKycTokenDataResponse, GenerateKycTokenOrVerificationLink>({
      query: ({ partyId }) => ({
        url: `/api/kyc-verification/generate-kyc-token?partyId=${partyId}`,
        method: 'POST',
      }),
      extraOptions: { auth: true, showLoader: true }
    }),
    generateKycVerificationLink: build.mutation<GenerateKycVerificationLinkDataResponse, GenerateKycTokenOrVerificationLink>({
      query: ({ partyId }) => ({
        url: `/api/kyc-verification/generate-kyc-verification-link?partyId=${partyId}`,
        method: 'POST',
      }),
      transformResponse: (response: GenerateKycVerificationLinkDataResponse) => {
        if (response.error) return { error: response.error };
        return { link: response.link };
      },
      extraOptions: { auth: true, showLoader: true }
    }),
  }),
});

export const {
  useGenerateKycTokenMutation,
  useGenerateKycVerificationLinkMutation
} = beginKycVerificationApiSlicer;
import { baseApi } from '@shared/api';
import { type GamesItem } from '@entities/games';
import { mapGamesItem } from '../lib';

export const gamesFilterByFavoriteApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFavoriteGames: build.query<Array<GamesItem>, Record<string, any>>({
      query: (params: Record<string, any>) => ({ url: '/api/favorite-games', params }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => response.map(mapGamesItem),
    }),
  }),
});

import {
  lazy,
  Suspense,
  type FC,
  type ReactNode,
} from 'react';
import {
  styled,
  useTheme,
  Stack,
  Typography,
} from '@mui/joy';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  maskCardDigitsIfClean
} from '../lib';
import {
  type CheckoutResult
} from '../model';

const CheckCircleOutlineRoundedIcon = lazy(() => import('@mui/icons-material/CheckCircleOutlineRounded'));

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: 578,
  backgroundColor: theme.palette.common[475],
  [theme.breakpoints.down(491)]: {
    maxHeight: '100%',
  },
}));

const CardContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 24,
  [theme.breakpoints.down(490)]: {
    paddingTop: 16,
    paddingInline: 16,
    paddingBottom: 32,
  },
}));

const CardHeader = styled(Stack)(() => ({
  width: '100%',
  marginBottom: 24,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export type CheckoutSuccessFormProps = {
  checkoutResult: Maybe<CheckoutResult>;
  receiptFooterSlot?: ReactNode;
  cardNumberSlot?: ReactNode;
  onClose?(): void;
};

// TODO: refactor me
export const CheckoutSuccessForm: FC<CheckoutSuccessFormProps> = ({
  onClose,
  checkoutResult,
  receiptFooterSlot,
  cardNumberSlot = 'Card number',
}) => (
  <Container>
    <CardContent>
      <CardHeader>
        <Typography
          level='h3'
          fontSize='1.25rem'>
          Checkout
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CardHeader>
      <Stack
        direction='column'
        justifyContent='flex-start'
        width='100%'
        height='100%'
        rowGap={5}>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='space-between'
          rowGap={3}
          sx={({ breakpoints }) => ({
            rowGap: 3,
            paddingInline: 1,
            [breakpoints.down(490)]: {
              paddingTop: 4,
              rowGap: 4,
            }
          })}>
          <Suspense>
            <CheckCircleOutlineRoundedIcon
              sx={{
                fontSize: 112,
                color: useTheme().palette.common[825]
              }}
            />
          </Suspense>
          <Typography
            sx={({ palette }) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 44,
              fontSize: 22,
              fontWeight: 'bold',
              fontStyle: 'normal',
              lineHeight: '20px',
              color: palette.common.white,
              backgroundColor: palette.common[900],
              borderRadius: 8,
            })}>
            {formatCurrency(checkoutResult?.amount ?? 0)}
          </Typography>
        </Stack>

        <Stack
          width='100%'
          paddingInline={1}>
          {checkoutResult?.cardNumber && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={({ palette }) => ({
                paddingBlock: 1,
                width: '100%',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: palette.common[925],
              })}>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                {cardNumberSlot}
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                {maskCardDigitsIfClean(checkoutResult?.cardNumber ?? '')}
              </Typography>
            </Stack>
          )}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={({ palette }) => ({
              paddingBlock: 1,
              width: '100%',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              borderBottomColor: palette.common[925],
            })}>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
              })}>
              Purchase amount
            </Typography>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
              })}>
              {formatCurrency(checkoutResult?.amount ?? 0)}
            </Typography>
          </Stack>
          {checkoutResult?.referenceId && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={({ palette }) => ({
                paddingBlock: 1,
                width: '100%',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: palette.common[925],
              })}>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                Reference ID
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                {checkoutResult?.referenceId}
              </Typography>
            </Stack>
          )}
          {checkoutResult?.paymentId && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={({ palette }) => ({
                paddingBlock: 1,
                width: '100%',
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: palette.common[925],
              })}>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                Payment ID
              </Typography>
              <Typography
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                })}>
                {checkoutResult?.paymentId}
              </Typography>
            </Stack>
          )}
          {receiptFooterSlot ? receiptFooterSlot : (
            <Stack alignItems='center' justifyContent='center'>
              <Typography
                sx={({ palette }) => ({
                  marginTop: 3,
                  fontSize: 18,
                  color: palette.common[100],
                })}>
                Receipt of your purchase
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </CardContent>
  </Container>
);
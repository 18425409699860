import {
  useSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import type {
  SessionState,
} from '@entities/session';
import {
  sessionSlice,
  selectKycStatus,
  selectIsAuthenticated,
  selectRegistrationStatus,
  selectHasIdVerifiedTag,
  UserKycStatusEnum,
  selectHasSCForbiddenTag,
  selectHasFifthTimesDepositTag,
} from '@entities/session';
import {
  type CoinPackage
} from '@entities/coins';
import {
  buyCoinsSlice,
  type BuyCoinsState,
} from './slice';

type RootState = {
  [sessionSlice.name]: SessionState;
  [buyCoinsSlice.name]: BuyCoinsState;
};

type BuyCoinsRequirements = {
  isAuthenticated: boolean
  kycRequired: boolean;
  allowedForNewPlayer: boolean;
  accessRestricted: boolean;
};

export const selectBuyCoinsRequirements = createSelector(
  [selectIsAuthenticated, selectRegistrationStatus, selectKycStatus, selectHasFifthTimesDepositTag, selectHasIdVerifiedTag, selectHasSCForbiddenTag],
  (isAuthenticated, registrationStatus, kycStatus, hasFifthTimesDepositTag, hasIdVerifiedTag, hasSCForbiddenTag): BuyCoinsRequirements => {
    const isQuickReg = registrationStatus === UserRegistrationStatusEnum.QuickReg;
    const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;

    const isKycPass = kycStatus === UserKycStatusEnum.Pass;
    const isKycOpen = kycStatus === UserKycStatusEnum.Open;
    const isKycPending = kycStatus === UserKycStatusEnum.Pending;
    const isKycRequested = kycStatus === UserKycStatusEnum.Requested;
    const isKycFailed = kycStatus === UserKycStatusEnum.Failed;
    const isKycUnknown = kycStatus === UserKycStatusEnum.Unknown;

    let defaultBuyCoinsRequirements: BuyCoinsRequirements = {
      isAuthenticated,
      allowedForNewPlayer: false,
      kycRequired: false,
      accessRestricted: false
    };

    if (!isAuthenticated) {
      return { ...defaultBuyCoinsRequirements, isAuthenticated: false };
    };

    const isAccessRestricted =
      isQuickReg ||
      // TODO: keep for now, don't touch this for now, it will be removed in the future
      (isQuickReg && isKycUnknown) ||
      (isPlayer && isKycFailed) ||
      (isPlayer && (isKycPending || isKycRequested) && hasFifthTimesDepositTag && hasIdVerifiedTag);
    if (isAccessRestricted) {
      defaultBuyCoinsRequirements = { ...defaultBuyCoinsRequirements, accessRestricted: true };
    }

    const isKycRequired =
      (isPlayer && (isKycUnknown || isKycOpen) && hasFifthTimesDepositTag) ||
      (isPlayer && (isKycPending || isKycRequested) && hasFifthTimesDepositTag);
    if (isKycRequired) {
      defaultBuyCoinsRequirements = { ...defaultBuyCoinsRequirements, kycRequired: true };
    }

    const allowedForNewPlayer = !hasSCForbiddenTag &&
      (isQuickReg && (isKycUnknown || isKycOpen || isKycPending || isKycRequested) && !hasFifthTimesDepositTag) ||
      (isPlayer && isKycPass) ||
      (isPlayer && (isKycUnknown || isKycOpen)) ||
      (isPlayer && (isKycPending || isKycRequested));
    if (allowedForNewPlayer) {
      defaultBuyCoinsRequirements = { ...defaultBuyCoinsRequirements, allowedForNewPlayer: true };
    }

    return defaultBuyCoinsRequirements;
  });

// TODO: don't touch this for now, it will be removed in the future 
// export const selectBuyCoinsRequirements = (hasBuyQueryParam: boolean) => createSelector(
//   [selectIsAuthenticated, selectRegistrationStatus, selectKycStatus, selectHasThirdTimesDepositTag, selectHasIdVerifiedTag, selectHasSCForbiddenTag],
//   (isAuthenticated, registrationStatus, kycStatus, hasThirdTimesDepositTag, hasIdVerifiedTag, hasSCForbiddenTag): BuyCoinsRequirements => {
//     const isQuickReg = registrationStatus === UserRegistrationStatusEnum.QuickReg;
//     const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;

//     const isKycPass = kycStatus === UserKycStatusEnum.Pass;
//     const isKycPending = kycStatus === UserKycStatusEnum.Pending;
//     const isKycRequested = kycStatus === UserKycStatusEnum.Requested;
//     const isKycFailed = kycStatus === UserKycStatusEnum.Failed;
//     const isKycUnknown = kycStatus === UserKycStatusEnum.Unknown;

//     const defaultBuyCoinsRequirements: BuyCoinsRequirements = {
//       isAuthenticated,
//       buyingAllowed: false,
//       kycRequired: false,
//       accessRestricted: false
//     };

//     if (!hasBuyQueryParam) return defaultBuyCoinsRequirements;

//     if (!isAuthenticated) {
//       return { ...defaultBuyCoinsRequirements, isAuthenticated: false };
//     };

//     const isAccessRestricted = isQuickReg ||
//       (isPlayer && isKycFailed) ||
//       (isPlayer && (isKycPending || isKycRequested) && hasThirdTimesDepositTag && hasIdVerifiedTag);
//     if (isAccessRestricted) {
//       return { ...defaultBuyCoinsRequirements, accessRestricted: true };
//     }

//     const isKycRequired =
//       (isPlayer && isKycUnknown && hasThirdTimesDepositTag) ||
//       (isPlayer && (isKycPending || isKycRequested) && hasThirdTimesDepositTag);
//     if (isKycRequired) {
//       return { ...defaultBuyCoinsRequirements, kycRequired: true };
//     }

//     const isBuyingAllowed =
//       !hasSCForbiddenTag &&
//       (isPlayer && isKycPass) ||
//       (isPlayer && isKycUnknown) ||
//       (isPlayer && (isKycPending || isKycRequested));
//     if (isBuyingAllowed) {
//       return { ...defaultBuyCoinsRequirements, buyingAllowed: true };
//     }

//     return defaultBuyCoinsRequirements;
//   });

export const selectAdditionalDocumentsRequired = createSelector(
  [selectRegistrationStatus, selectKycStatus, selectHasFifthTimesDepositTag, selectHasIdVerifiedTag],
  (registrationStatus, kycStatus, hasFifthTimesDepositTag, hasIdVerifiedTag): boolean => {
    const isPlayer = registrationStatus === UserRegistrationStatusEnum.Player;
    const isKycPending = kycStatus === UserKycStatusEnum.Pending;
    const isKycRequested = kycStatus === UserKycStatusEnum.Requested;

    return (isPlayer && (isKycPending || isKycRequested) && hasFifthTimesDepositTag && hasIdVerifiedTag);
  });

export const selectIsPlayerVerified = (state: RootState): boolean => {
  const fullProfile = state[sessionSlice.name]?.fullProfile;
  return fullProfile?.kycStatus === UserKycStatusEnum.Pass &&
    fullProfile?.registrationStatus === UserRegistrationStatusEnum.Player;
};

export const selectChosenCoinPackage = (state: RootState): Maybe<CoinPackage> => (
  state[buyCoinsSlice.name].chosenCoinPackage
);

export const useBuyCoinsSelector: TypedUseSelectorHook<RootState> = useSelector;

import {
  FC
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  v4 as uuidv4
} from 'uuid';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  SocialMedia
} from '../model';

export type FollowUsBlockProps = {
  title: string;
  socialMedia: SocialMedia[];
};

export const FollowUsBlock: FC<FollowUsBlockProps> = ({ title, socialMedia }) => (
  <Stack gap={1}>
    <Typography
      sx={({ palette, breakpoints }) => ({
        alignSelf: 'center',
        color: palette.common.white,
        [breakpoints.down(912)]: { alignSelf: 'start', },
      })}>
      {title}
    </Typography>
    <Stack
      flexDirection='row'
      gap={2}
      sx={({ breakpoints }) => ({
        justifyContent: 'center',
        [breakpoints.down(912)]: { justifyContent: 'start' }
      })}>
      {socialMedia?.map(socialMedia => (
        <Link
          key={uuidv4()}
          to={socialMedia.href}
          target='_blank'>
          <Box
            component='img'
            src={socialMedia.icon}
            sx={{
              width: '31px',
              height: '31px',
            }}
          />
        </Link>
      ))}
    </Stack>
  </Stack>
);

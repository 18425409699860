import {
  mapper
} from '@shared/lib';
import type {
  ChestConfig,
  BonusConfig,
  RewardBonusPlanId,
} from '../model';

type MapBonusConfig = (data: any) => BonusConfig;
export const mapChestConfig: MapChestConfig = mapper([
  { name: 'active', from: 'attributes.active' },
  { name: 'repeat', from: 'attributes.repeat' },
  { name: 'title', from: 'attributes.chestTitle' },
  { name: 'steps', from: 'attributes.steps' },
  { name: 'purchaseButtonText', from: 'attributes.purchaseButtonText' },
  { name: 'rewardButtonTextClaimed', from: 'attributes.rewardButtonTextClaimed' },
  { name: 'rewardButtonTextUnclaimed', from: 'attributes.rewardButtonTextUnclaimed' },
  {
    name: 'rewardBonusPlanIds', from: 'attributes.rewardBonusPlanIds',
    transform: (rewardBonusPlanIds: Array<RewardBonusPlanId>) => (
      rewardBonusPlanIds.map((rewardBonusPlanId: RewardBonusPlanId) => ({
        bonusIdSelectionRate: rewardBonusPlanId.bonusIdSelectionRate,
        bonusId: Number(rewardBonusPlanId.bonusId),
        successMessage: rewardBonusPlanId.successMessage
      })))
  },
  {
    name: 'purchasesBonusPlanId',
    from: 'attributes.purchasesBonusPlanId',
    transform: (purchasesBonusPlanId: string) => {
      return parseInt(purchasesBonusPlanId)
    },
  },
  { name: 'claimProgressText', from: 'attributes.claimProgressText' },
]);

export const mapBonusConfig: MapBonusConfig = mapper([
  { name: 'title', from: 'attributes.title' },
  { name: 'link', from: 'attributes.link' },
]);

type MapChestConfig = (data: unknown) => ChestConfig;
export const mapChestsConfig = (data: any) => {
  return data?.attributes?.treasureChests?.data?.map(mapChestConfig) as ChestConfig[];
};
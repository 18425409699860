import {
  combineReducers
} from 'redux';
import {
  baseApi,
  baseOmegaApi,
} from '@shared/api';
import {
  circularLoaderSlice,
  circularLoaderReducer
} from '@shared/ui/circular-loader';
import {
  featureToggles
} from '@shared/config';
import {
  sessionSlice,
  sessionSliceReducer,
} from '@entities/session';
import {
  gamesSlice,
  gamesReducer,
} from '@entities/games';
import {
  messagesSlice,
  messagesReducer
} from '@entities/messages';
import {
  layoutsSlice,
  layoutsReducer,
} from '@entities/layouts';
import {
  transactionsSlice,
  transactionsReducer,
} from '@entities/transactions';
import {
  gameWinnersSlice,
  gameWinnersReducer,
} from '@entities/game-winners';
import {
  tournamentsSlice,
  tournamentsReducer
} from '@entities/tournaments';
import {
  challengesSlice,
  challengesReducer
} from '@entities/challenges';
import {
  paymentsSlice,
  paymentsReducer
} from '@entities/payments';
import {
  managePromotionalSubscriptionsSlice,
  managePromotionalSubscriptionsReducer,
} from '@features/manage-promotional-subscriptions';
import {
  gamesFilteredByCategoriesSlice,
  gamesFilteredByCategoriesReducer
} from '@features/games-filter-by-category';
import {
  gamesFilteredBySearchSlice,
  gamesFilteredBySearchReducer
} from '@features/games-filter-by-search';
import {
  gamesFilteredByFavoriteSlice,
  gamesFilteredByFavoriteReducer
} from '@features/games-filter-by-favorite';
import {
  documentsSlice,
  documentsReducer
} from '@features/upload-documents';
import {
  zipCodeInfoApiSlice,
  editPlayerProfileSlice,
  editPlayerProfileReducer,
} from '@features/edit-player-profile';
import {
  redeemCoinsSlice,
  redeemCoinsReducer
} from '@features/redeem-coins';
import {
  buyCoinsSlice,
  buyCoinsReducer,
} from '@features/buy-coins';
import {
  transactionFilterByTypeSlice,
  transactionFilterByTypeReducer
} from '@features/transaction-filter-by-type';
import {
  authViaSocialsSlice,
  authViaSocialsReducer
} from '@features/authentication-via-socials';
import {
  promotionsFilteredByCategoriesSlice,
  promotionsFilteredByCategoriesReducer
} from '@features/promotions-filter-by-category';
import {
  claimBonusCoinsSlice,
  claimBonusCoinsReducer
} from '@features/claim-bonus-coins';
import {
  pageLayoutSlice,
  pageLayoutReducer
} from '@widgets/page-layout';
import {
  transactionListReducer,
  transactionListSlice
} from '@widgets/transaction-list';
import {
  gamesListWidgetSlice,
  gamesListWidgetReducer
} from '@widgets/game-list';
import {
  lobbyPageSlice,
  lobbyPageReducer,
} from '@pages/lobby';
import {
  gamesPageReducer,
  gamesPageSlice
} from '@pages/games';
import {
  tournamentsPageReducer,
  tournamentsPageSlice
} from '@pages/tournaments';
import {
  dynamicPageReducer,
  dynamicPageSlice,
} from '@pages/dynamic';
import {
  promotionsPageReducer,
  promotionsPageSlice
} from '@pages/promotions';
import {
  accountPageSlice,
  accountPageReducer
} from '@pages/account';
import {
  luxeClubPageReducer,
  luxeClubPageSlice,
} from '@pages/luxe-club';
import {
  tournamentDetailsPageReducer,
  tournamentDetailsPageSlice,
} from '@pages/tournament-details';
import {
  promotionDetailsPageReducer,
  promotionDetailsPageSlice
} from '@pages/promotion-details';
import {
  challengesPageReducer,
  challengesPageSlice
} from '@pages/challenges';
import {
  playGamePageSlice,
  playGamePageReducer
} from '@pages/play-game';
import {
  landingPageReducer,
  landingPageSlice,
} from '@pages/landing';

export const rootReducer = combineReducers({
  [gamesSlice.name]: gamesReducer,
  [layoutsSlice.name]: layoutsReducer,
  [paymentsSlice.name]: paymentsReducer,
  [messagesSlice.name]: messagesReducer,
  [buyCoinsSlice.name]: buyCoinsReducer,
  [lobbyPageSlice.name]: lobbyPageReducer,
  [gamesPageSlice.name]: gamesPageReducer,
  [documentsSlice.name]: documentsReducer,
  [sessionSlice.name]: sessionSliceReducer,
  [pageLayoutSlice.name]: pageLayoutReducer,
  [tournamentsSlice.name]: tournamentsReducer,
  [landingPageSlice.name]: landingPageReducer,
  [redeemCoinsSlice.name]: redeemCoinsReducer,
  [gameWinnersSlice.name]: gameWinnersReducer,
  [accountPageSlice.name]: accountPageReducer,
  [dynamicPageSlice.name]: dynamicPageReducer,
  [playGamePageSlice.name]: playGamePageReducer,
  [transactionsSlice.name]: transactionsReducer,
  [luxeClubPageSlice.name]: luxeClubPageReducer,
  [authViaSocialsSlice.name]: authViaSocialsReducer,
  [promotionsPageSlice.name]: promotionsPageReducer,
  [circularLoaderSlice.name]: circularLoaderReducer,
  [gamesListWidgetSlice.name]: gamesListWidgetReducer,
  [tournamentsPageSlice.name]: tournamentsPageReducer,
  [claimBonusCoinsSlice.name]: claimBonusCoinsReducer,
  [transactionListSlice.name]: transactionListReducer,
  [editPlayerProfileSlice.name]: editPlayerProfileReducer,
  [promotionDetailsPageSlice.name]: promotionDetailsPageReducer,
  [gamesFilteredBySearchSlice.name]: gamesFilteredBySearchReducer,
  [tournamentDetailsPageSlice.name]: tournamentDetailsPageReducer,
  [gamesFilteredByFavoriteSlice.name]: gamesFilteredByFavoriteReducer,
  [transactionFilterByTypeSlice.name]: transactionFilterByTypeReducer,
  [gamesFilteredByCategoriesSlice.name]: gamesFilteredByCategoriesReducer,
  [managePromotionalSubscriptionsSlice.name]: managePromotionalSubscriptionsReducer,
  [promotionsFilteredByCategoriesSlice.name]: promotionsFilteredByCategoriesReducer,

  ...(featureToggles.challenges && ({
    [challengesSlice.name]: challengesReducer,
    [challengesPageSlice.name]: challengesPageReducer,
  })),

  // API
  // TODO: maybe remove all api slices except baseOmegaApi and baseApi
  [baseApi.reducerPath]: baseApi.reducer,
  [baseOmegaApi.reducerPath]: baseOmegaApi.reducer,
  [zipCodeInfoApiSlice.reducerPath]: zipCodeInfoApiSlice.reducer,
});

import {
  useRef,
  useState,
  useContext,
  createContext,
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  isEmpty
} from '@shared/lib';
import {
  ConsentStatusEnum,
  type UserConsent,
} from '../lib';
import {
  useMakeSaveConsentsMutation,
  useLazyGetUserConsentsQuery,
} from '../api';
import {
  UserConsentsConfirmationModal
} from './user-consents-confirmation-modal.component';

type UserConsentsConfirmationResult = {
  rejected: boolean;
  confirmedOrSkipped: boolean;
};

type UserConsentsConfirmationResolver = Maybe<(value: UserConsentsConfirmationResult | PromiseLike<UserConsentsConfirmationResult>) => void>;

type UserConsentsConfirmOptions = {
  sessionKey: string;
  confirmMandatoryOnly?: boolean;
};

export type UserConsentsConfirmationContext = {
  confirm?(options: UserConsentsConfirmOptions): Promise<UserConsentsConfirmationResult>;
};

export const UserConsentsConfirmationContext = createContext<Maybe<UserConsentsConfirmationContext>>(null);

export const useUserConsentsConfirmation = (): UserConsentsConfirmationContext => {
  const context = useContext(UserConsentsConfirmationContext);
  if (!context) throw new Error('useUserConsentsConfirmation must be used within a UserConsentsConfirmationProvider');
  return context;
};

export const WithUserConsentsConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [saveConsents] = useMakeSaveConsentsMutation();
  const [getUserConsents] = useLazyGetUserConsentsQuery();

  const confirmationResolverRef = useRef<UserConsentsConfirmationResolver>(null);
  const [consentsToConfirmation, setConsentsToConfirmation] = useState<Maybe<Array<UserConsent>>>(null);
  const [sessionKey, setSessionKey] = useState<Maybe<string>>();

  const handleConfirm = async ({ sessionKey, confirmMandatoryOnly = false }: UserConsentsConfirmOptions): Promise<UserConsentsConfirmationResult> => {
    return new Promise<UserConsentsConfirmationResult>(async resolve => {
      try {
        confirmationResolverRef.current = resolve;

        const userConsents = await getUserConsents({ sessionKey }).unwrap();
        const userConsentsToConfirmation = userConsents.filter(uc => {
          const isNotConsented = uc.consentStatus === ConsentStatusEnum.NotConsented;
          if (confirmMandatoryOnly) return isNotConsented && uc.isMandatory;
          else return isNotConsented;
        });

        if (isEmpty(userConsentsToConfirmation)) {
          setSessionKey(null);
          resolve({ confirmedOrSkipped: true, rejected: false });
        } else {
          setConsentsToConfirmation(userConsentsToConfirmation);
          setSessionKey(sessionKey);
        }
      } catch (error) {
        console.error('Error during consents confirmation:', error);
        setSessionKey(null);
        resolve({ confirmedOrSkipped: false, rejected: true });
      }
    });
  };

  const handleConfirmation = async (confirmed: boolean): Promise<void> => {
    try {
      if (confirmed && sessionKey && consentsToConfirmation) {
        const consentedVersions = consentsToConfirmation.map(({ consentVersionId }) => consentVersionId);
        const response = await saveConsents({ consentedVersions, sessionKey }).unwrap();
        if (response?.status === OmegaApiResponseStatusEnum.Success) {
          setSessionKey(null);
          setConsentsToConfirmation(null);
          confirmationResolverRef.current?.({ confirmedOrSkipped: true, rejected: false });
        }
      } else {
        setSessionKey(null);
        setConsentsToConfirmation(null);
        confirmationResolverRef.current?.({ confirmedOrSkipped: false, rejected: true });
      }
    } catch (error) {
      setSessionKey(null);
      console.error('Error during consents saving:', error);
      confirmationResolverRef.current?.({ confirmedOrSkipped: false, rejected: true });
    }
  };

  const handleClose = (): void => {
    setSessionKey(null);
    setConsentsToConfirmation(null);
    confirmationResolverRef.current?.({ confirmedOrSkipped: false, rejected: true });
  };

  return (
    <UserConsentsConfirmationContext.Provider value={{ confirm: handleConfirm }}>
      {children}
      <UserConsentsConfirmationModal
        open={Boolean(sessionKey)}
        userConsents={consentsToConfirmation}
        onConfirmation={handleConfirmation}
        onClose={handleClose}
      />
    </UserConsentsConfirmationContext.Provider>
  );
};
import {
  type ComponentType
} from 'react';
import {
  ToastProvider
} from '@shared/ui';

export const withToast = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => (props: any) => (
  <ToastProvider>
    <WrappedComponent {...props} />
  </ToastProvider>
)

import {
  type FC
} from 'react';
import {
  Link,
  type LinkProps,
} from '@shared/ui';

export type TermsOfUseLinkProps = LinkProps & {
  text?: string;
};

export const TermsOfUseLink: FC<TermsOfUseLinkProps> = ({
  sx,
  text = 'Terms of use',
  ...props
}) => (
  <Link
    color='primary'
    href='/terms-and-conditions'
    underline='none'
    sx={[{
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    {text}
  </Link>
);
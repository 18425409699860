import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  FetchMessagesResponse,
  fetchMessages
} from '../api';

export type ChannelMessage = {
  id: string;
  title: Maybe<string>;
  imgUrl: Maybe<string>;
  text: Array<string>;
  buttonText: Maybe<string>;
  buttonLink: Maybe<string>;
  isButtonGreen: Maybe<boolean>;
  isRead: boolean;
  date: string;
};

export type MessagesState = {
  channels: Record<string, Array<ChannelMessage>>;
  openNotificationSidebar: boolean;
};

export type SetMessagePayload = {
  channelId: string;
  message: ChannelMessage;
};

export type ClearMessagePayload = {
  channelId: string;
};

export type ReadMessagesPayload = {
  channelId: string;
};

const initialState: MessagesState = {
  channels: {},
  openNotificationSidebar: false,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessage: (state: MessagesState, action: PayloadAction<SetMessagePayload>) => {
      const { channelId, message } = action.payload;
      const newMessages = [
        ...(state.channels[channelId] ?? []).filter(
          (messageInStore: ChannelMessage) => messageInStore.id !== message.id
        ),
        message,
      ];
      state.channels[channelId] = newMessages;
    },
    clearMessage: (state: MessagesState, action: PayloadAction<ClearMessagePayload>) => {
      const { channelId } = action.payload;
      const newMessages: Array<ChannelMessage> = [...(state.channels[channelId] ?? []).slice(1)];
      state.channels[channelId] = newMessages;
    },
    clearAllMessages: (state: MessagesState, action: PayloadAction<ClearMessagePayload>) => {
      const { channelId } = action.payload;
      state.channels[channelId] = [];
    },
    readAllMessages: (state: MessagesState, action: PayloadAction<ReadMessagesPayload>) => {
      const { channelId } = action.payload;
      const newMessages: Array<ChannelMessage> = (state.channels[channelId] ?? []).map((message: ChannelMessage) => ({
        ...message,
        isRead: true,
      }));
      state.channels[channelId] = newMessages;
    },
    openNotificationsSidebar: (state: MessagesState) => {
      state.openNotificationSidebar = true;
    },
    closeNotificationsSidebar: (state: MessagesState) => {
      state.openNotificationSidebar = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState)
    builder.addCase(fetchMessages.fulfilled,
      (state: MessagesState, action: PayloadAction<FetchMessagesResponse>) => {
        const oldMessages = state.channels[action.payload.channelId] ?? [];
        state.channels[action.payload.channelId] = [
          ...oldMessages,
          ...action.payload.messages.filter((message: ChannelMessage) =>
            !oldMessages.find((oldMessage: ChannelMessage) => oldMessage.id === message.id)
          ),
        ];
      }
    );
  },
});

export const {
  setMessage,
  clearMessage,
  readAllMessages,
  clearAllMessages,
  openNotificationsSidebar,
  closeNotificationsSidebar
} = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;

import {
  type FC
} from 'react';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  Button,
} from '@shared/ui';
import {
  BonusTriggerTypeEnum,
  type ChestWithBonuses,
} from '../model';
import {
  BonusPackageRewardInfo
} from './bonus-package-reward-info.component';
import {
  useBonusProgressTracker,
  getRandomRewardIdBasedOnPercentage,
} from '../lib';
import {
  MakeBonusPlanAwardsData,
  useMakeBonusPlanAwardsMutation
} from '../api';
import {
  activeCoin,
  inactiveCoin,
  chestBackground,
  woodChestOpened,
  woodChestClosed,
  woodChestClaimed,
  bronzeChestOpened,
  bronzeChestClosed,
  silverChestClosed,
  silverChestOpened,
  bronzeChestClaimed,
  silverChestClaimed,
} from '../assets';

export type ChestImage = {
  opened: string;
  closed: string;
  claimed: string;
};

const chestsImages: Record<number, ChestImage> = {
  1: {
    opened: woodChestOpened.default,
    closed: woodChestClosed.default,
    claimed: woodChestClaimed.default
  },
  2: {
    opened: bronzeChestOpened.default,
    closed: bronzeChestClosed.default,
    claimed: bronzeChestClaimed.default
  },
  3: {
    opened: silverChestOpened.default,
    closed: silverChestClosed.default,
    claimed: silverChestClaimed.default
  },
};

export type BonusChestItemProps = {
  partyId: number;
  chestNumber: number;
  chest: ChestWithBonuses;
  isFetching: boolean;
  triggerType: BonusTriggerTypeEnum;
  onBuy?: (purchasesBonusPlanId: number) => void;
  onClaimedFailed?(message?: string): void;
  onClaimedSuccess?(message?: string): void;
};

export const BonusChestItem: FC<BonusChestItemProps> = ({
  chest,
  partyId,
  triggerType,
  chestNumber,
  onBuy,
  onClaimedFailed,
  onClaimedSuccess
}) => {
  const {
    title,
    steps,
    claimProgressText,
    purchaseButtonText,
    rewardBonusPlanIds,
    redeemableCoinAmount,
    nonRedeemableCoinAmount,
    rewardButtonTextClaimed,
    rewardButtonTextUnclaimed,
  } = chest;
  const [makeBonusPlanAwardsMutation] = useMakeBonusPlanAwardsMutation();
  const { isChestClaimed, currentProgressStep, isChestOpened } = useBonusProgressTracker({ chest });

  const handleClaim = async (): Promise<void> => {
    const rewardBonusPlanId = getRandomRewardIdBasedOnPercentage(rewardBonusPlanIds);
    try {
      const response = await makeBonusPlanAwardsMutation({
        partyId,
        bonusPlanId: rewardBonusPlanId.bonusId,
        triggerType,
      });
      const { data, error: errorData } = response as unknown as { data: MakeBonusPlanAwardsData; error: { error: string; } };
      if (data?.error || errorData?.error || (!data?.awards || !data.awards[0]?.success)) {
        console.error('Error claiming bonus:', data?.error ?? errorData?.error);
        onClaimedFailed?.('Failed to claim bonus');
      } else {
        onClaimedSuccess?.(rewardBonusPlanId.successMessage);
      }
    } catch (error) {
      const _error = error instanceof Error ? error : new Error('Something went wrong. Please try again later.');
      console.error('Error claiming bonus:', _error.message);
      onClaimedFailed?.('Failed to claim bonus');
    };
  };

  const handleBonusItemClick = (): void => {
    if (isChestClaimed) return;

    if (isChestOpened) {
      handleClaim();
    } else {
      onBuy?.(chest.purchasesBonusPlanId);
    }
  };

  return (
    <Stack
      onClick={handleBonusItemClick}
      sx={({ palette, breakpoints }) => ({
        minWidth: 415,
        maxWidth: 415,
        height: 240,
        padding: 2.5,
        borderRadius: 24,
        cursor: 'pointer',
        boxSizing: 'border-box',
        backgroundColor: palette.common[475],
        pointerEvents: isChestClaimed ? 'none' : 'initial',
        [breakpoints.down(1349)]: {
          maxWidth: 400,
          minWidth: 400,
        },
        [breakpoints.down(1248)]: {
          maxWidth: 380,
          minWidth: 380,
        },
        [breakpoints.down(1190)]: {
          maxWidth: 360,
          minWidth: 360,
        },
        [breakpoints.down(1124)]: {
          maxWidth: 350,
          minWidth: 350,
        },
        [breakpoints.down(1098)]: {
          maxWidth: 330,
          minWidth: 330,
        },
        [breakpoints.down(1028)]: {
          maxWidth: 310,
          minWidth: 310,
        },
        [breakpoints.down(978)]: {
          minWidth: 415,
          maxWidth: '100%'
        },
        [breakpoints.down(863)]: {
          minWidth: 400,
          maxWidth: '100%'
        },
        [breakpoints.down(863)]: {
          minWidth: 360,
          maxWidth: '100%'
        },
        [breakpoints.down(768)]: {
          minWidth: 330,
          maxWidth: '100%',
          paddingInline: 1.5,
          paddingBlock: 2
        },
        [breakpoints.down(698)]: {
          minWidth: 310,
          maxWidth: '100%',
        },
      })}>
      <Stack
        gap={1}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        marginBottom={0.5}>
        <Stack alignItems='flex-start'>
          <Typography
            sx={({ palette, breakpoints }) => ({
              fontSize: 28,
              fontWeight: 700,
              textAlign: 'center',
              color: palette.common.white,
              [breakpoints.down(1048)]: {
                fontSize: 24,
              },
              [breakpoints.down(769)]: {
                fontSize: 24,
              }
            })}>
            {title}
          </Typography>
          <BonusPackageRewardInfo
            redeemableCoinAmount={redeemableCoinAmount}
            nonRedeemableCoinAmount={nonRedeemableCoinAmount}
          />
        </Stack>
        {isChestClaimed ? (
          <Box
            component='img'
            src={chestsImages[chestNumber].claimed}
            sx={({ breakpoints }) => ({
              width: 93,
              height: 91,
              objectFit: 'contain',
              [breakpoints.down(768)]: {
                width: 70,
                height: 70,
              },
            })}
          />
        ) : (
          <Stack
            position='relative'>
            <Box
              component='img'
              src={chestsImages[chestNumber][isChestOpened ? 'opened' : 'closed']}
              sx={({ breakpoints }) => ({
                zIndex: 1,
                width: 93,
                height: 91,
                objectFit: 'contain',
                [breakpoints.down(768)]: {
                  width: 70,
                  height: 70,
                },
              })}
            />
            {isChestOpened && (
              <Box
                component='img'
                src={chestBackground.default}
                alt='A glowing light effect'
                sx={({ breakpoints }) => ({
                  position: 'absolute',
                  width: 140,
                  height: 140,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  [breakpoints.down(769)]: {
                    width: 105,
                    height: 105,
                  }
                })}
              />
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        height='100%'
        justifyContent='center'>
        <Typography
          sx={({ palette, breakpoints }) => ({
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 0.5,
            textAlign: 'center',
            color: palette.common.white,
            [breakpoints.down(1028)]: {
              fontSize: 12,
            },
            [breakpoints.down(935)]: {
              fontSize: 14,
            },
          })}>
          {claimProgressText}
        </Typography>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          gap={steps > 10 ? 0 : 1}
          sx={({ breakpoints }) => ({
            marginBottom: 2,
            [breakpoints.down(768)]: {
              marginBottom: 1,
            },
          })}>
          {[...Array(steps)].map((_, index) => (
            <Box
              key={index}
              component='img'
              src={(((index + 1 <= currentProgressStep) || isChestClaimed))
                ? activeCoin.default
                : inactiveCoin.default}
              sx={({ breakpoints }) => ({
                width: 26,
                objectFit: 'contain',
                ...(steps > 10 && ({
                  width: 20
                })),
                [breakpoints.down(1274)]: {
                  width: 26
                },
                [breakpoints.down(1274)]: {
                  width: 20
                },
              })}
            />
          ))}
        </Stack>
      </Stack>
      {isChestClaimed ? (
        <Button
          color='success'
          fullWidth
          disabled>
          {rewardButtonTextClaimed}
        </Button>
      ) : (
        <Button
          fullWidth
          color={isChestOpened ? 'success' : 'primary'}>
          {isChestOpened ? rewardButtonTextUnclaimed : purchaseButtonText}
        </Button>
      )}
    </Stack>
  );
};

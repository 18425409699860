import {
  FC
} from 'react';
import {
  StackProps,
  Typography
} from '@mui/joy';

export const RedeemProcessingTerms: FC<StackProps> = ({ sx }) => {
  return (
    <Typography
      sx={[({ palette }) => ({
        color: palette.common[150],
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'center'
      }),
      ...(Array.isArray(sx) ? sx : [sx])
      ]}>
      Redemptions are processed within 1-3 business days, prioritized by Luxe Club level.
      Additional processing time may be required by the payment provider.
      Available seven days a week, excluding Federal Holidays.
      Maximum pending amount: SC 2,000.00
    </Typography>
  );
};
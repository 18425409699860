import { CurrentVIPLevelEnum } from '@entities/session';

export type Level = {
  level: CurrentVIPLevelEnum;
  number: number;
  poinst: number;
  benefits: Array<string>;
  label: string;
};

export const levelDetailsData: Array<Level> = [
  {
    level: CurrentVIPLevelEnum.Level1,
    number: 1,
    poinst: 1,
    benefits: [
      'LUXE Club Badge',
      'Over 300 Games',
      'Daily Log in Reward',
      'Customer Support',
    ],
    label: 'Bronze',
  },
  {
    level: CurrentVIPLevelEnum.Level2,
    number: 2,
    poinst: 1250,
    benefits: [
      'Bronze Benefits',
      'Recharge Bonus',
      'Level Up Reward',
    ],
    label: 'Silver',
  },
  {
    level: CurrentVIPLevelEnum.Level3,
    number: 3,
    poinst: 5000,
    benefits: [
      'Silver Benefits',
      'Level Up Reward',
      'LUXE Reward',
    ],
    label: 'Gold'
  },
  {
    level: CurrentVIPLevelEnum.Level4,
    number: 4,
    poinst: 25000,
    benefits: [
      'Gold Benefits',
      'Level Up Reward',
      'LUXE Reward',
    ],
    label: 'Ruby',
  },
  {
    level: CurrentVIPLevelEnum.Level5,
    number: 5,
    poinst: 100000,
    benefits: [
      'Ruby Benefits',
      'Level Up Reward',
      'Extra Recharge Bonus',
      'Premier - New Game Exclusivity',
      'Rakeback',
    ],
    label: 'Emerald'
  },
  {
    level: CurrentVIPLevelEnum.Level6,
    number: 6,
    poinst: 375000,
    benefits: [
      'Emerald Benefits',
      'Level Up Reward',
      'Extra Recharge Bonus',
      'Premier - New Game Exclusivity',
      'Bigger Rakeback',
    ],
    label: 'Sapphire'
  },
  {
    level: CurrentVIPLevelEnum.Level7,
    number: 7,
    poinst: 2500000,
    benefits: [
      'Sapphire Benefits',
      'Level Up Reward',
      'Exclusive Recharge Bonus & LUXE Races',
      'Premier - New Game Exclusivity',
      'Bigger Rakeback',
    ],
    label: 'Diamond'
  },
  {
    level: CurrentVIPLevelEnum.Level8,
    number: 8,
    poinst: 18750000,
    benefits: [
      'Sapphire Benefits',
      'Level Up Reward',
      'Exclusive Recharge Bonus & LUXE Races',
      'Bigger Rakeback',
      'More Rewards...',
    ],
    label: 'Platinum'
  },
];

import { mapper } from '@shared/lib';
import { type PageFooterNavigationItem } from '../model';

type MapPageFooterNavigationItem = (data: PageFooterNavigationItem) => PageFooterNavigationItem;
export const mapPageFooterNavigationItem: MapPageFooterNavigationItem = mapper([
  { name: 'title', from: 'title' },
  {
    name: 'items',
    from: 'items',
    transform: (itemsData: Array<{ id: number, text: string, slug: string }>) =>
      itemsData.map(({ id, ...props }) => ({ ...props })),
  }
]);
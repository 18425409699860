import {
  useEffect,
  type PropsWithChildren,
  type FC,
} from 'react';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import {
  useSessionSelector,
  selectHasSCForbiddenTag,
} from '../model';
import {
  SC_FORBIDDEN_PAGES
} from '../config';

export const ForbiddenTagBlockerRoute: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasSCForbiddenTag = useSessionSelector(selectHasSCForbiddenTag);
  const shouldRedirectToHomePage = SC_FORBIDDEN_PAGES
    .some(page => location.pathname.includes(page)) && hasSCForbiddenTag;

  useEffect(() => {
    if (shouldRedirectToHomePage) navigate('/');
  }, [navigate, shouldRedirectToHomePage, location]);

  return shouldRedirectToHomePage ? null : children;
}
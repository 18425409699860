import {
  mapper
} from '@shared/lib';
import {
  type OmegaApiResponse
} from '@shared/api';
import type {
  FullProfile,
  LoyaltyStatus
} from '../model';
import {
  mapLoyaltyStatus
} from './map-loyalty-status';
import {
  mapFullProfile
} from './map-full-profile';

export type PlayerDetails = {
  playerInfo: Pick<OmegaApiResponse, 'status'> & FullProfile;
  loyaltyPoints: Pick<OmegaApiResponse, 'status'> & LoyaltyStatus;
};

type MapToPlayerDetails = (data: any) => PlayerDetails;
export const mapToPlayerDetails: MapToPlayerDetails = mapper([
  {
    name: 'loyaltyPoints',
    transform: mapLoyaltyStatus
  },
  {
    name: 'playerInfo',
    transform: mapFullProfile
  },
]);
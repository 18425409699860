import {
  mapper
} from '@shared/lib';
import {
  type LandingPageStepPlayItem
} from '../model';

type MapLandingPageStepPlayItem = (data: any) => LandingPageStepPlayItem;
export const mapLandingPageStepPlayItem: MapLandingPageStepPlayItem = mapper([
  { name: 'title', from: 'title' },
  { name: 'description', from: 'description' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
]);
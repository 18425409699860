import {
  lazy,
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  Suspense,
  type FC,
} from 'react';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import {
  useMediaQuery
} from '@mui/material';
import {
  Box,
  Stack,
  Tooltip,
  Skeleton,
  AspectRatio,
} from '@mui/joy';
import {
  DicesIcon,
  IconButton
} from '@shared/ui';
import {
  materialTheme
} from '@shared/theme';
import {
  selectIsAuthenticated,
  selectCurrencySessionKey,
  selectToggledCurrencyCode,
  useLazyBalanceQuery,
  WithPullingUserBalance,
} from '@entities/session';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  useLazyGetGameQuery
} from '../api';
import {
  mapGameUrl,
  useRelaxGamesToLobby
} from '../lib';
import {
  selectIsFullScreen,
  usePlayGamePageSelector
} from '../model';
import {
  PlayGameIframe
} from './play-game-iframe.component';
import {
  FullScreenToggle
} from './full-screen-toggle.component';

const CloseIcon = lazy(() => import('@mui/icons-material/Close'));

export const PlayGamePage: FC = () => {
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'play-game',
    },
  });

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currencySessionKey = useSelector(selectCurrencySessionKey);
  const currencyCode = useSelector(selectToggledCurrencyCode);
  const isFullScreen = usePlayGamePageSelector(selectIsFullScreen);

  const isMobile = useMediaQuery(materialTheme.breakpoints.down(490));

  const [getGame, { data: gameData, isFetching }] = useLazyGetGameQuery();
  const [getBalance] = useLazyBalanceQuery();

  const [gameUrl, setGameUrl] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState<boolean>(true);
  const iframeContainerRef = useRef<any>(null);

  const fetchGameInfo = useCallback(async () => {
    const id = Number(params.id);
    if (!id) return;
    await getGame(id);
  }, [getGame, params?.id]);

  const handleLoad = () => {
    setIframeLoading(false);
  };

  const handleClose = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    navigate(-1);
  };

  const handleGoToLobby = () => {
    if (document.fullscreenElement) document.exitFullscreen();
    navigate('/games');
  };

  useRelaxGamesToLobby({ handler: handleGoToLobby });

  useLayoutEffect(() => {
    fetchGameInfo();
    return () => { if (isAuthenticated) getBalance(); };
  }, [fetchGameInfo, getBalance, isAuthenticated]);

  useEffect(() => {
    if (gameData && currencySessionKey) {
      setIframeLoading(true);
      const url = mapGameUrl({
        gameId: gameData.gameId,
        platform: gameData.productCode,
        sessionKey: currencySessionKey,
        currencyCode: currencyCode,
        lobbyURL: window.location.origin,
      });
      setGameUrl(url);
    }
  }, [gameData, currencySessionKey, currencyCode]);

  return (
    <WithPullingUserBalance>
      <Box
        sx={({ breakpoints }) => ({
          maxWidth: isFullScreen ? '100%' : '1440px',
          width: '100%',
          height: '100%',
          paddingBlock: '3rem',
          paddingInline: isFullScreen ? '10rem' : '3rem',
          [breakpoints.down(769)]: {
            padding: 0,
          },
          '@media (orientation: landscape) and (max-height: 500px)': {
            padding: 0,
          },
        })}>
        <Stack
          direction='row'
          alignItems='flex-start'
          justifyContent='center'
          sx={({ breakpoints }) => ({
            height: '100%',
            width: '100%',
            [breakpoints.up(460)]: { gap: '0.5rem' },
          })}>
          <AspectRatio
            ratio='16/9'
            sx={({ breakpoints }) => ({
              overflow: 'hidden',
              margin: '-2px',
              height: '100%',
              aspectRatio: '16/9',
              [breakpoints.up(769)]: {
                maxHeight: isFullScreen ? '100%' : '755px',
                borderRadius: '0.5rem',
                overflow: 'hidden',
              },
              [breakpoints.down(769)]: {
                display: 'flex',
              },
              '@media (orientation: landscape) and (max-height: 500px)': {
                display: 'flex',
                borderRadius: 8,
                overflow: 'unset',
                width: '100%',
                aspectRatio: 'unset',
                alignItems: 'flex-start',
                '& .MuiAspectRatio-content': {
                  width: '100%',
                  pb: 0,
                },
              },
            })}>
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
              }}>
              <Skeleton
                variant='overlay'
                loading={iframeLoading || isFetching}
                sx={{
                  visibility: iframeLoading || isFetching ? 'visible' : 'hidden',
                }}
              />
              {(!isFetching || !iframeLoading) && (
                <PlayGameIframe
                  ref={iframeContainerRef}
                  onLoad={handleLoad}
                  key={gameUrl}
                  src={gameUrl}
                  gameId={String(params.id)}
                  gameMode={currencyCode === 'SCO' ? 'SC' : 'GC'}
                  isFetching={isFetching}
                />
              )}
            </Box>
          </AspectRatio>
          {!isMobile ? (
            <Stack>
              <IconButton
                size='md'
                onClick={handleClose}
                sx={({ breakpoints }) => ({
                  '@media (orientation: landscape) and (max-height: 500px)': {
                    display: 'none',
                  },
                  [breakpoints.down(769)]: {
                    display: 'none',
                  },
                })}>
                <Suspense>
                  <CloseIcon />
                </Suspense>
              </IconButton>
              <FullScreenToggle />
              <Tooltip
                title='More Games'
                placement='right'
                sx={({ palette }) => ({
                  backgroundColor: palette.neutral.outlinedBorder,
                  paddingBlock: '8px',
                  paddingInline: '12px',
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: '18px',
                  borderRadius: 8,
                })}>
                <IconButton
                  size='md'
                  onClick={handleGoToLobby}
                  sx={({ breakpoints }) => ({
                    '@media (orientation: landscape) and (max-height: 500px)': {
                      display: 'none',
                    },
                    [breakpoints.down(769)]: {
                      display: 'none',
                    },
                  })}>
                  <DicesIcon sx={{ fontSize: '32px' }} />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </WithPullingUserBalance>
  );
};

import {
  useState,
  useEffect,
  createContext,
  type FC,
  type PropsWithChildren,
} from 'react';
import TagManager, {
  type DataLayerArgs
} from 'react-gtm-module';
import {
  ENVIRONMENT
} from '@shared/config';

export type GtmContextType = {
  sendDataToGtm: (data: DataLayerArgs) => void;
};

export const GtmContext = createContext<Maybe<GtmContextType>>(null);

export type GtmProviderProps = PropsWithChildren & {
  gtmId: string;
};

export const GtmProvider: FC<GtmProviderProps> = ({ children, gtmId }) => {
  const [initialized, setInitialized] = useState(false);
  const isProd = ENVIRONMENT === 'prod';

  useEffect(() => {
    const initializeGTM = () => {
      TagManager.initialize({ gtmId });
      setInitialized(true);
      // Remove event listeners once initialized
      window.removeEventListener('click', initializeGTM);
      window.removeEventListener('scroll', initializeGTM);
    };

    if (!initialized && isProd) {
      window.addEventListener('click', initializeGTM);
      window.addEventListener('scroll', initializeGTM);
    }

    return () => {
      if (isProd) {
        window.removeEventListener('click', initializeGTM);
        window.removeEventListener('scroll', initializeGTM);
      }
    };
  }, [gtmId, initialized]);

  const sendDataToGtm = (data: DataLayerArgs) => {
    if (initialized && isProd) TagManager.dataLayer(data);
  };

  return (
    <GtmContext.Provider value={{ sendDataToGtm }}>
      {children}
    </GtmContext.Provider>
  );
};

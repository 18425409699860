import {
  FC,
  PropsWithChildren
} from 'react'
import {
  Navigate
} from 'react-router-dom';
import {
  selectIsWithdrawCoinsAllowed,
  useRedeemCoinsSelector
} from '../model';

export const RedeemCoinsAccessOnlyRoute: FC<PropsWithChildren> = ({ children }) => {
  const isWithdrawalAllowed = useRedeemCoinsSelector(selectIsWithdrawCoinsAllowed);
  return isWithdrawalAllowed ? children : <Navigate to='/confirm-identity' replace />;
}
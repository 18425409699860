import {
  memo,
  lazy,
  useState,
  useEffect,
  Suspense,
  type FC,
  type ReactNode,
  type BaseSyntheticEvent,
} from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  yupResolver
} from '@hookform/resolvers/yup';
import {
  Link,
  Stack,
  Button,
  Typography,
  IconButton,
} from '@mui/joy';
import {
  Dropdown,
  TextInput,
  CloseIcon,
  Autocomplete,
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  checkoutAptPayFormSchema,
  checkoutAptPaySimplifiedFormSchema,
} from '../lib';
import {
  BANK_LIST
} from '../config';

const CreditCardIcon = lazy(() => import('@mui/icons-material/CreditCard'));

export type CheckoutAptPayFormValues = {
  bankName: string;
  bankAccountNumber: string;
  bankTransitNumber: string;
  bankAccountType: 'checking' | 'saving';
};

export type CheckoutAptPaySimplifiedFormResult = {
  bankAccountNumber: string;
  paymentInformationId: string;
};

export type CheckoutAptPayFormResult = CheckoutAptPayFormValues | CheckoutAptPaySimplifiedFormResult;

export type CheckoutAptPayFormProps = {
  amount: number;
  receiptFooterSlot?: ReactNode;
  existingPaymentInformation?: Array<{
    paymentInformationId: string;
    accountNumber: string;
  }>;
  onClose?(): void;
  onCheckoutSuccess?(redeemResult?: CheckoutAptPayFormResult): void;
};

export const CheckoutAptPayForm: FC<CheckoutAptPayFormProps> = memo(({
  amount,
  existingPaymentInformation,
  receiptFooterSlot,
  onClose,
  onCheckoutSuccess
}) => {
  const [showSimplifiedForm, setShowSimplifiedForm] = useState<boolean>(false);
  const [hasAccountTypeFocus, setAccountTypeFocus] = useState<boolean>(false);
  const [hasBankNameFocus, setBankNameFocus] = useState<boolean>(false);
  const [hasPaymentInformationFocus, setPaymentInformationFocus] = useState<boolean>(false);

  const {
    control,
    formState,
    setValue,
    getValues,
    trigger,
    register,
    handleSubmit
  } = useForm<CheckoutAptPayFormValues>({
    resolver: yupResolver(checkoutAptPayFormSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      bankName: '',
      bankAccountNumber: '',
      bankTransitNumber: '',
      bankAccountType: 'checking',
    }
  });

  const {
    formState: formStateSimplified,
    setValue: setValueSimplified,
    getValues: getValuesSimplified,
    trigger: triggerSimplified,
    register: registerSimplified,
    handleSubmit: handleSubmitSimplified
  } = useForm<{ paymentInformationId: UniqueId; }>({
    resolver: yupResolver(checkoutAptPaySimplifiedFormSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentInformationId: existingPaymentInformation?.[0]?.paymentInformationId ?? ''
    }
  });

  useEffect(() => {
    if (existingPaymentInformation?.length) setShowSimplifiedForm(true);
  }, [existingPaymentInformation]);

  const handleFormSubmit = async (
    formData: CheckoutAptPayFormValues,
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    const isFormValid = await trigger();
    if (isFormValid) onCheckoutSuccess?.(formData);
  };

  const handleSimplifiedFormSubmit = async (
    formData: { paymentInformationId: UniqueId },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    const isFormValid = await triggerSimplified();
    if (isFormValid) {
      const paymentInformation = existingPaymentInformation?.find(({ paymentInformationId }) => String(paymentInformationId) === formData.paymentInformationId);
      const bankAccountNumber = paymentInformation?.accountNumber;
      onCheckoutSuccess?.({
        ...formData,
        bankAccountNumber: bankAccountNumber ?? '',
      });
    }
  };

  const handleAccountTypeBlur = async (): Promise<void> => {
    setAccountTypeFocus(true);
    await trigger('bankAccountType');
  };

  const handleAccountTypeChange = (bankAccountType: string): void => {
    setValue('bankAccountType', bankAccountType as 'checking' | 'saving', { shouldValidate: true });
    if (hasAccountTypeFocus) trigger('bankAccountType');
  };

  const handleBankNameBlur = async (): Promise<void> => {
    setBankNameFocus(true);
    await trigger('bankName');
  };

  const handleBankNameChange = (bankName: string): void => {
    setValue('bankName', bankName, { shouldValidate: true });
    if (hasBankNameFocus) trigger('bankName');
  };

  const handlePaymentInformationBlur = async (): Promise<void> => {
    setPaymentInformationFocus(true);
    await triggerSimplified('paymentInformationId');
  };

  const handlePaymentInformationChange = (paymentInformationId: UniqueId): void => {
    setValueSimplified('paymentInformationId', paymentInformationId, { shouldValidate: true });
    if (hasPaymentInformationFocus) triggerSimplified('paymentInformationId');
  };

  const handleAddNewCard = (): void => {
    setShowSimplifiedForm(false);
  };

  return (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingInline: 3,
        paddingBlock: 2,
        [breakpoints.down(490)]: {
          paddingTop: 2,
          paddingBottom: 4,
          paddingInline: 2,
          marginBottom: 2,
        },
        [breakpoints.down(390)]: {
          paddingBottom: 2,
          paddingInline: 1,
        },
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          marginBottom: 2,
        }}>
        <Typography
          level='h3'
          fontSize='1.25rem'>
          Checkout
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>

      {showSimplifiedForm ? (
        <Stack
          component='form'
          onSubmit={handleSubmitSimplified(handleSimplifiedFormSubmit)}
          direction='column'
          justifyContent='space-between'
          gap={2}
          height='100%'
        >
          <Stack
            direction='column'
            sx={({ palette, breakpoints }) => ({
              padding: 2,
              borderRadius: 8,
              backgroundColor: palette.common[900],
              [breakpoints.down(460)]: {
                paddingTop: 4.8
              }
            })}>
            <Dropdown
              ref={registerSimplified('paymentInformationId').ref}
              label='ACH Account Number'
              placeholder='Select ACH Account Number'
              inputProps={{
                select: {
                  startDecorator: (
                    <Suspense>
                      <CreditCardIcon sx={{ width: 24, height: 24 }} />
                    </Suspense>
                  )
                }
              }}
              value={getValuesSimplified('paymentInformationId')}
              options={existingPaymentInformation!.map(({ accountNumber, paymentInformationId }) => ({
                label: accountNumber,
                value: paymentInformationId
              }))}
              onBlur={handlePaymentInformationBlur}
              onChange={handlePaymentInformationChange}
              error={formStateSimplified.errors?.paymentInformationId?.message}
            />
            <Stack alignItems='center'>
              <Link
                color='primary'
                underline='none'
                sx={({ breakpoints }) => ({
                  fontSize: 16,
                  [breakpoints.down(460)]: { fontSize: 18 }
                })}
                onClick={handleAddNewCard}>
                Add New Account
              </Link>
            </Stack>
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            rowGap={1}>
            {receiptFooterSlot}
            <Button
              disabled={!formStateSimplified.isValid}
              type='submit'>
              Redeem {formatCurrency(amount ?? 0)}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Stack
          component='form'
          onSubmit={handleSubmit(handleFormSubmit)}
          direction='column'
          justifyContent='space-between'
          gap={2}
          height='100%'>
          <Stack
            direction='column'
            sx={({ palette }) => ({
              backgroundColor: palette.common[900],
              padding: 2,
              borderRadius: 8,
            })}>
            <Autocomplete
              ref={register('bankName').ref}
              label='Bank Name'
              placeholder='Select Bank Name'
              value={getValues('bankName')}
              options={BANK_LIST.map(bankName => ({ label: bankName, value: bankName }))}
              onBlur={handleBankNameBlur}
              onChange={handleBankNameChange}
              error={formState.errors?.bankName?.message}
            />
            <Controller
              name='bankAccountNumber'
              control={control}
              render={({ field: { value, onBlur, onChange }, fieldState }) => (
                <TextInput
                  ref={register('bankAccountNumber').ref}
                  label='ACH Account Number'
                  placeholder='ACH Account Number'
                  startDecorator={(
                    <Suspense>
                      <CreditCardIcon sx={{ width: 24, height: 24 }} />
                    </Suspense>
                  )}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name='bankTransitNumber'
              control={control}
              render={({ field: { value, onBlur, onChange }, fieldState }) => (
                <TextInput
                  ref={register('bankTransitNumber').ref}
                  label='ACH Routing Number'
                  placeholder='ACH Routing Number'
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Dropdown
              ref={register('bankAccountType').ref}
              label='Account Type'
              placeholder='Select Account Type'
              value={getValues('bankAccountType')}
              onBlur={handleAccountTypeBlur}
              onChange={handleAccountTypeChange}
              error={formState.errors?.bankAccountType?.message}
              options={[
                { label: 'Checking', value: 'checking' },
                { label: 'Saving', value: 'saving' },
              ]}
            />
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            rowGap={1}>
            {receiptFooterSlot}
            <Button
              type='submit'
              disabled={!formState.isValid}>
              Redeem {formatCurrency(amount ?? 0)}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
});
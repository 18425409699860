import {
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  Navigate
} from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import {
  selectIsAuthenticated
} from '@entities/session';

export const NonAuthenticatedOnlyRoute: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return isAuthenticated ? <Navigate to='/lobby' replace /> : children;
};

import {
  useCallback,
  type FC,
  type ReactNode,
  useMemo,
  Fragment,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Stack, Typography
} from '@mui/joy';
import {
  isEmpty
} from '@shared/lib';
import {
  type PaymentMethod,
  selectPaymentMethod,
} from '../model';
import {
  PaymentMethodsCard
} from './payment-methods-card.component';
import {
  PaymentMethodButton
} from './payment-method-button.component';

export type PaymentMethodSelectorProps = {
  titleSlot?: ReactNode;
  recommended?: Array<PaymentMethod>;
  alternative?: Array<PaymentMethod>;
  recently?: Maybe<PaymentMethod>;
  onSelect?(method: PaymentMethod): void;
};

export const PaymentMethodSelector: FC<PaymentMethodSelectorProps> = ({
  titleSlot,
  recommended,
  alternative,
  recently,
  onSelect,
}) => {
  const dispatch = useDispatch();

  const isPaymentMethodsAvailable = useMemo(
    () => (recommended || alternative || recently),
    [recommended, alternative, recently]
  );

  const handleSelectPaymentMethod = useCallback((paymentMethod: PaymentMethod) => () => {
    dispatch(selectPaymentMethod(paymentMethod));
    onSelect?.(paymentMethod);
  }, [dispatch, onSelect]);

  return (
    <Stack
      flexDirection='column'
      sx={({ breakpoints, palette }) => ({
        maxWidth: 488,
        width: '100%',
        height: '100%',
        borderRadius: 12,
        marginInline: 'auto',
        padding: 3,
        backgroundColor: palette.common[475],
        [breakpoints.down(490)]: {
          paddingTop: 2,
          borderRadius: 0,
          paddingInline: 2,
          paddingBottom: 4,
        },
        [breakpoints.down(390)]: {
          paddingInline: 1,
          paddingBottom: 2,
        },
      })}>
      {titleSlot && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{ width: '100%', marginBottom: 3 }}>
          {titleSlot}
        </Stack>
      )}
      <Stack
        sx={({ breakpoints }) => ({
          rowGap: '32px',
          [breakpoints.down(491)]: {
            height: '100%',
            maxHeight: '100%',
          },
        })}>
        {isPaymentMethodsAvailable ? (
          <Fragment>
            {!isEmpty(recently) && (
              <PaymentMethodsCard label='Recently Used'>
                <PaymentMethodButton
                  key={recently!.id}
                  hint={recently!.displayName}
                  iconSrc={recently!.iconSrc}
                  onClick={handleSelectPaymentMethod(recently!)}
                />
              </PaymentMethodsCard>
            )}
            {!isEmpty(recommended) && (
              <PaymentMethodsCard label='Recommended'>
                {recommended!.map(({ id, displayName, iconSrc, ...props }) => (
                  <PaymentMethodButton
                    key={id}
                    hint={displayName}
                    iconSrc={iconSrc}
                    onClick={handleSelectPaymentMethod({ id, displayName, iconSrc, ...props })}
                  />
                ))}
              </PaymentMethodsCard>
            )}
            {!isEmpty(alternative) && (
              <PaymentMethodsCard label='Alternative Payments'>
                {alternative!.map(({ id, displayName, iconSrc, ...props }) => (
                  <PaymentMethodButton
                    key={id}
                    hint={displayName}
                    iconSrc={iconSrc}
                    onClick={handleSelectPaymentMethod({ id, displayName, iconSrc, ...props })}
                  />
                ))}
              </PaymentMethodsCard>
            )}
          </Fragment>
        ) : (
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              width: '100%',
              height: '100%',
              minHeight: 392,
            }}>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 298,
              }}>
              No payment methods are available at the moment.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
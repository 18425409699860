import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { promotionDetailsPageApiSlice } from '../api';
import { type PromotionDetailsPageState } from './types';

const initialState: PromotionDetailsPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  name: null,
  action: null,
  slider: null,
  faqBlock: null,
  howItWorksBlock: null,
  games: null,
  pageNotFound: false,
};

export const promotionDetailsPageSlice = createSlice({
  name: 'promotion-details-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PromotionDetailsPageState>) => {
    builder.addMatcher(
      promotionDetailsPageApiSlice.endpoints.getPromotionDetailsPage.matchFulfilled,
      (state: PromotionDetailsPageState, action: PayloadAction<PromotionDetailsPageState>) => {

        if (Reflect.has(action?.payload, 'title')) {
          state.title = action.payload.title;
          state.keywords = action.payload.keywords;
          state.description = action.payload.description;
          state.canonical = action.payload.canonical;

          state.name = action.payload.name;
          state.slider = action.payload.slider;
          state.howItWorksBlock = action.payload.howItWorksBlock;
          state.faqBlock = action.payload.faqBlock;
          state.action = action.payload.action;
          state.games = action.payload.games;

          state.pageNotFound = false;
        } else {
          Object.assign(state, initialState, { pageNotFound: true });
        }
      }
    );
  }
});

export const promotionDetailsPageReducer = promotionDetailsPageSlice.reducer;
import {
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import {
  mapLocationInfoByZipCode
} from '../lib';

export type LocationInfoByZipCode = {
  postCode: string;
  country: string,
  countryAbbreviation: string,
  city: string,
  state: string,
  stateAbbreviation: string,
  longitude: string,
  latitude: string,
};

export const zipCodeInfoApiSlice = createApi({
  reducerPath: 'zipCodeInfoApiSlice',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.zippopotam.us/us/' }),
  endpoints: (build) => ({
    getCityAndStateByZipCode: build.query<any, string>({
      query: zipCode => zipCode,
      transformResponse: (response: { data: unknown[] }) => mapLocationInfoByZipCode(response)
    }),
  }),
});

export const {
  useLazyGetCityAndStateByZipCodeQuery,
} = zipCodeInfoApiSlice;


import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  SC_FORBIDDEN_PAGES,
  selectHasSCForbiddenTag,
  selectIsAuthenticated,
  sessionSlice,
  type SessionState
} from '@entities/session';
import {
  selectSidebarMenuItems,
  AuthVisibilityEnum,
  selectPageFooterNavigationItems,
} from '@entities/layouts';
import {
  pageLayoutSlice,
  type SidebarMenuState
} from './slice';

type RootState = {
  [pageLayoutSlice.name]: SidebarMenuState;
  [sessionSlice.name]: SessionState;
};

export const selectIsOpenSidebarMenu = (state: RootState): boolean => (
  state[pageLayoutSlice.name].open ?? false
);

export const isMenuItemSCForbidden = (hasSCForbiddenTag: boolean, slug: string) => {
  return hasSCForbiddenTag && SC_FORBIDDEN_PAGES.includes(slug);
};

const isMenuItemVisible = (isAuthenticated: boolean, authVisibility?: AuthVisibilityEnum) => {
  if (!authVisibility) return false;
  return isAuthenticated
    ? authVisibility !== AuthVisibilityEnum.UnauthorizedOnly
    : authVisibility !== AuthVisibilityEnum.AuthorizedOnly;
};

export const selectMenuItems = createSelector(
  selectIsAuthenticated,
  selectHasSCForbiddenTag,
  selectSidebarMenuItems,
  (isAuthenticated, hasSCForbiddenTag, menuItems) =>
    menuItems?.filter(({ authVisibility, slug }) =>
      !isMenuItemSCForbidden(hasSCForbiddenTag, slug) &&
      isMenuItemVisible(isAuthenticated, authVisibility)
    ),
  { devModeChecks: { inputStabilityCheck: 'never' } }
);

export const selectFooterNavigationItems = createSelector(
  [selectPageFooterNavigationItems, selectHasSCForbiddenTag],
  (navigationItems, hasSCForbiddenTag) => {
    const items = navigationItems?.reduce((acc, curr) => {
      const filtered = curr.items?.filter(({ slug }) => !isMenuItemSCForbidden(hasSCForbiddenTag, slug));
      return [...acc, ...filtered];
    }, new Array<{ text: string; slug: string }>());

    return items;
  });

export const useSidebarMenuSelector: TypedUseSelectorHook<RootState> = useSelector;
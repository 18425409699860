import {
  FC,
  useMemo,
  useState
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DOMPurify from 'dompurify';
import {
  Box,
  Button,
  Stack,
  Typography
} from '@mui/joy';
import {
  isExternalUrl
} from '@shared/lib';
import {
  ChannelMessage
} from '@entities/messages';

dayjs.extend(utc);

export type NotificationItemProps = {
  message: ChannelMessage;
  onButtonClick(): void;
};

export const NotificationItem: FC<NotificationItemProps> = ({ message, onButtonClick }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formattedDate: string = useMemo(() => {
    const now = dayjs();
    const messageDate = dayjs(message.date);

    const diffInMinutes = now.diff(messageDate, 'minutes');
    const diffInHours = now.diff(messageDate, 'hours');
    const diffInDays = now.diff(messageDate, 'days');
    if (diffInDays > 0) {
      return `${diffInDays}d ago`;
    }
    if (diffInHours > 0) {
      return `${diffInHours}h ago`;
    }
    if (diffInMinutes > 0) {
      return `${diffInMinutes} min ago`;
    }

    return 'Now';
  }, [message]);

  const handleToggleOpen = (): void => {
    setIsOpen((prev: boolean) => !prev);
  };

  const handleButtonClick = (): void => {
    if (isExternalUrl(message.buttonLink ?? '')) {
      window.open(message.buttonLink ?? '', '_blank');
      onButtonClick();
    } else {
      navigate(message.buttonLink ?? '');
      onButtonClick();
    }
  };

  return (
    <Stack
      sx={({ palette }) => ({
        padding: 2,
        borderRadius: 8,
        gap: 1,
        background: palette.common[900],
      })}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' gap={1} alignItems='center'>
          {!message.isRead && (
            <Box
              sx={({ palette }) => ({
                width: 8,
                height: 8,
                borderRadius: '50%',
                background: palette.common[700],
              })}
            />
          )}

          <Typography
            sx={({ palette }) => ({
              fontSize: 18,
              fontWeight: 500,
              lineHeight: '28px',
              color: palette.common.white,
            })}>
            {message.title}
          </Typography>
        </Stack>

        <Typography
          onClick={handleToggleOpen}
          sx={({ palette }) => ({
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
            color: palette.common[300],
            cursor: 'pointer',
            ':hover': {
              color: palette.common[325],
            },
          })}>
          {isOpen ? 'Read less' : 'Read more'}
        </Typography>
      </Stack>

      <Typography
        sx={({ palette }) => ({
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 'normal',
          color: palette.common[200],
        })}>
        {formattedDate}
      </Typography>

      {message.imgUrl && (
        <Box
          component='img'
          loading='lazy'
          src={message.imgUrl}
          sx={{
            width: '100%',
            borderRadius: 8,
            aspectRatio: 3,
            objectFit: 'contain',
          }}
        />
      )}

      {!isOpen && message.text[0] && (
        <Box
          component='div'
          sx={({ palette }) => ({
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            maxHeight: 'calc(3 * 20px)',
            overflow: 'hidden',
            color: palette.common[150],
            '& strong': {
              fontWeight: 'bold',
            },
          })}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(message.text[0]),
          }}
        />
      )}

      {isOpen &&
        message.text.map((text: string, index: number) => (
          <Box
            key={index}
            component='div'
            sx={({ palette }) => ({
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '20px',
              color: palette.common[150],
              '& strong': {
                fontWeight: 'bold',
              },
            })}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text),
            }}
          />
        ))}

      {isOpen && message.buttonText && (
        <Button
          onClick={handleButtonClick}
          color={
            message.isButtonGreen
              ? 'success'
              : 'primary'
          }>
          {message.buttonText}
        </Button>
      )}
    </Stack>
  );
};

import {
  type FC
} from 'react';
import {
  Box,
  Stack,
  Theme,
  Typography,
  styled
} from '@mui/joy';
import {
  smallGC,
  smallSC,
} from '../assets';

export type BonusPackageRewardInfoProps = {
  redeemableCoinAmount: Maybe<number>;
  nonRedeemableCoinAmount: Maybe<number>;
};

// TODO: refactor me - create component for image
const commonImageStyles = ({ breakpoints }: Theme) => ({
  width: 30,
  height: 35,
  [breakpoints.down(768)]: {
    width: 18,
    height: 22,
  },
});

// TODO: refactor me - create separate component with slot for FREE text
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  lineHeight: 1,
  fontWeight: 600,
  color: theme.palette.common.white,
  [theme.breakpoints.down(1188)]: {
    fontSize: 14,
  },
  [theme.breakpoints.down(1155)]: {
    fontSize: 12,
  },
  [theme.breakpoints.down(935)]: {
    fontSize: 16,
  },
  [theme.breakpoints.down(768)]: {
    fontSize: 16,
  },
}));

export const BonusPackageRewardInfo: FC<BonusPackageRewardInfoProps> = ({
  nonRedeemableCoinAmount,
  redeemableCoinAmount
}) => (
  <Stack
    direction='row'
    gap={1}>
    {nonRedeemableCoinAmount && (
      <Stack
        direction='row'
        alignItems='center'>
        <Box
          component='img'
          src={smallGC.default}
          sx={commonImageStyles}
        />
        <StyledTypography>
          {nonRedeemableCoinAmount}
        </StyledTypography>
      </Stack>
    )}
    {redeemableCoinAmount && (
      <Stack
        direction='row'
        alignItems='center'>
        <Box
          component='img'
          src={smallSC.default}
          sx={commonImageStyles}
        />
        <StyledTypography>
          {redeemableCoinAmount}
          <Box
            component='span'
            sx={({ palette, breakpoints }) => ({
              color: palette.common[825],
              [breakpoints.down(1188)]: {

              }
            })}>
            &nbsp;FREE SC
          </Box>
        </StyledTypography>
      </Stack>
    )}
  </Stack>
);
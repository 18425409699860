import {
  lazy,
  memo,
  useState,
  Suspense,
  ReactNode,
  type FC,
  type MouseEvent,
} from 'react';
import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
  SvgIconTypeMap,
} from '@mui/joy';
import {
  OverridableComponent
} from '@mui/material/OverridableComponent';

const HelpOutlineIcon = lazy(() => import('@mui/icons-material/HelpOutline'));

export type TitledSectionTooltipProps = {
  title?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'open' | 'children'>;
  iconButtonProps?: IconButtonProps;
  iconProps?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

export const TitledSectionTooltip: FC<TitledSectionTooltipProps> = memo(({
  title,
  tooltipProps,
  iconButtonProps,
  iconProps,
}) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const handleTooltipClose = (): void => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = (event?: MouseEvent | undefined): void => {
    event?.stopPropagation();
    setOpenTooltip(true);
  };
  return (
    <Tooltip
      open={openTooltip}
      title={title}
      onClose={handleTooltipClose}
      placement='bottom-start'
      sx={({ palette }) => ({
        '&.MuiTooltip-root': {
          borderRadius: '0.5rem',
          background: palette.common[475],
          boxShadow: 'none',
          padding: '0.75rem',
          zIndex: 1,
          maxWidth: 353,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: palette.common[925]
        },
      })}
      {...tooltipProps}>
      <IconButton
        onClick={handleTooltipOpen}
        sx={{
          padding: 0,
          minHeight: 'min-content',
          minWidth: 'min-content',
          fontSize: '14px',
        }}
        {...iconButtonProps}>
        <Suspense>
          <HelpOutlineIcon
            sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: '1.25rem',
            })}
            {...iconProps ?? {}}
          />
        </Suspense>
      </IconButton>
    </Tooltip>
  );
});
import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  dynamicPageSlice
} from './slice';
import {
  selectDynamicPageContent
} from './common-selectors';
import {
  type DynamicPageState,
  type ContactUsContent
} from './types';
import {
  SessionState,
  sessionSlice
} from '@entities/session';

type RootState = {
  [dynamicPageSlice.name]: DynamicPageState;
  [sessionSlice.name]: SessionState
};

export const selectContactUsContent = createSelector(
  selectDynamicPageContent,
  contactUsContent => contactUsContent as Maybe<ContactUsContent>,
  { devModeChecks: { identityFunctionCheck: 'never' } }
);

export const useContactUsPageContentSelector: TypedUseSelectorHook<RootState> = useSelector;
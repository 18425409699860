import {
  type ComponentType
} from 'react';
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {
  materialTheme
} from './mui-theme';

export const withMuiTheme = <T extends object>
  (Component: ComponentType<T>) =>
  (props: T) => (
    <MaterialCssVarsProvider
      theme={{
        [MATERIAL_THEME_ID]: materialTheme
      }}>
      <Component {...props} />
    </MaterialCssVarsProvider>
  );

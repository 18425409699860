import {
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  Navigate,
  useLocation
} from 'react-router-dom';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '../model';

export type AuthenticatedOnlyRouteProps = PropsWithChildren<{
  forbiddenRoutes?: string[];
}>;

export const AuthenticatedOnlyRoute: FC<AuthenticatedOnlyRouteProps> = ({ children, forbiddenRoutes }) => {
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const location = useLocation();

  const shouldRedirectToSignIn = () => {
    if (!isAuthenticated) {
      if (forbiddenRoutes) return forbiddenRoutes.includes(location.pathname);
      else return true;
    }
    return false;
  };

  return shouldRedirectToSignIn() ? <Navigate to='/login' replace /> : children;
};
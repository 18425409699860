import {
  Middleware
} from '@reduxjs/toolkit';
import {
  isDefined
} from '@shared/lib';
import {
  type SessionState,
  sessionSlice
} from '@entities/session';
import {
  appRouter
} from '../app-router';

export const redirectAfterSignInMiddleware: Middleware =
  ({ getState }) => next => (action: unknown) => {
    const state = getState() as unknown as { [sessionSlice.name]: SessionState };

    const isNotAuthenticated = !isDefined(state[sessionSlice.name].shortProfile);
    const isSignedIn = (action as { type: string }).type === 'session/setSignedInData';

    if (isNotAuthenticated && isSignedIn) {
      const returnTo = sessionStorage.getItem('returnTo');

      if (returnTo && returnTo.startsWith('/')) {
        sessionStorage.setItem('isAfterSignIn', 'true');
        sessionStorage.removeItem('returnTo');
        appRouter.navigate(returnTo);
      }
    }
    return next(action);
  };

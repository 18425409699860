import {
  isEmpty
} from '@shared/lib';

export enum CoinPackageVariantEnum {
  Default = 'DEFAULT',
  WelcomeRed = 'WELCOME_RED',
  WelcomeYellow = 'WELCOME_YELLOW',
  MostPopular = 'MOST_POPULAR',
};

export const determineCoinPackageVariant = (str: Maybe<string>): CoinPackageVariantEnum => {
  if (isEmpty(str)) return CoinPackageVariantEnum.Default;
  else if (str?.includes(`{{'red'}}`)) return CoinPackageVariantEnum.WelcomeRed;
  else if (str?.includes(`{{'yellow'}}`)) return CoinPackageVariantEnum.WelcomeYellow;
  else return CoinPackageVariantEnum.MostPopular;
};

import {
  baseApi,
  DEFAULT_PAGE_LAYOUT_TAG
} from '@shared/api';
import {
  type LayoutsState
} from '../model';

export const pageLayoutApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getPageLayout: build.query<LayoutsState, void>({
      query: () => ({ url: '/api/default-page-layout' }),
      providesTags: [DEFAULT_PAGE_LAYOUT_TAG],
      extraOptions: { showLoader: false }
    }),
    getNonAuthenticatedPageLayout: build.query<LayoutsState, void>({
      query: () => ({ url: '/api/non-authenticated-page-layout' }),
      providesTags: [DEFAULT_PAGE_LAYOUT_TAG],
      extraOptions: { showLoader: false }
    }),
  }),
});

export const {
  useGetPageLayoutQuery,
  useGetNonAuthenticatedPageLayoutQuery,
} = pageLayoutApiSlice;

import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {
  UserStateEnum,
  type CarouselSliderItem
} from '@shared/types';
import {
  type GameCategory
} from '@entities/games';
import {
  lobbyPageApiSlice
} from '../api';

export type LobbyPageTitlePlayInThreeSteps = {
  combinedText: string;
  linkKey: string;
  anchorText: string;
  href?: Url;
  underline: 'hover' | 'always' | 'none';
};

export type LobbyPageStepPlayItem = {
  iconSrc: Url;
  title: string;
  description: string;
};

export type LobbyPagePlayInThreeSteps = {
  title: LobbyPageTitlePlayInThreeSteps;
  steps: Array<LobbyPageStepPlayItem>;
};

export type ActionItem = {
  text: string;
  url: Url;
  userState: UserStateEnum;
};

export type LobbyPageState = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
  playInThreeSteps: Maybe<LobbyPagePlayInThreeSteps>;
  slider: Maybe<Array<CarouselSliderItem>>;
  gamesByCategoriesBlockTop: Maybe<Array<GameCategory>>;
  gamesByCategoriesBlockBottom: Maybe<Array<GameCategory>>;
  tournamentWidgetTitle: Maybe<string>;
  action: Maybe<Array<ActionItem>>;
};

const initialState: LobbyPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  playInThreeSteps: null,
  slider: null,
  gamesByCategoriesBlockBottom: null,
  gamesByCategoriesBlockTop: null,
  tournamentWidgetTitle: null,
  action: null,
};

export const lobbyPageSlice = createSlice({
  name: 'lobby-page',
  initialState,
  reducers: {
    initializeState: (state: LobbyPageState, payload: PayloadAction<LobbyPageState>) => {
      state = { ...payload.payload };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<LobbyPageState>) => {
    builder.addMatcher(
      lobbyPageApiSlice.endpoints.getLobbyPage.matchFulfilled,
      (state: LobbyPageState, action: PayloadAction<any>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.playInThreeSteps = action.payload?.playInThreeSteps;
        state.slider = action.payload?.slider;
        state.gamesByCategoriesBlockBottom = action.payload?.gamesByCategoriesBlockBottom;
        state.gamesByCategoriesBlockTop = action.payload?.gamesByCategoriesBlockTop;
        state.tournamentWidgetTitle = action.payload.tournamentWidgetTitle;
        state.action = action.payload.action;
      }
    );
  },
});

export const { initializeState } = lobbyPageSlice.actions;
export const lobbyPageReducer = lobbyPageSlice.reducer;

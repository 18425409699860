import {
  useState,
  Fragment,
  type FC,
  type ReactNode,
  type ChangeEvent,
} from 'react';
import {
  styled,
  Stack,
  Checkbox,
  Typography,
  type StackProps,
  type CheckboxProps,
} from '@mui/joy';
import {
  TermsOfUseLink,
  PrivacyPolicyLink,
} from '@shared/ui';
import {
  ConsentKeyEnum,
  type PublicConsent
} from '../lib';
import {
  useGetPublicConsentsQuery
} from '../api';

const ConsentLabel = styled(Typography)(({ theme }) => ({
  color: theme.colorSchemes.dark.palette.common[150],
  lineHeight: '150%',
  fontWeight: 400,
  fontSize: 12,
}));

const config: Record<ConsentKeyEnum, { label: ReactNode; }> = {
  [ConsentKeyEnum.AgeAndGeo]: {
    label: (
      <ConsentLabel>
        I am at least 21 years old and I am not a resident of the&nbsp;
        <TermsOfUseLink text='Restricted areas' />
      </ConsentLabel>
    ),
  },
  [ConsentKeyEnum.TermsAndConditions]: {
    label: (
      <ConsentLabel>
        I accept the SweepLuxe&nbsp;<TermsOfUseLink />&nbsp;and the&nbsp;<PrivacyPolicyLink />
      </ConsentLabel>
    ),
  },
  [ConsentKeyEnum.SmsSubscribed]: {
    label: (
      <Fragment>Sms</Fragment>
    ),
  },
};

type PublicConsentsConfirmationFormSlotProps = {
  root?: StackProps;
  itemRoot?: StackProps;
  checkbox?: CheckboxProps;
};

export type PublicConsentsConfirmationFormProps = {
  disabled?: boolean;
  allowed?: Array<ConsentKeyEnum>;
  slotProps?: PublicConsentsConfirmationFormSlotProps;
  onConsentSubmit?(consents: Maybe<Array<PublicConsent>>): void;
  onConsentIdsSubmit?(consentVersionIds: Maybe<Array<number>>): void;
};

export const PublicConsentsConfirmationForm: FC<PublicConsentsConfirmationFormProps> = ({
  slotProps,
  disabled = false,
  onConsentSubmit,
  onConsentIdsSubmit,
  allowed = Object.keys(config),
}) => {
  const { isFetching, data: consents } = useGetPublicConsentsQuery(undefined, { refetchOnMountOrArgChange: true });

  const allowedConsents = consents?.filter(({ key }) => allowed.includes(key));
  const [checked, setChecked] = useState<Record<number, boolean>>({});

  const handleChange = (consentVersionId: number) => (e: ChangeEvent<HTMLInputElement>): void => {
    const newSelectedConsents = { ...checked, [consentVersionId]: e.target.checked };
    setChecked(newSelectedConsents);

    if (allowedConsents) {
      const { checkedConsents, checkedConsentsIds } = allowedConsents?.reduce((acc, { consentVersionId, ...consentOtherProps }) => {
        if (newSelectedConsents[consentVersionId]) {
          acc.checkedConsents.push({ consentVersionId, ...consentOtherProps });
          acc.checkedConsentsIds.push(consentVersionId);
        } else {
          acc.checkedConsentsIds = acc.checkedConsentsIds.filter(id => id !== consentVersionId);
          acc.checkedConsents = acc.checkedConsents.filter(({ consentVersionId: id }) => id !== consentVersionId);
        }
        return acc;
      }, { checkedConsentsIds: new Array<number>(), checkedConsents: new Array<PublicConsent>() });

      const areAllConsentsChecked = allowedConsents?.every(({ consentVersionId }) => newSelectedConsents[consentVersionId]);
      if (areAllConsentsChecked) {
        onConsentSubmit?.(checkedConsents);
        onConsentIdsSubmit?.(checkedConsentsIds);
      } else {
        onConsentSubmit?.(null);
        onConsentIdsSubmit?.(null);
      }
    }
  };

  return !isFetching && (
    <Stack
      direction='column'
      sx={[
        { gap: 1 },
        ...((slotProps && slotProps?.root) &&
          Array.isArray(slotProps?.root?.sx)
          ? slotProps?.root.sx
          : [slotProps?.root?.sx]),
      ]}
      {...slotProps?.root}>
      {allowedConsents?.map(({ id, key, consentVersionId }) => (
        <Stack
          key={id}
          direction='row'
          alignItems='flex-start'
          sx={[
            { gap: 1 },
            ...((slotProps && slotProps?.itemRoot) &&
              Array.isArray(slotProps?.itemRoot.sx)
              ? slotProps?.itemRoot?.sx
              : [slotProps?.itemRoot?.sx])
          ]}
          {...slotProps?.itemRoot}>
          <Checkbox
            disabled={disabled}
            checked={checked[consentVersionId] ?? false}
            onChange={handleChange(consentVersionId)}
            sx={[{
              gap: 1,
              height: 36,
              textAlign: 'justify',
            },
            ...((slotProps && slotProps?.itemRoot) &&
              Array.isArray(slotProps?.itemRoot.sx)
              ? slotProps?.itemRoot?.sx
              : [slotProps?.itemRoot?.sx])]}
            {...slotProps?.checkbox}
          />
          {config[key].label}
        </Stack>
      ))}
    </Stack>
  );
};
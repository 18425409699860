import {
  lazy,
  Suspense,
  type FC
} from 'react';
import {
  Stack,
  Typography
} from '@mui/joy';
import {
  Button
} from '@shared/ui';

const ArrowBackIcon = lazy(() => import('@mui/icons-material/ArrowBack'));

export type PaymentUnavailableMessageProps = {
  paymentMethodName: 'WorldPay' | 'PaymentIQ' | 'Unipaas' | 'AptPay';
  onBack?(): void;
};

export const PaymentUnavailableMessage: FC<PaymentUnavailableMessageProps> = ({
  paymentMethodName,
  onBack
}) => (
  <Stack
    direction='column'
    alignItems='center'
    justifyContent='center'
    rowGap={5}
    sx={({ breakpoints }) => ({
      padding: 4,
      minHeight: 650,
      width: '100%',
      height: '100%',
      [breakpoints.down(491)]: {
        minHeight: '100%',
      }
    })}>
    <Typography
      sx={({ breakpoints }) => ({
        fontSize: 18,
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: 'normal',
        [breakpoints.down(491)]: {
          textAlign: 'center'
        }
      })}>
      Sorry, {paymentMethodName} is currently unavailable.
    </Typography>
    {onBack && (
      <Button
        color='primary'
        onClick={onBack}
        startDecorator={(
          <Suspense>
            <ArrowBackIcon />
          </Suspense>
        )}>
        Back to payment methods
      </Button>
    )}
  </Stack>
);
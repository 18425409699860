import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  SC_FORBIDDEN_PAGES,
  selectHasSCForbiddenTag,
  sessionSlice,
  SessionState
} from '@entities/session';
import {
  layoutsSlice
} from './slice';
import {
  type PageFooterNavigationItem,
  type LayoutsState,
  type SidebarMenuItem,
  type StickyNavigation,
  type FollowUs,
} from './types';


type RootState = {
  [layoutsSlice.name]: LayoutsState;
  [sessionSlice.name]: SessionState;
};

export const selectPageHeaderVariant1LogoImgSrc = (state: RootState): Url => (
  state[layoutsSlice.name].header?.logo?.variant1SizeImgSrc ?? ''
);

export const selectPageHeaderVariant2LogoImgSrc = (state: RootState): Url => (
  state[layoutsSlice.name].header?.logo?.variant2SizeImgSrc ?? ''
);

export const selectPageFooterVariant1LogoImgSrc = (state: RootState): string => (
  state[layoutsSlice.name].footer?.logo.variant1SizeImgSrc ?? ''
);

export const selectPageFooterPromotionLegalNote = (state: RootState): string => (
  state[layoutsSlice.name].footer?.promotionLegalNote ?? ''
);

export const selectPageFooterDisclaimer = (state: RootState): string => (
  state[layoutsSlice.name].footer?.disclaimer ?? ''
);

export const selectPageFooterFollowUs = (state: RootState): Maybe<FollowUs> => (
  state[layoutsSlice.name].footer?.followUs
);

export const selectPageFooterNavigationItems = createSelector([
  (state: RootState) => state[layoutsSlice.name]?.footer?.navigationItems,
  selectHasSCForbiddenTag
],
  (navigationItems, hasSCForbiddenTag): Maybe<Array<PageFooterNavigationItem>> => (
    navigationItems?.map(({ items, ...rest }) => ({
      ...rest,
      items: items.filter(({ slug }) => !(hasSCForbiddenTag && SC_FORBIDDEN_PAGES.includes(slug)))
    }))
  ));

export const selectSidebarMenuLogo = (state: RootState): Url => (
  state[layoutsSlice.name]?.sidebarMenu?.logo.variant1SizeImgSrc ?? ''
);

export const selectSidebarMenuBanner = (state: RootState): Url => (
  state[layoutsSlice.name]?.sidebarMenu?.banner.variant1SizeImgSrc ?? ''
);

export const selectSidebarMenuItems = (state: RootState): Array<SidebarMenuItem> => (
  state[layoutsSlice.name]?.sidebarMenu?.menuItems ?? []
);

export const selectStickyNavigation = (state: RootState): Maybe<StickyNavigation> => (
  state[layoutsSlice.name].stickyNavigation
);

export const selectStickyNavigationByForbiddenTags = createSelector(
  [selectStickyNavigation, selectHasSCForbiddenTag],
  (stickyNavigation, hasSCForbiddenTag): Maybe<StickyNavigation> => {
    if (stickyNavigation) {
      let { menuItemsAuth, menuItemsNotAuth } = stickyNavigation
      menuItemsAuth = menuItemsAuth.filter(({ slug }) => !(hasSCForbiddenTag && SC_FORBIDDEN_PAGES.includes(slug)))
      return { menuItemsAuth, menuItemsNotAuth }
    } else return null;
  }
);

export const selectSignInBannerSrc = (state: RootState): Url => (
  state[layoutsSlice.name].signInBanner ?? ''
);

export const selectSignUpBannerSrc = (state: RootState): Url => (
  state[layoutsSlice.name].signUpBanner ?? ''
);

export const selectPageNonAuthenticatedHeaderVariant1LogoImgSrc = (state: RootState): Url => (
  state[layoutsSlice.name].nonAuthenticatedHeader?.logo?.variant1SizeImgSrc ?? ''
);

export const selectPageNonAuthenticatedHeaderVariant2LogoImgSrc = (state: RootState): Url => (
  state[layoutsSlice.name].nonAuthenticatedHeader?.logo?.variant2SizeImgSrc ?? ''
);

export const selectPageNonAuthenticatedFooterVariant1LogoImgSrc = (state: RootState): string => (
  state[layoutsSlice.name].nonAuthenticatedFooter?.logo.variant1SizeImgSrc ?? ''
);

export const selectPageNonAuthenticatedFooterPromotionLegalNote = (state: RootState): string => (
  state[layoutsSlice.name].nonAuthenticatedFooter?.promotionLegalNote ?? ''
);

export const selectPageNonAuthenticatedFooterFollowUs = (state: RootState): Maybe<FollowUs> => (
  state[layoutsSlice.name].nonAuthenticatedFooter?.followUs
);

export const selectPageNonAuthenticatedFooterDisclaimer = (state: RootState): Maybe<string> => (
  state[layoutsSlice.name].nonAuthenticatedFooter?.disclaimer ?? ''
);

export const selectPageNonAuthenticatedFooterNavigationItems = (state: RootState): Maybe<Array<PageFooterNavigationItem>> => (
  state[layoutsSlice.name].nonAuthenticatedFooter?.navigationItems
);

export const selectSignInNonAuthenticatedBannerSrc = (state: RootState): Url => (
  state[layoutsSlice.name].nonAuthenticatedSignInBanner ?? ''
);

export const selectSignUpNonAuthenticatedBannerSrc = (state: RootState): Url => (
  state[layoutsSlice.name].nonAuthenticatedSignUpBanner ?? ''
);

export const useLayoutSelector: TypedUseSelectorHook<RootState> = useSelector;
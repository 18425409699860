import {
  createContext
} from 'react';

export type ToastColor = 'danger' | 'success';

export type Toast = {
  id: number;
  message: string;
  type: ToastColor;
  autoHideDuration: number;
};

export type ToastOptions = Omit<Toast, 'type' | 'id' | 'autoHideDuration'> & {
  autoHideDuration?: number;
};

export const ToastContext = createContext({
  error: (options: ToastOptions) => { },
  success: (options: ToastOptions) => { }
});
import {
  SESSION_TAG,
  baseOmegaApi,
  OmegaApiResponseStatusEnum,
  type OmegaApiResponse,
} from '@shared/api';
import {
  toQueryString
} from '@shared/lib';

export type SignIn = {
  username: string;
  password: Password;
};

export type CheckLoggedInSuccessResponse = {
  status: OmegaApiResponseStatusEnum.Success;
  loggedIn: 'TRUE' | 'FALSE';
};

export type SignUpConfirmation = {
  confirmationCode: string;
  email?: Email;
  mobile?: Phone;
  consentedVersions?: Maybe<Array<number>>;
};

export type VerifyConfirmation = {
  confirmationCode: string;
  email?: Email;
  mobile?: Phone;
  type?: 'email' | 'mobile';
};

export type ResendVerificationCode = {
  username?: Email | Phone;
  verificationTarget?: 'email' | 'mobile';
};

export type SendVerifyConfirmation = Pick<ResendVerificationCode, 'verificationTarget'> & {
  email?: Maybe<Email>;
  mobile?: Maybe<Phone>;
  username: UniqueId;
};

export const authApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.query<OmegaApiResponse, SignIn>({
      query: (params: SignIn) => ({ url: '/ips/login', params: params }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: false }
    }),
    signOut: build.query<OmegaApiResponse, void>({
      query: () => ({ url: '/ips/logout' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    checkLoggedIn: build.query<CheckLoggedInSuccessResponse, void>({
      query: () => ({ url: '/ips/checkLoggedIn' }),
      extraOptions: { auth: true, showLoader: false },
    }),
    signUpConfirmation: build.query<OmegaApiResponse, Partial<SignUpConfirmation>>({
      query: (params) => ({ url: `/ips/signupConfirmation${toQueryString(params, { allowDuplicates: true })}` }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: false },
    }),
    resendVerificationCode: build.query<OmegaApiResponse, ResendVerificationCode>({
      query: (params) => ({ url: '/ips/resendVerificationCode', params }),
      extraOptions: { showLoader: false },
    }),
    sendVerifyConfirmation: build.query<OmegaApiResponse, SendVerifyConfirmation>({
      query: (params) => ({ url: '/ips/sendVerifyConfirmation', params }),
      extraOptions: { auth: true, showLoader: false },
    }),
    verifyConfirmation: build.query<OmegaApiResponse, VerifyConfirmation>({
      query: (params) => ({ url: '/ips/verifyConfirmation', params }),
      extraOptions: { auth: true, showLoader: false },
    }),
  }),
});

export const {
  useLazySignInQuery,
  useLazySignOutQuery,
  useLazyCheckLoggedInQuery,
  useSignInQuery,
  useSignOutQuery,
  useCheckLoggedInQuery,
  useSendVerifyConfirmationQuery,
  useVerifyConfirmationQuery,
  useLazySignUpConfirmationQuery,
  useLazyResendVerificationCodeQuery,
  useLazySendVerifyConfirmationQuery,
  useLazyVerifyConfirmationQuery,
} = authApiSlicer;

import {
  type FC
} from 'react';
import {
  Stack,
  Accordion,
  Typography,
  AccordionGroup,
  AccordionSummary,
  AccordionDetails
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Link
} from '@shared/ui';

export type NavigationSectionDataItem = {
  title: string;
  items: Array<{ text: string; slug: string }>;
};

export type NavigationSectionProps = {
  data: Maybe<Array<NavigationSectionDataItem>>;
};

export const NavigationSection: FC<NavigationSectionProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(430));
  return isMobile ? (
    <AccordionGroup
      disableDivider
      sx={{
        gap: 1,
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}>
      {data?.map(({ title, items }, index) => (
        <Accordion key={`${title}-${index}`} sx={{ paddingInline: 0 }}>
          <AccordionSummary
            variant='plain'
            sx={({ palette }) => ({
              '& .MuiAccordionSummary-button': {
                backgroundColor: `${palette.common[475]} !important`,
              },
              '& .MuiAccordionSummary-indicator svg': {
                fontSize: 24,
              },
              '& .MuiAccordionSummary-indicator.Mui-expanded svg': {
                color: palette.common[300],
              },
            })}>
            <Typography
              sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common.white,
              })}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {items.map(({ text, slug }) => (
              <Link
                color='neutral'
                underline='none'
                key={text}
                href={slug}>
                {text}
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionGroup>
  ) : (
    <Stack
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        columnGap: 4.5,
        rowGap: 2,
      }}>
      {data?.map(({ title, items }, index) => (
        <Stack key={`${title}-${index}`} direction='column'>
          <Typography
            sx={({ palette }) => ({
              fontSize: 16,
              color: palette.common.white,
            })}>
            {title}
          </Typography>
          <Stack direction='column'>
            {items.map(({ text, slug }) => (
              <Link
                color='neutral'
                underline='none'
                key={text}
                href={slug}>
                {text}
              </Link>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

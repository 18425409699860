import {
  lazy,
  memo,
  useState,
  ReactElement,
  type FC,
} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  IconButton
} from '@shared/ui';
import {
  isEmpty
} from '@shared/lib';
import {
  useSessionSelector,
  selectIsAuthenticated,
} from '@entities/session';
import {
  InfoToolTip
} from './info-tooltip.component';

const InfoOutlinedIcon = lazy(() => import('@mui/icons-material/InfoOutlined'));

export type GameListItemDetailsProps = {
  rtp: string;
  name: string;
  redirectTo?: Url;
  features: string;
  volatility: string;
  isOverlayActive: boolean;
  launchGameSlot: ReactElement;
  favoriteSwitcherSlot: ReactElement;
};

// TODO: refactor me
export const GameListItemDetails: FC<GameListItemDetailsProps> = memo(({
  rtp,
  name,
  features,
  redirectTo,
  volatility,
  isOverlayActive,
  launchGameSlot,
  favoriteSwitcherSlot,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const handleClickShowInfo = () => {
    setShowInfo(state => !state);
  };

  const handleCloseTooltip = () => {
    setShowInfo(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Box
        component={isEmpty(redirectTo) ? 'div' : 'a'}
        href={redirectTo}
        sx={({ breakpoints }) => ({
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.90)',
          padding: '0.5rem 0.75rem',
          transition: 'opacity 0.4s linear',
          ...(!isEmpty(redirectTo) && { cursor: 'pointer' }),
          [breakpoints.down(768)]: { padding: '0.5rem' },
          ...(isOverlayActive && {
            opacity: 1,
            cursor: 'pointer',
          }),
        })}>
        <Stack
          zIndex='1'
          direction='row'
          position='relative'
          justifyContent={isAuthenticated ? 'space-between' : 'end'}>
          {favoriteSwitcherSlot}
          {isEmpty(redirectTo) && (
            <InfoToolTip
              open={showInfo}
              features={features}
              rtp={rtp}
              volatility={volatility}>
              <IconButton
                onClick={handleClickShowInfo}
                sx={{
                  padding: 0,
                  minHeight: 'min-content',
                  minWidth: 'min-content',
                  fontSize: '14px',
                }}>
                <InfoOutlinedIcon
                  sx={({ palette }) => ({
                    fontSize: '1.25rem',
                    color: palette.common.white,
                  })}
                />
              </IconButton>
            </InfoToolTip>
          )}
        </Stack>
        {isEmpty(redirectTo) && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              gap: '0.5rem',
              alignItems: 'center',
              justifyContent: 'center',
              paddingInline: '0.25rem',
            }}>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: '1.5rem',
                textAlign: 'center',
              })}>
              {name}
            </Typography>
            {launchGameSlot}
          </Stack>
        )}
      </Box>
    </ClickAwayListener>
  );
});

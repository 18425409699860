import {
  baseOmegaApi
} from '@shared/api';
import {
  OmegaApiResponse,
} from '@shared/api';
import {
  mapPaymentInformation,
  type PaymentInfoSimplified
} from '../lib';
import {
  CurrencyCodesEnum,
  PaymentMethodKeyEnum
} from '@shared/types';

export type PrepareWithdrawal = {
  paymentMethod: PaymentMethodKeyEnum;
  action?: 'GET_EXCHANGE_INFO';
};

export type PrepareWithdrawalResponse = OmegaApiResponse & {
  conversionId: number;
};

export type ProcessWithdrawal = {
  paymentMethod: PaymentMethodKeyEnum;
  amount: number;
  conversionId: number;
  bankName?: string;
  bankAccountNumber?: string;
  bankTransitNumber?: string;
  bankAccountType?: 'checking' | 'saving';
  paymentInformationId?: UniqueId;
};

export type ProcessWithdrawalResponse = OmegaApiResponse & {
  paymentId: number;
  continueUrl: string;
  payPageId: string;
  reportGroupId: string;
  merchantTxId: number;
  orderId: string;
};

export type CheckPaymentStatus = {
  paymentMethod: PaymentMethodKeyEnum;
  paymentId: string;
};

export enum PaymentStatusEnum {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
};

export type CheckPaymentStatusResponse = OmegaApiResponse & {
  paymentId: number;
  paymentStatus: PaymentStatusEnum;
  amount: string;
  currency: CurrencyCodesEnum;
};

export type CancelWithdrawal = {
  paymentId: Maybe<number>;
};

export type CancelWithdrawalResponse = OmegaApiResponse & {
  paymentId: number;
  amount: string;
  partialAmount: string;
  referenceNumber: string;
  currentBalance: string;
  currency: CurrencyCodesEnum;
};

export type GetPaymentInformation = {
  type: 'BANK';
};

export type PaymentInformationDetail = {
  id: UniqueId;
  paymentInformationId: UniqueId;
  key: string;
  value: string;
}

export type PaymentInformation = {
  id: UniqueId;
  partyId: number;
  type: 'BANK';
  paymentMethods: string[];
  description: string;
  priority: number;
  ccExpired: boolean;
  details: Array<PaymentInformationDetail>;
  active: boolean;
  detailsAsMap: Record<string, string>;
}

export type GetPaymentInformationResponse = OmegaApiResponse & {
  data: Array<PaymentInformation>
};

export const worldPayBuyCoinsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    prepareWithdrawal: build.query<PrepareWithdrawalResponse, PrepareWithdrawal>({
      query: params => ({ url: '/ips/prepareWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    processWithdrawal: build.query<ProcessWithdrawalResponse, ProcessWithdrawal>({
      query: (params) => ({ url: '/ips/processWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    checkPaymentStatus: build.query<CheckPaymentStatusResponse, CheckPaymentStatus>({
      query: (params) => ({ url: '/ips/checkPaymentStatus', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    cancelWithdrawal: build.query<CancelWithdrawalResponse, CancelWithdrawal>({
      query: (params) => ({ url: '/ips/cancelWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    getPaymentInformation: build.query<Array<PaymentInfoSimplified>, GetPaymentInformation>({
      query: (params) => ({ url: '/ips/getPaymentInformation', params }),
      extraOptions: { auth: true, showLoader: true },
      transformResponse: (response: { data: Array<PaymentInformation> }) => {
        return response.data.map(mapPaymentInformation)
      }
    }),
  }),
});

export const {
  useProcessWithdrawalQuery,
  useLazyProcessWithdrawalQuery,
  useCheckPaymentStatusQuery,
  useLazyCheckPaymentStatusQuery,
  useCancelWithdrawalQuery,
  useLazyCancelWithdrawalQuery,
  usePrepareWithdrawalQuery,
  useLazyPrepareWithdrawalQuery,
  useGetPaymentInformationQuery,
  useLazyGetPaymentInformationQuery,
} = worldPayBuyCoinsApiSlicer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageLayoutApiSlice } from '../api';
import { mapLayoutState } from '../lib';
import { type LayoutsState } from './types';

export const initialState: LayoutsState = {
  header: null,
  footer: null,
  sidebarMenu: null,
  stickyNavigation: null,
  signInBanner: null,
  signUpBanner: null,
  nonAuthenticatedHeader: null,
  nonAuthenticatedFooter: null,
  nonAuthenticatedSignInBanner: null,
  nonAuthenticatedSignUpBanner: null,
};

export const layoutsSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    setLayout: (state: LayoutsState, action: PayloadAction<LayoutsState>) => {
      state.header = action.payload.header;
      state.footer = action.payload.footer;
      state.sidebarMenu = action.payload.sidebarMenu;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pageLayoutApiSlice.endpoints.getPageLayout.matchFulfilled,
      (state: LayoutsState, action: PayloadAction<any>) => {
        const mappedState = mapLayoutState(action.payload);
        state.header = mappedState.header;
        state.footer = mappedState.footer;
        state.sidebarMenu = mappedState.sidebarMenu;
        state.stickyNavigation = mappedState.stickyNavigation;
        state.signInBanner = mappedState.signInBanner;
        state.signUpBanner = mappedState.signUpBanner;
      }
    );
    builder.addMatcher(
      pageLayoutApiSlice.endpoints.getNonAuthenticatedPageLayout.matchFulfilled,
      (state: LayoutsState, action: PayloadAction<any>) => {
        const mappedState = mapLayoutState(action.payload);
        state.nonAuthenticatedHeader = mappedState.header;
        state.nonAuthenticatedFooter = mappedState.footer;
        state.nonAuthenticatedSignInBanner = mappedState.signInBanner;
        state.nonAuthenticatedSignUpBanner = mappedState.signUpBanner;
      }
    );
  },
});

export const { setLayout } = layoutsSlice.actions;
export const layoutsReducer = layoutsSlice.reducer;
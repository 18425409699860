import {
  type FC
} from 'react';
import {
  styled
} from '@mui/joy';
import {
  GoogleLogin,
  type GoogleLoginProps
} from '@react-oauth/google';

const GoogleLoginWrapper = styled('div')(() => ({
  overflow: 'hidden',
  width: 173,
  height: 32,
  borderRadius: 8
}));

export type GoogleLoginButtonProps = GoogleLoginProps;

export const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({ ...props }) => (
  <GoogleLoginWrapper>
    <GoogleLogin {...props} />
  </GoogleLoginWrapper>
);
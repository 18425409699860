import {
  type FC
} from 'react';
import {
  Typography
} from '@mui/joy';

export const WorldPayDebitCardRestrictions: FC = () => (
  <Typography
    sx={({ palette }) => ({
      gridColumnStart: 1,
      gridColumnEnd: 3,
      padding: 2,
      borderRadius: 8,
      fontWeight: 400,
      lineHeight: '150%',
      width: '100%',
      color: palette.common[150],
      backgroundColor: palette.common[900],
      textAlign: 'justify',
    })}>
    Redemption requests are only supported with Debit Cards issued by physical US banks from Visa and
    MasterCard. Request to digital banks, prepaid cards, or accounts outside the US are not available at
    this time.
    <br />
    We are actively working to add more options. For now, we kindly ask you to use the available methods or
    contact Support to get additional help
  </Typography>
);
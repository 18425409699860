import {
  type RewardBonusPlanId
} from '../model';

export const getRandomRewardIdBasedOnPercentage = (rewardBonusPlanIds: Array<RewardBonusPlanId>): RewardBonusPlanId => {
  if (rewardBonusPlanIds.length === 1) return rewardBonusPlanIds[0];

  const randomValue = Math.random() * 100;

  let cumulativePercentage = 0;

  for (const plan of rewardBonusPlanIds) {
    cumulativePercentage += plan.bonusIdSelectionRate;
    if (randomValue <= cumulativePercentage) {
      return plan;
    }
  }

  return rewardBonusPlanIds[rewardBonusPlanIds.length - 1];
};
import {
  configureStore
} from '@reduxjs/toolkit';
import {
  setupListeners
} from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  baseApi,
  baseOmegaApi
} from '@shared/api';
import {
  circularLoaderSlice
} from '@shared/ui/circular-loader';
import {
  featureToggles
} from '@shared/config';
import {
  sessionSlice
} from '@entities/session';
import {
  messagesSlice
} from '@entities/messages';
import {
  layoutsSlice
} from '@entities/layouts';
import {
  gamesSlice
} from '@entities/games';
import {
  tournamentsSlice
} from '@entities/tournaments';
import {
  transactionsSlice
} from '@entities/transactions';
import {
  gameWinnersSlice
} from '@entities/game-winners';
import {
  challengesSlice
} from '@entities/challenges';
import {
  paymentsSlice
} from '@entities/payments';
import {
  managePromotionalSubscriptionsSlice
} from '@features/manage-promotional-subscriptions';
import {
  redeemCoinsSlice
} from '@features/redeem-coins';
import {
  buyCoinsSlice
} from '@features/buy-coins';
import {
  documentsSlice
} from '@features/upload-documents';
import {
  zipCodeInfoApiSlice,
  editPlayerProfileSlice,
} from '@features/edit-player-profile';
import {
  transactionFilterByTypeSlice
} from '@features/transaction-filter-by-type';
import {
  authViaSocialsSlice
} from '@features/authentication-via-socials';
import {
  promotionsFilteredByCategoriesSlice
} from '@features/promotions-filter-by-category';
import {
  claimBonusCoinsSlice,
} from '@features/claim-bonus-coins';
import {
  transactionListSlice
} from '@widgets/transaction-list';
import {
  lobbyPageSlice
} from '@pages/lobby';
import {
  gamesPageSlice,
  applyGameCategoryIdsMiddleware
} from '@pages/games';
import {
  tournamentsPageSlice
} from '@pages/tournaments';
import {
  dynamicPageSlice
} from '@pages/dynamic';
import {
  promotionsPageSlice
} from '@pages/promotions';
import {
  accountPageSlice
} from '@pages/account';
import {
  luxeClubPageSlice
} from '@pages/luxe-club';
import {
  tournamentDetailsPageSlice
} from '@pages/tournament-details';
import {
  promotionDetailsPageSlice
} from '@pages/promotion-details';
import {
  challengesPageSlice
} from '@pages/challenges';
import {
  playGamePageSlice
} from '@pages/play-game';
import {
  landingPageSlice,
} from '@pages/landing';
import {
  handleNotLoggedInErrorMiddleware,
  keepReturnToPathAfterSignInMiddleware,
  redirectAfterSignInMiddleware
} from '@app/middlewares';
import {
  rootReducer
} from './root-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    gamesSlice.name,
    layoutsSlice.name,
    sessionSlice.name,
    paymentsSlice.name,
    messagesSlice.name,
    buyCoinsSlice.name,
    gamesPageSlice.name,
    documentsSlice.name,
    lobbyPageSlice.name,
    redeemCoinsSlice.name,
    landingPageSlice.name,
    tournamentsSlice.name,
    dynamicPageSlice.name,
    gameWinnersSlice.name,
    luxeClubPageSlice.name,
    playGamePageSlice.name,
    transactionsSlice.name,
    authViaSocialsSlice.name,
    promotionsPageSlice.name,
    circularLoaderSlice.name,
    tournamentsPageSlice.name,
    claimBonusCoinsSlice.name,
    editPlayerProfileSlice.name,
    promotionDetailsPageSlice.name,
    tournamentDetailsPageSlice.name,
    managePromotionalSubscriptionsSlice.name,
    promotionsFilteredByCategoriesSlice.name,
    ...(featureToggles.challenges ? [
      challengesSlice.name,
      challengesPageSlice.name
    ] : []),
  ],
  blacklist: [
    accountPageSlice.name,
    transactionListSlice.name,
    transactionFilterByTypeSlice.name,
  ]
};

const reducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: [/omegaApi.queries.uploadUserDocument/],
        },
      })
        .concat(baseApi.middleware)
        .concat(baseOmegaApi.middleware)
        .concat(zipCodeInfoApiSlice.middleware)
        .concat(handleNotLoggedInErrorMiddleware)
        .concat(applyGameCategoryIdsMiddleware)
        // TODO: why need these two same middlewares? 
        .concat(keepReturnToPathAfterSignInMiddleware)
        .concat(redirectAfterSignInMiddleware)
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();
export const persister = persistStore(store);

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;

import {
  useState,
  useEffect
} from 'react';
import {
  type ChestWithBonuses
} from '../model';

export const useBonusProgressTracker = ({ chest }: { chest: ChestWithBonuses }) => {
  const [currentProgressStep, setCurrentProgressStep] = useState<number>(0);

  const { purchaseBonuses, repeat, steps, rewardBonuses } = chest;
  const purchaseBonusesLength = purchaseBonuses.length;
  const rewardBonusesLength = rewardBonuses.length;

  const isChestClaimed = rewardBonusesLength >= repeat + 1;
  const isChestOpened = currentProgressStep === steps;
  const stepsAfterLastReward = purchaseBonusesLength - (rewardBonusesLength * steps);

  useEffect(() => {
    if (stepsAfterLastReward < 0) {
      setCurrentProgressStep(purchaseBonusesLength % steps);
    } else {
      setCurrentProgressStep(Math.min(stepsAfterLastReward, steps));
    }
  }, [chest]);

  return {
    isChestOpened,
    isChestClaimed,
    currentProgressStep,
    purchaseBonusesLength,
  };
};
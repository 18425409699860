import {
  lazy,
  Suspense,
  useCallback,
  type FC,
} from 'react';
import {
  styled,
  useTheme
} from '@mui/joy';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch
} from 'redux-thunk';
import {
  UnknownAction
} from 'redux';
import {
  IconButton
} from '@shared/ui';
import {
  type GamesItem,
  addFavoriteGame,
  useGamesSelector,
  removeFavoriteGame,
  selectIsGameFavorite,
  selectIsFavoriteLoadingFromOmega,
} from '@entities/games';
import {
  useSessionSelector,
  selectIsAuthenticated,
} from '@entities/session';

const StarIcon = lazy(() => import('@mui/icons-material/Star'));
const StarBorderOutlinedIcon = lazy(() => import('@mui/icons-material/StarBorderOutlined'));

const StyledIconButton = styled(IconButton)({
  padding: 0,
  minHeight: 'min-content',
  minWidth: 'min-content',
  fontSize: '14px',
});

type FavoriteGameSwitcherProps = {
  game: GamesItem;
};

export const FavoriteGameSwitcher: FC<FavoriteGameSwitcherProps> = ({ game }) => {
  const theme = useTheme();
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const isFavorite = useGamesSelector(selectIsGameFavorite(game.launchId, game.productCode));
  const isFetchingFavoriteGames = useGamesSelector(selectIsFavoriteLoadingFromOmega);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const handleFavoriteClick = useCallback(() => {
    if (isFetchingFavoriteGames) return;
    isFavorite
      ? dispatch(removeFavoriteGame({ gameId: game.gameId, platformCode: game.productCode }))
      : dispatch(addFavoriteGame({ gameId: game.gameId, platformCode: game.productCode }));
  }, [isFetchingFavoriteGames, isFavorite, game, dispatch]);

  return (
    isAuthenticated && (
      <StyledIconButton onClick={handleFavoriteClick}>
        {isFavorite ? (
          <Suspense>
            <StarIcon
              sx={{
                color: theme.palette.common[1060],
                fontSize: '1.25rem',
                [theme.breakpoints.down(768)]: {
                  fontSize: '1rem',
                },
              }}
            />
          </Suspense>
        ) : (
          <Suspense>
            <StarBorderOutlinedIcon
              sx={{
                color: theme.palette.common.white,
                fontSize: '1.25rem',
                [theme.breakpoints.down(768)]: {
                  fontSize: '1rem',
                },
              }}
            />
          </Suspense>
        )}
      </StyledIconButton>
    )
  );
};

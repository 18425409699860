import { baseOmegaApi } from '@shared/api';
import {
  type GetEmailSubscriptionStateResponse,
  type UpdateEmailSubscription
} from './types';

export const managePromotionalSubscriptionsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getEmailSubscriptionState: build.query<GetEmailSubscriptionStateResponse, void>({
      query: () => ({ url: '/ips/getEmailOptions' }),
      extraOptions: { auth: true, showLoader: true },
    }),
    updateEmailSubscription: build.query<void, UpdateEmailSubscription>({
      query: (params) => ({ url: '/ips/updateEmailOptions', params }),
      extraOptions: { auth: true, showLoader: true }
    })
  }),
});

export const {
  useGetEmailSubscriptionStateQuery,
  useLazyUpdateEmailSubscriptionQuery,
} = managePromotionalSubscriptionsApiSlicer;

import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  gamesPageApiSlice,
  GamesPageResponse,
} from '../api';

export type GamesPageState = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
};

const initialState: GamesPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
};

export const gamesPageSlice = createSlice({
  name: 'games-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GamesPageState>) => {
    builder.addMatcher(
      gamesPageApiSlice.endpoints.getGamesPage.matchFulfilled,
      (state: GamesPageState, action: PayloadAction<GamesPageResponse>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
      }
    );
  },
});

export const gamesPageReducer = gamesPageSlice.reducer;

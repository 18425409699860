import {
  baseApi,
  DYNAMIC_PAGE_TAG
} from '@shared/api';
import {
  buildStrapiQuery,
  type Filter
} from '@shared/lib';
import {
  DynamicPageState
} from '../model';
import {
  mapDynamicPageState
} from '../lib';

export type GetDynamicPageArgs = {
  filters: Filter;
  isKycPassed?: boolean;
  isAuthenticated?: boolean;
  isPlayer?: boolean;
};

export enum GetDynamicPageApiNameErrors {
  KycRequired = 'KycRequired',
  AuthRequired = 'AuthRequired',
  NotFoundError = 'NotFoundError',
  PlayerRegistrationRequired = 'PlayerRegistrationRequired'
};

export const dynamicPageApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getDynamicPage: build.query<DynamicPageState, GetDynamicPageArgs>({
      query: (args: GetDynamicPageArgs) => {
        const { filters, ...params } = args;
        return ({ url: `/api/dynamic-pages?${buildStrapiQuery(filters)}`, params })
      },
      extraOptions: { showLoader: true },
      providesTags: [DYNAMIC_PAGE_TAG],
      transformResponse: (response: { data: unknown[] }) => {
        return mapDynamicPageState(response.data[0]);
      },
      transformErrorResponse: (error: { data: { error: { name: GetDynamicPageApiNameErrors } } }) => {
        return error?.data?.error?.name || 'UnknownError';
      },
    })
  }),
});

export const {
  useGetDynamicPageQuery,
  useLazyGetDynamicPageQuery
} = dynamicPageApiSlice;

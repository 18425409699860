import * as Yup from 'yup';

export const coinRedemptionSchema = (userBalance: number) => {
  return userBalance < 2000.01
    ? Yup.object().shape({
      amount: Yup.number().typeError('This field is required')
        .required('This field is required')
        .min(100.00, 'You must accumulate at least 100 redeemable Sweeps Coins before you can redeem')
        .lessThan(userBalance + 0.01, 'Not enough amount of redeemable coins')
    })
    : Yup.object().shape({
      amount: Yup.number().typeError('This field is required')
        .required('This field is required')
        .min(100.00, 'You must accumulate at least 100 redeemable Sweeps Coins before you can redeem')
        .lessThan(2000.01, 'The maximum amount that can be redeemed at one time is 2000 SC')
    });
}
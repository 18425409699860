import {
  baseApi,
  OmegaApiResponse,
  SESSION_TAG
} from '@shared/api';

export type SubmissionPlayerProfile = {
  firstName: string;
  lastName: string;
  nickname: string;
  email?: Email;
  mobile?: Phone;
  birthDate: string;
  address: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  registrationStatus: string;
  kycStatus: string;
  receiveEmail?: boolean;
};

export const editPlayerProfileApiSlicer = baseApi.injectEndpoints({
  endpoints: build => ({
    quickSignupCompletion: build.query<OmegaApiResponse, SubmissionPlayerProfile>({
      query: (arg: SubmissionPlayerProfile) => ({ url: '/api/sign-up-completion', params: arg, }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
  }),
});

export const {
  useLazyQuickSignupCompletionQuery,
  useQuickSignupCompletionQuery,
} = editPlayerProfileApiSlicer;



import {
  lazy,
  Fragment,
  Suspense,
  type FC,
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Button
} from '@shared/ui';
import {
  useLayoutSelector,
  selectPageNonAuthenticatedHeaderVariant1LogoImgSrc,
  selectPageNonAuthenticatedHeaderVariant2LogoImgSrc,
} from '@entities/layouts';

const AppLogo = lazy(() => import('@shared/ui').then((module) => ({ default: module.AppLogo })));

export const NonAuthenticatedPageHeader: FC = () => {
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down(912));
  const isDesktop = useMediaQuery(breakpoints.up(912));

  const variant1LogoImgSrc = useLayoutSelector(selectPageNonAuthenticatedHeaderVariant1LogoImgSrc);
  const variant2LogoImgSrc = useLayoutSelector(selectPageNonAuthenticatedHeaderVariant2LogoImgSrc);

  const handleRedirectTo = (slug: Url) => (): void => {
    navigate(slug, { replace: true });
  };

  return (
    <Stack
      component='header'
      justifyContent='center'
      alignItems='center'
      sx={({ palette }) => ({
        zIndex: 5,
        width: '100%',
        paddingInline: isMobile ? 2 : 10,
        backgroundColor: palette.common[475],
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1280,
          height: 68,
          gap: 0.5,
          [breakpoints.down(491)]: {
            justifyContent: 'center',
          },
        })}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          gap={1.25}>
          {variant2LogoImgSrc && (
            <Suspense>
              <AppLogo
                redirectTo='/'
                src={variant2LogoImgSrc}
                sx={({ breakpoints }) => ({
                  height: 36,
                  [breakpoints.down(491)]: {
                    height: 48,
                  },
                })}
              />
            </Suspense>
          )}
        </Stack>
        {isDesktop && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            columnGap={1}>
            <Button
              size='md'
              onClick={handleRedirectTo('/login')}>
              Log In
            </Button>
            <Button
              size='md'
              color='success'
              onClick={handleRedirectTo('/sign-up')}>
              Sign Up
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

import {
  mapper,
} from '@shared/lib';
import {
  addThousandSeparators
} from './add-thousand-separators';
import {
  extractCoinPageTitle
} from './extract-coin-package-title';
import {
  CoinPackageVariantEnum,
  determineCoinPackageVariant
} from './determine-coin-package-variant';

export type CoinPackage = {
  id: number;
  brandId?: number;
  order: number;
  isLimited: boolean;
  originPrice: number;
  price: string;
  originCurrency: string;
  currency: string;
  redeemableCoinAmount: number;
  nonRedeemableCoinAmount: number;
  title: Maybe<string>;
  variant: CoinPackageVariantEnum;
  promoTAndCApplyUrl: Maybe<Url>;
  bonusPlanId?: number;
};

type MapToCoinPackage = (data: any) => CoinPackage;
export const mapToCoinPackage: MapToCoinPackage = mapper([
  { name: 'id', from: 'packageId' },
  { name: 'brandId' },
  { name: 'bonusPlanId' },
  { name: 'originPrice', from: 'purchasePrice' },
  {
    name: 'price',
    from: 'purchasePrice',
    transform: (price: number) => addThousandSeparators(price),
  },
  { name: 'originCurrency', from: 'purchaseCurrency' },
  {
    name: 'currency',
    from: 'purchaseCurrency',
    transform: (symbol: string) => symbol.includes('USD') ? '$' : symbol
  },
  {
    name: 'redeemableCoinAmount',
    transform: (redeemableCoinAmount: number) => addThousandSeparators(redeemableCoinAmount),
  },
  {
    name: 'nonRedeemableCoinAmount',
    transform: (nonRedeemableCoinAmount: number) => addThousandSeparators(nonRedeemableCoinAmount),
  },
  {
    name: 'title',
    from: 'description',
    transform: (description: Maybe<string>) => extractCoinPageTitle(description)
  },
  {
    name: 'variant',
    from: 'description',
    transform: (description: Maybe<string>) => determineCoinPackageVariant(description)
  },
  {
    name: 'isLimited',
    from: 'description',
    transform: (description: Maybe<string>) => determineCoinPackageVariant(description) !== CoinPackageVariantEnum.Default
  },
  {
    name: 'promoTAndCApplyUrl',
    from: 'imageUrl',
  },
  {
    name: 'order',
    from: ['description', 'purchasePrice'],
    transform: (fields: [string, number]) => {
      const [description, price] = fields;
      const match = description.match(/{{'order'}}:(\d+)/);
      if (match && match[1]) return parseInt(match[1], 10);
      const scalingFactor = 100;
      const scaledPrice = Math.round(price * scalingFactor);
      return scaledPrice;
    },
  },
]);
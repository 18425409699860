import {
  memo,
  type FC
} from 'react';
import {
  Sheet,
  Stack,
  Typography,
  styled
} from '@mui/joy';
import {
  CoinPackage,
  CoinPackageVariantEnum
} from '../lib';
import {
  CoinPackageTitle
} from './coin-package-title.component';
import {
  CoinPackageSummary
} from './coin-package-summary.component';

const Container = styled(Stack)<{ variant: CoinPackageVariantEnum; }>(({ variant, theme }) => ({
  width: '100%',
  maxWidth: 406,
  overflow: 'hidden',
  position: 'relative',
  height: 'fit-content',
  borderRadius: 8,
  paddingTop: 34,
  paddingBottom: 11,
  paddingInline: 16,
  background: theme.palette.common[900],
  ...(variant === CoinPackageVariantEnum.MostPopular && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common[700]}`,
    background: theme.palette.common[565],
  })),
  ...(variant === CoinPackageVariantEnum.WelcomeRed && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common.error}`,
    background: theme.palette.common[565],
  })),
  ...(variant === CoinPackageVariantEnum.WelcomeYellow && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common[1085]}`,
    background: theme.palette.common[565],
  })),
  ...(variant === CoinPackageVariantEnum.Default && ({
    paddingTop: 16,
    background: theme.palette.common[565],
    boxShadow: `0px 0px 8px .5px ${theme.palette.common[350]}`,
  })),
  [theme.breakpoints.down(491)]: {
    background: variant !== CoinPackageVariantEnum.MostPopular
      ? theme.palette.common[475]
      : 'inherit',
  },
}));

const Content = styled(Sheet)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr fit-content(100%)',
  background: 'transparent',
}));

const Total = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  justifyContent: 'flex-end',
}));

export type ChosenCoinPackageCardProps = {
  data?: Maybe<CoinPackage>;
};

export const ChosenCoinPackageCard: FC<ChosenCoinPackageCardProps> = memo(({ data }) => data && (
  <Container variant={data?.variant || undefined}>
    <CoinPackageTitle
      text={data.title}
      variant={data.variant}
    />
    <Content>
      <CoinPackageSummary
        variant={data.variant}
        redeemableCoinAmount={data.redeemableCoinAmount}
        nonRedeemableCoinAmount={data.nonRedeemableCoinAmount}
      />
      <Total>Total: {data.currency}{data.price}</Total>
    </Content>
  </Container>
));
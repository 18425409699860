import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectIsAuthenticated } from '@entities/session';

type LaunchGame = {
  launchGame(gameId: number): void;
};

export const useLaunchGame = (): LaunchGame => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isAuth = useSelector(selectIsAuthenticated);

  const launchGame = (gameId: number) => {
    if (!isAuth) {
      urlSearchParams.append('login', 'true');
      setUrlSearchParams(urlSearchParams, { replace: true });
    } else {
      navigate(`/games/${gameId}`);
    }
  };

  return {
    launchGame,
  };
};

import {
  lazy,
  Fragment,
  Suspense,
  type FC,
} from 'react';
import {
  Divider,
  Typography,
  Stack,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  FollowUsBlock,
  NavigationSection,
  useLayoutSelector,
  selectPageNonAuthenticatedFooterFollowUs,
  selectPageNonAuthenticatedFooterDisclaimer,
  selectPageNonAuthenticatedFooterNavigationItems,
  selectPageNonAuthenticatedFooterVariant1LogoImgSrc,
  selectPageNonAuthenticatedFooterPromotionLegalNote,
} from '@entities/layouts';

const AppLogo = lazy(() => import('@shared/ui').then((module) => ({ default: module.AppLogo })));

export type NonAuthenticatedPageFooterProps = {
  keep?: boolean;
};

export const NonAuthenticatedPageFooter: FC<NonAuthenticatedPageFooterProps> = ({ keep = false }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(912));
  const isMobile = useMediaQuery(theme.breakpoints.down(491));
  const navigationItems = useLayoutSelector(selectPageNonAuthenticatedFooterNavigationItems);
  const variant1LogoImgSrc = useLayoutSelector(selectPageNonAuthenticatedFooterVariant1LogoImgSrc);
  const promotionLegalNote = useLayoutSelector(selectPageNonAuthenticatedFooterPromotionLegalNote);
  const followUs = useLayoutSelector(selectPageNonAuthenticatedFooterFollowUs);
  const disclaimer = useLayoutSelector(selectPageNonAuthenticatedFooterDisclaimer);
  return (!isMobile || keep === true) && (
    <Stack
      component='footer'
      gap={3}
      sx={({ palette, breakpoints }) => ({
        width: '100%',
        maxWidth: 1280,
        paddingBlock: 6,
        backgroundColor: palette.common[475],
        [breakpoints.down(913)]: {
          paddingBlock: 3,
        },
      })}>
      <Stack
        direction='column'
        gap={2}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%',
            ...(isTablet && ({
              flexDirection: 'column',
              gap: 1,
            }))
          }}>
          {variant1LogoImgSrc && (
            <Suspense>
              <AppLogo
                redirectTo='/'
                src={variant1LogoImgSrc}
                sx={{
                  width: 210,
                  height: 35.4,
                  boxSizing: 'content-box',
                }}
              />
            </Suspense>
          )}
          <FollowUsBlock
            title={followUs?.title ?? ''}
            socialMedia={followUs?.socialMedia ?? []}
          />
        </Stack>
        {isTablet ? (
          <Fragment>
            <Stack direction='column' gap={2}>
              <NavigationSection data={navigationItems} />
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
            </Stack>
            <Stack
              direction='column'
              gap={2}
              width='100%'>
              <Typography
                sx={({ palette }) => ({
                  width: 'fit-content',
                  padding: .5,
                  fontSize: 16,
                  fontWeight: 500,
                  borderRadius: 8,
                  fontStyle: 'normal',
                  color: palette.common[300],
                  backgroundColor: palette.common[900],
                })}>
                +21
              </Typography>
              <Divider />
              <Stack
                direction='column'
                justifyContent='space-between'
                width='100%'>
                {disclaimer && (
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 2,
                      lineHeight: '24px',
                      fontStyle: 'normal',
                      color: palette.common[150]
                    })}>
                    {disclaimer}
                  </Typography>
                )}
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'>
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '24px',
                      fontStyle: 'normal',
                      color: palette.common[150]
                    })}>
                    &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        ) : (
          <Fragment>
            <Stack
              direction='column'
              gap={2}>
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '20px',
                    fontStyle: 'normal',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
              <NavigationSection data={navigationItems} />
            </Stack>
            <Stack
              direction='column'
              gap={4}
              width='100%'>
              <Divider />
              <Stack
                direction='column'
                justifyContent='space-between'
                width='100%'>
                {disclaimer && (
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 2,
                      lineHeight: '24px',
                      fontStyle: 'normal',
                      color: palette.common[150]
                    })}>
                    {disclaimer}
                  </Typography>
                )}
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'>
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '24px',
                      fontStyle: 'normal',
                      color: palette.common[150]
                    })}>
                    &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      borderRadius: 8,
                      padding: .5,
                      fontSize: 16,
                      fontWeight: 500,
                      fontStyle: 'normal',
                      color: palette.common[300],
                      backgroundColor: palette.common[900],
                    })}>
                    +21
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        )}
      </Stack>
    </Stack>
  );
};
import {
  styled,
  Typography
} from '@mui/joy';

export const CheckboxFormLabel = styled(Typography)(({ theme }) => ({
  color: theme.colorSchemes.dark.palette.common[150],
  fontWeight: 400,
  lineHeight: '150%',
  fontSize: '12px',
}));
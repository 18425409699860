import {
  baseApi
} from '@shared/api';
import {
  type TournamentsPageState
} from '../model';
import {
  mapTournamentsPageState
} from '../lib';

export const tournamentsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTournamentsPage: build.query<TournamentsPageState, void>({
      query: () => ({ url: '/api/tournaments-page' }),
      transformResponse: (response: any) => mapTournamentsPageState(response),
    }),
  }),
});

export const { useGetTournamentsPageQuery } = tournamentsPageApiSlice;

import {
  baseApi,
  GAMES_PAGE_TAG
} from '@shared/api';
import {
  mapGamesPageState
} from '../lib';

export type GamesPageResponse = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
  categoryIds: Array<number>;
}

export const gamesPageApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getGamesPage: build.query<GamesPageResponse, void>({
      query: () => ({ url: '/api/games-page' }),
      extraOptions: { showLoader: true },
      providesTags: [GAMES_PAGE_TAG],
      transformResponse: (response: any) => mapGamesPageState(response),
    }),
  }),
});

export const { useGetGamesPageQuery } = gamesPageApiSlice;

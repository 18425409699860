import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  OmegaApiResponseStatusEnum,
  type OmegaApiResponse,
} from '@shared/api';
import {
  type ChannelMessage
} from '../model';
import {
  mapMessages
} from '../lib';
import {
  messagesApiSlice,
  type GetMessagesHistoryPayload
} from './slice';

export type FetchMessagesResponse = {
  channelId: string;
  messages: Array<ChannelMessage>;
};

// TODO: refactor me - replace to regular redux-toolkit slice 
export const fetchMessages = createAsyncThunk<FetchMessagesResponse, GetMessagesHistoryPayload>(
  'fetch-messages',
  async (payload: GetMessagesHistoryPayload, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const responseFromOmega = await dispatch(
        messagesApiSlice.endpoints.getMessagesHistory.initiate(payload)
      ).unwrap();

      const { status } = responseFromOmega as OmegaApiResponse;
      if (status !== OmegaApiResponseStatusEnum.Success) {
        throw new Error('Fail to fetch messages from OmegaApi');
      }

      const messages = mapMessages(responseFromOmega);

      return fulfillWithValue({ messages, channelId: payload.channel });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

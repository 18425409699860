import {
  FC,
  useRef,
  useEffect
} from 'react';
import {
  Stack,
  styled,
  Typography
} from '@mui/joy';
import _PaymentIQCashier, {
  type IPiqCashierApiMethods,
  type Environment
} from 'paymentiq-cashier-bootstrapper';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  selectSessionKey,
  useSessionSelector,
  selectShortProfile
} from '@entities/session';
import {
  type CheckoutResult
} from '@entities/payments';
import {
  mapRedeemCashierConfig
} from '../lib';

export const ConfirmCoinRedemptionIframe = styled('div')(() => ({
  width: '100%',
  height: '100%',
  border: 'none',
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export type ConfirmCoinRedemptionPaymentIQFormProps = {
  redeemAmount: number
  onClose?(): void
  onStart?: (amount: string | number) => void;
  onSuccess?: (redeemResult?: CheckoutResult) => void;
  onFail: (redeemResult?: CheckoutResult) => void;
}

export const ConfirmCoinRedemptionPaymentIQForm: FC<ConfirmCoinRedemptionPaymentIQFormProps> = ({
  redeemAmount,
  onClose,
  onStart,
  onSuccess,
  onFail,
}) => {
  const cashierRef = useRef<HTMLDivElement>(null);
  const shortProfile = useSessionSelector(selectShortProfile);
  const sessionKey = useSessionSelector(selectSessionKey);

  useEffect(() => {
    if (cashierRef.current) {
      const config = mapRedeemCashierConfig({
        environment: `${import.meta.env.VITE_PAYMENT_ENVIRONMENT}` as Environment,
        merchantId: `${import.meta.env.VITE_MERCHANT_ID}`,
        userId: `${shortProfile?.partyId}`,
        sessionId: `${sessionKey}`,
        containerHeight: `${cashierRef.current?.clientHeight}px`,
        containerWidth: `${cashierRef.current?.clientWidth}px`,
        amount: `${redeemAmount}`,
      });

      new _PaymentIQCashier('#cashier',
        config,
        (api: IPiqCashierApiMethods) => {
          api.on({
            cashierInitLoad: () => onStart?.(`${redeemAmount}`),
            success: () => onSuccess?.({ amount: redeemAmount.toString() }),
            failure: () => onFail?.(),
            validationFailed: () => onFail?.(),
          });
        });
    }
    // eslint-disable-next-line
  }, [cashierRef]);

  return (
    <Stack
      sx={({ breakpoints, palette }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 488,
        minHeight: 700,
        backgroundColor: palette.common[925],
        [breakpoints.down(491)]: {
          maxHeight: '100%',
          borderRadius: 0,
          boxShadow: 'none',
        },
      })}>
      <Stack
        flexDirection='column'
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          padding: 3,
          [breakpoints.down(490)]: {
            padding: 2,
          },
        })}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            width: '100%',
          }}>
          <Typography
            level='h3'
            fontSize='1.25rem'>
            Checkout
          </Typography>
          {onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
        <Stack
          ref={cashierRef}
          direction='column'
          width='100%'
          height='100%'>
          <ConfirmCoinRedemptionIframe id='cashier' />
        </Stack>
      </Stack>
    </Stack>
  )
};

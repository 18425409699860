import compose from 'compose-function';
import {
  withLoaderRoot
} from '@shared/ui';
import {
  withJoyTheme,
  withMuiTheme
} from '@shared/theme';
import {
  withStore
} from './with-store';
import {
  withCheckLoggedIn
} from './with-check-logged-in';
import {
  withToast
} from './with-toast';
import {
  withGtm
} from './with-gtm';
import {
  withFullScreenListener
} from './with-full-screen-listener';

export const withProviders = compose(
  withJoyTheme,
  withMuiTheme,
  withStore,
  withGtm,
  withCheckLoggedIn,
  withLoaderRoot,
  withToast,
  withFullScreenListener,
);

import { CurrentVIPLevelEnum } from '@entities/session';

type MapNextLevel = (data: any) => Maybe<Exclude<CurrentVIPLevelEnum, CurrentVIPLevelEnum.Level1>>
export const mapNextLevel: MapNextLevel = (level: CurrentVIPLevelEnum) => {
  const VIPLevelMap: Record<string, Maybe<Exclude<CurrentVIPLevelEnum, CurrentVIPLevelEnum.Level1>>> = {
    [CurrentVIPLevelEnum.Level1]: CurrentVIPLevelEnum.Level2,
    [CurrentVIPLevelEnum.Level2]: CurrentVIPLevelEnum.Level3,
    [CurrentVIPLevelEnum.Level3]: CurrentVIPLevelEnum.Level4,
    [CurrentVIPLevelEnum.Level4]: CurrentVIPLevelEnum.Level5,
    [CurrentVIPLevelEnum.Level5]: CurrentVIPLevelEnum.Level6,
    [CurrentVIPLevelEnum.Level6]: CurrentVIPLevelEnum.Level7,
    [CurrentVIPLevelEnum.Level7]: CurrentVIPLevelEnum.Level8,
    [CurrentVIPLevelEnum.Level8]: null,
  };

  return VIPLevelMap[level];
}

import {
  lazy,
  memo,
  useState,
  Fragment,
  Suspense,
  type FC,
} from 'react'
import {
  Stack,
  Skeleton,
  IconButton,
  Typography
} from '@mui/joy';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  ContactUsLink,
  TermsOfUseLink
} from '@shared/ui';
import {
  PaymentMethod,
  CheckoutResult,
  CheckoutFailedForm,
  usePaymentsSelector,
  CheckoutSuccessForm,
  PaymentMethodSelector,
  PaymentUnavailableMessage,
  PaymentMethodOrderSetTypeEnum,
  selectRecentlyUsedPaymentMethod,
  selectWithdrawSinglePaymentMethod,
  useGetPaymentMethodOrderSetsQuery,
  selectDepositPaymentMethodOrderSets
} from '@entities/payments';
import {
  ConfirmCoinRedemptionWorldPayFrom
} from './confirm-coin-redemption-worldpay-form.component';
import {
  ConfirmCoinRedemptionPaymentIQForm
} from './confirm-coin-redemption-paymentiq-form.component';
import {
  ConfirmCoinRedemptionAptPayForm
} from './confirm-coin-redemption-aptpay-from.component';

const ArrowBackIcon = lazy(() => import('@mui/icons-material/ArrowBack'));

type WithdrawCoinsFormView =
  'selector' |
  'checkout' |
  'success' |
  'failed';

export type CoinRedemptionCheckoutFormProps = {
  redeemAmount: number;
  onClose?(): void;
  onStart?(amount: string | number): void;
  onFailed?(result: {
    redeemAmount: Maybe<number>;
    paymentMethod: Maybe<PaymentMethod>;
  }): void;
  onSuccess?(result: {
    redeemAmount: Maybe<number>;
    paymentMethod: Maybe<PaymentMethod>;
  }): void;
};

export const CoinRedemptionCheckoutForm: FC<CoinRedemptionCheckoutFormProps> = memo(({
  redeemAmount,
  onStart,
  onClose,
  onFailed,
  onSuccess
}) => {

  const { paymentMethods, recently, single } = usePaymentsSelector(state => ({
    paymentMethods: selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Withdrawal)(state),
    recently: selectRecentlyUsedPaymentMethod(state),
    single: selectWithdrawSinglePaymentMethod(state),
  }));

  const [view, setView] = useState<WithdrawCoinsFormView>(single ? 'checkout' : 'selector');
  const [paymentMethod, setPaymentMethod] = useState<Maybe<PaymentMethod>>(single || null);
  const [checkoutResult, setCheckoutResult] = useState<Maybe<CheckoutResult>>(null);

  const { isFetching, refetch } = useGetPaymentMethodOrderSetsQuery(undefined, { refetchOnMountOrArgChange: true });

  const handlePaymentMethodSelection = (method: PaymentMethod): void => {
    setPaymentMethod(method);
    setView('checkout');
  };

  const handlePaymentSuccess = (result?: CheckoutResult): void => {
    if (paymentMethod?.key !== PaymentMethodKeyEnum.PaymentIQ) {
      setCheckoutResult(result);
      setView('success');
    };
    onSuccess?.({ redeemAmount, paymentMethod });
  };

  const handlePaymentFailed = (result?: CheckoutResult): void => {
    setCheckoutResult(result);
    setView('failed');
    onFailed?.({ redeemAmount, paymentMethod });
  };

  const handlePaymentUnavailable = async (): Promise<void> => {
    setView('selector');
    setPaymentMethod(null);
    refetch();
  };

  return (
    <Fragment>
      {isFetching ? (
        <Skeleton
          variant="rectangular"
          height='600px'
          width='100%'
        />
      ) : (
        <Fragment>
          {view === 'selector' && !isFetching && (
            <PaymentMethodSelector
              recently={recently}
              recommended={paymentMethods?.recommended}
              alternative={paymentMethods?.alternative}
              onSelect={handlePaymentMethodSelection}
              titleSlot={(
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='start'
                  width='100%'
                  gap={2}>
                  <IconButton onClick={onClose}>
                    <Suspense>
                      <ArrowBackIcon />
                    </Suspense>
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}>
                    Payment Methods
                  </Typography>
                </Stack>
              )}
            />
          )}
          {view === 'checkout' && paymentMethod && (
            ({
              [PaymentMethodKeyEnum.WorldPayCNPCard]: (
                <ConfirmCoinRedemptionWorldPayFrom
                  redeemAmount={redeemAmount}
                  onClose={onClose}
                  onStart={onStart}
                  onFail={handlePaymentFailed}
                  onSuccess={handlePaymentSuccess}
                />
              ),
              [PaymentMethodKeyEnum.PaymentIQ]: (
                <ConfirmCoinRedemptionPaymentIQForm
                  redeemAmount={redeemAmount}
                  onClose={onClose}
                  onStart={onStart}
                  onFail={handlePaymentFailed}
                  onSuccess={handlePaymentSuccess}
                />
              ),
              [PaymentMethodKeyEnum.AptPay]: (
                <ConfirmCoinRedemptionAptPayForm
                  redeemAmount={redeemAmount}
                  onClose={onClose}
                  onStart={onStart}
                  onFail={handlePaymentFailed}
                  onSuccess={handlePaymentSuccess}
                />
              ),
              [PaymentMethodKeyEnum.Unipaas]: (
                <PaymentUnavailableMessage
                  paymentMethodName='Unipaas'
                  onBack={handlePaymentUnavailable}
                />
              ),
            })[paymentMethod.key]
          )}
          {view === 'success' && (
            <CheckoutSuccessForm
              cardNumberSlot='Account number'
              checkoutResult={checkoutResult}
              onClose={onClose}
              receiptFooterSlot={
                <Typography
                  sx={({ palette }) => ({
                    marginTop: 3,
                    fontSize: 16,
                    color: palette.common[100],
                    textAlign: 'center'
                  })}>
                  Your redemption request has been submitted <br />
                  successfully. It takes 1-2 business days to <br />
                  process your order.&nbsp;
                  <TermsOfUseLink sx={{ fontSize: 16 }} />
                  &nbsp;apply.&nbsp;
                  <ContactUsLink sx={{ fontSize: 16 }} >
                    For help, please contact our support team.
                  </ContactUsLink>
                </Typography>
              }
            />
          )}
          {view === 'failed' && (
            <CheckoutFailedForm
              cardNumberSlot='Account number'
              paymentMethod={paymentMethod}
              checkoutResult={checkoutResult}
              onClose={onClose}
              onTryAgain={onClose}
              onBackupPayment={handlePaymentMethodSelection}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
});
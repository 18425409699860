import {
  useSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  paymentsSlice
} from './slice';
import {
  type CheckoutResult,
  type PaymentsState,
  PaymentMethod,
  PaymentMethodOrderSet,
  PaymentMethodOrderSetTypeEnum
} from './types';

type RootState = {
  [paymentsSlice.name]: PaymentsState;
};

export const selectDepositPaymentMethodOrderSets = (type: PaymentMethodOrderSetTypeEnum) => (state: RootState): Maybe<PaymentMethodOrderSet> => (
  state[paymentsSlice.name].paymentMethodOrderSet?.find(item => item.type === type)
);

export const selectDepositAllowOnlyOnePaymentSet = createSelector([
  selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Deposit)],
  (paymentMethodOrderSet) => {
    if (paymentMethodOrderSet) {
      const { recommended, alternative } = paymentMethodOrderSet;
      const combinedPaymentMethods = [...recommended, ...alternative];
      return combinedPaymentMethods.length === 1;
    } else {
      return false;
    }
  });

export const selectDepositSinglePaymentMethod = createSelector([
  selectDepositAllowOnlyOnePaymentSet,
  selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Deposit)
], (allowOnlyOnePaymentSet, paymentMethodOrderSet) => {
  if (allowOnlyOnePaymentSet && paymentMethodOrderSet) {
    const { recommended, alternative } = paymentMethodOrderSet;
    const combinedPaymentMethods = [...recommended, ...alternative];
    return combinedPaymentMethods[0];
  } else {
    return null;
  }
});

export const selectWithdrawAllowOnlyOnePaymentSet = createSelector([
  selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Withdrawal)],
  (paymentMethodOrderSet) => {
    if (paymentMethodOrderSet) {
      const { recommended, alternative } = paymentMethodOrderSet;
      const combinedPaymentMethods = [...recommended, ...alternative];
      return combinedPaymentMethods.length === 1;
    } else {
      return false;
    }
  });

export const selectWithdrawSinglePaymentMethod = createSelector([
  selectWithdrawAllowOnlyOnePaymentSet,
  selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Withdrawal)
], (allowOnlyOnePaymentSet, paymentMethodOrderSet) => {
  if (allowOnlyOnePaymentSet && paymentMethodOrderSet) {
    const { recommended, alternative } = paymentMethodOrderSet;
    const combinedPaymentMethods = [...recommended, ...alternative];
    return combinedPaymentMethods[0];
  } else {
    return null;
  }
});

export const selectPaymentMethodOrderSets = (state: RootState): Maybe<PaymentMethodOrderSet> => (
  state[paymentsSlice.name].paymentMethodOrderSet
    ?.find(item => item.type === state[paymentsSlice.name].type)
);

export const selectRecentlyUsedPaymentMethod = (state: RootState): Maybe<PaymentMethod> => (
  state[paymentsSlice.name].recentlyUsedPaymentMethod
);

export const usePaymentsSelector: TypedUseSelectorHook<RootState> = useSelector;
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux';
import {
  UserStateEnum,
  type CarouselSliderItem,
} from '@shared/types';
import {
  selectUserStatus
} from '@entities/session';
import {
  type GameCategory
} from '@entities/games';
import {
  landingPageSlice,
  type ActionItem,
  type LandingPageState,
  type LandingPageStepPlayItem,
  type LandingPageTitlePlayInThreeSteps,
} from './slice';

type RootState = {
  [landingPageSlice.name]: LandingPageState;
};

export const selectLandingPageMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[landingPageSlice.name]?.keywords
);

export const selectLandingPageMetaTagTitle = (state: RootState): Maybe<string> => (
  state[landingPageSlice.name]?.title
);

export const selectLandingPageMetaTagDescription = (state: RootState): Maybe<string> => (
  state[landingPageSlice.name]?.description
);

export const selectLandingPageMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[landingPageSlice.name]?.canonical
);

export const selectLandingPageTitlePlayInThreeSteps = (state: RootState): Maybe<LandingPageTitlePlayInThreeSteps> => (
  state[landingPageSlice.name]?.playInThreeSteps?.title
);

export const selectLandingPageStepsPlayInThreeSteps = (state: RootState): Maybe<Array<LandingPageStepPlayItem>> => (
  state[landingPageSlice.name]?.playInThreeSteps?.steps
);

export const selectSliders = createSelector(
  selectUserStatus,
  (state: RootState): Array<CarouselSliderItem> => state[landingPageSlice.name]?.carouselSliderTop ?? [],
  (state: RootState): Array<CarouselSliderItem> => state[landingPageSlice.name]?.carouselSliderMiddle ?? [],
  (state: RootState): Array<CarouselSliderItem> => state[landingPageSlice.name]?.carouselSliderBottom ?? [],
  (userState, carouselSliderTop, carouselSliderMiddle, carouselSliderBottom) => {
    const processedTop = carouselSliderTop.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });

    const processedMiddle = carouselSliderMiddle.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });

    const processedBottom = carouselSliderBottom.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });

    return {
      carouselSliderTop: processedTop,
      carouselSliderMiddle: processedMiddle,
      carouselSliderBottom: processedBottom,
    };
  }
);

export const selectLandingPageGamesByCategoriesBlockTop = (state: RootState): Maybe<Array<GameCategory>> => (
  state[landingPageSlice.name]?.gamesByCategoriesBlockTop
);

export const selectLandingPageGamesByCategoriesBlockBottom = (state: RootState): Maybe<Array<GameCategory>> => (
  state[landingPageSlice.name]?.gamesByCategoriesBlockBottom
);

export const selectAction = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<ActionItem>> => state[landingPageSlice.name]?.action,
  (userStatus: Maybe<UserStateEnum>, action: Maybe<Array<ActionItem>>) => {
    const currentAction = action?.find(actionItem => actionItem.userState === userStatus);
    return currentAction;
  },
  { devModeChecks: { identityFunctionCheck: 'never' } }
);

export const useLandingPageSelector: TypedUseSelectorHook<RootState> = useSelector;

import {
  mapper
} from '@shared/lib';
import {
  type PublicConsent
} from './index';

export enum ConsentStatusEnum {
  Consented = 'CONSENTED',
  NotConsented = 'NOT_CONSENTED',
};

export type UserConsent = PublicConsent & {
  consentStatus: ConsentStatusEnum;
};

type MapUserConsent = (data: any) => UserConsent;
export const mapUserConsent: MapUserConsent = mapper([
  { name: 'consentStatus' },
  { name: 'id', from: 'consent.id' },
  { name: 'key', from: 'consent.key' },
  { name: 'name', from: 'consent.name' },
  { name: 'version', from: 'consent.version' },
  { name: 'isMandatory', from: 'consent.isMandatory' },
  { name: 'consentVersionId', from: 'consent.consentVersionId' },
]);

import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  type CoinPackage
} from '@entities/coins';

export type BuyCoinsState = {
  chosenCoinPackage: Maybe<CoinPackage>;
};

const initialState: BuyCoinsState = {
  chosenCoinPackage: null
};

export const buyCoinsSlice = createSlice({
  name: 'buy-coins',
  initialState,
  reducers: {
    setChosenCoinPackage: (state: BuyCoinsState, action: PayloadAction<CoinPackage>) => {
      state.chosenCoinPackage = action.payload;
    },
    resetChosenCoinPackage: (state: BuyCoinsState) => {
      state.chosenCoinPackage = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, (state: BuyCoinsState) => {
      state.chosenCoinPackage = null;
    });
  },
});

export const {
  setChosenCoinPackage,
  resetChosenCoinPackage
} = buyCoinsSlice.actions;
export const buyCoinsReducer = buyCoinsSlice.reducer;
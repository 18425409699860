import {
  useState,
  useEffect,
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  useLocation
} from 'react-router-dom';
import {
  ScreenLoaderOverlay
} from '@shared/ui';
import {
  useLazyGetPlayerDetailsQuery,
} from '../api';

type WithPullingPlayerInfoProps = PropsWithChildren & {
  addScreenLoaderOverlay?: boolean
};

export const WithPullingPlayerInfo: FC<WithPullingPlayerInfoProps> = ({ children, addScreenLoaderOverlay }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getPlayerDetails] = useLazyGetPlayerDetailsQuery();

  useEffect(() => {
    setIsLoading(true);
    getPlayerDetails()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
    // TODO: why need location.pathname?
  }, [getPlayerDetails, location.pathname]);

  return isLoading
    ? (addScreenLoaderOverlay ? <ScreenLoaderOverlay /> : null)
    : children
};
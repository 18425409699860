import { FC, memo, useState } from 'react';
import { SystemStyleObject } from '@mui/system';
import { Box, LinearProgress, Stack, Typography } from '@mui/joy';
import { useResizeObserver } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import { ChallengeStatusEnum } from '../model';
import { ChallengeCoinsAmount } from './challenge-coins-amount.component';
import { ChallengeTimer } from './challenge-timer.component';

export type ChallengeListItemProps = {
  title: string;
  subTitle: string;
  status: ChallengeStatusEnum;
  endDate: string;
  percentage: number;
  badgeUrl: Maybe<Url>;
  amount: number;
  currency: CurrencyCodesEnum;
  sx?: SystemStyleObject;
};

export const ChallengeListItem: FC<ChallengeListItemProps> = memo(
  ({ title, subTitle, status, endDate, percentage, badgeUrl, amount, currency, sx }) => {
    const isActive = status === ChallengeStatusEnum.Active;
    const isComplete = status === ChallengeStatusEnum.Completed;
    const isExpired = status === ChallengeStatusEnum.Claimable;

    const containerRef = useResizeObserver();
    const [badgeLoaded, setBadgeLoaded] = useState<boolean>(false);

    const handleImageLoaded = (loaded: boolean) => (): void => {
      setBadgeLoaded(loaded);
    };

    return (
      <Stack
        alignItems='center'
        justifyContent='space-between'
        sx={({ palette }) => ({
          background: palette.common[475],
          borderRadius: 8,
          padding: 1.5,
          paddingBottom: 1.625,
          height: 172,
          boxSizing: 'border-box',
          ...(isComplete && {
            height: 171,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.common[825],
          }),
          ...sx,
        })}
      >
        <Box
          ref={containerRef.ref}
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '48px auto',
            gap: 1,
          }}
        >
          <Stack
            sx={{
              height: 48,
              width: 48,
              borderRadius: 8,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box
              component='img'
              src='/assets/webp/default-challenger-badge.webp'
              loading='lazy'
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                ...(badgeLoaded && {
                  display: 'none',
                }),
              }}
            />
            {badgeUrl && (
              <Box
                component='img'
                loading='lazy'
                src={badgeUrl ?? ''}
                onLoad={handleImageLoaded(true)}
                onError={handleImageLoaded(false)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  height: 48,
                  width: 48,
                  visibility: badgeLoaded ? 'visible' : 'hidden',
                  transition: 'visibility 0.5s ease-in',
                }}
              />
            )}
          </Stack>
          <Stack
            sx={{
              width: `${containerRef.width - (48 + 8)}px`,
            }}
          >
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                width: `${containerRef.width - 97}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              })}
            >
              {title}
            </Typography>
            <Typography
              sx={({ palette }) => ({
                color: palette.common[150],
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                width: `${containerRef.width - 87}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              })}
            >
              {subTitle}
            </Typography>
          </Stack>
        </Box>
        <Stack alignItems='center' justifyContent='center'>
          {isActive && <ChallengeTimer {...{ endDate }} />}
          {isComplete && (
            <Typography
              sx={({ palette }) => ({
                color: palette.common[825],
                textAlign: 'center',
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              })}
            >
              Done
            </Typography>
          )}
          {isExpired && (
            <Typography
              sx={({ palette }) => ({
                color: palette.common[200],
                textAlign: 'center',
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              })}
            >
              Expired
            </Typography>
          )}
        </Stack>
        <Stack alignItems='center' justifyContent='center' height={20} width='100%'>
          <ChallengeCoinsAmount value={amount} currency={currency} />
        </Stack>
        <Stack width='100%'>
          <LinearProgress
            determinate
            size='lg'
            value={percentage}
            sx={({ palette }) => ({
              width: '100%',
              '&:before': {
                ...(isComplete && {
                  background: palette.common[825],
                }),
                ...(isExpired && {
                  background: palette.common[200],
                }),
              },
            })}
          />
        </Stack>
      </Stack>
    );
  }
);

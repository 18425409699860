import {
  useMemo,
  useEffect,
  type FC,
  type BaseSyntheticEvent,
} from 'react';
import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  yupResolver
} from '@hookform/resolvers/yup';
import {
  Stack,
  Typography
} from '@mui/joy';
import {
  Button,
  TextInput,
  CloseIcon,
  IconButton,
} from '@shared/ui';
import {
  isEmpty
} from '@shared/lib';
import {
  coinRedemptionSchema
} from '../lib'
import {
  RedeemableBalance
} from './redeemable-balance.component';
import {
  RedeemCoinsFreePlayDisclaimer
} from './redeem-coins-free-play-disclaimer.component';
import {
  RedeemProcessingTerms
} from './redeem-processing-terms.component';

export type CoinRedemptionFormProps = {
  withdrawableBalance: number;
  bonusBalance: number;
  onClose?(): void;
  onCoinRedeem?(amount: number): void;
};

export const CoinRedemptionForm: FC<CoinRedemptionFormProps> = ({
  withdrawableBalance,
  bonusBalance,
  onClose,
  onCoinRedeem,
}) => {
  const {
    control,
    formState,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(coinRedemptionSchema(withdrawableBalance)),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { amount: 0 },
  });
  const amount = watch('amount');

  useEffect(() => {
    reset({ amount: 0 });
  }, [reset]);

  const usd = useMemo(() => {
    const numericAmount = Number(amount);
    return isEmpty(numericAmount) || numericAmount < 0
      ? 0
      : numericAmount;
  }, [amount]);

  const handleCoinRedeem = async (
    formData: { amount: number },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    onCoinRedeem?.(formData.amount);
  };

  const handleClose = (): void => {
    onClose?.();
  };

  return (
    <Stack
      sx={({ palette }) => ({
        width: '100%',
        height: '100%',
        paddingBlock: 3,
        paddingInline: 3,
        backgroundColor: palette.common[475],
      })}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          gap='0.5rem'
          alignItems='center'>
          <Typography
            sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: '1.125rem',
              fontWeight: 600,
              lineHeight: 'normal',
            })}>
            Redeem
          </Typography>
        </Stack>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack
        direction='column'
        component='form'
        onSubmit={handleSubmit(handleCoinRedeem)}>
        <RedeemableBalance
          withdrawableBalance={withdrawableBalance}
          bonusBalance={bonusBalance}
        />
        <Stack
          direction='column'
          gap={.5}
          sx={{
            gap: .5,
            mt: 2,
            mb: 2,
          }}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
            sx={({ palette }) => ({
              '& .MuiFormControl-root': {
                minHeight: '100%'
              },
              '& .MuiFormLabel-root': {
                color: palette.common.white
              },
              '& .MuiInput-root.Mui-disabled': {
                backgroundColor: palette.common[475],
                color: palette.common.white,
              },
              '& .MuiInput-input': {
                width: '100%'
              },
              '& .MuiFormHelperText-root': {
                display: 'none'
              },
              '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                '-webkitAppearance': 'none',
                margin: 0,
              },
              '& input[type="number"]': {
                mozAppearance: 'textfield'
              }
            })}>
            <Controller
              name='amount'
              control={control}
              render={({ field: { value, onBlur, onChange }, fieldState }) => (
                <TextInput
                  label='Super Coins'
                  value={value}
                  type='number'
                  onBlur={onBlur}
                  onChange={onChange}
                  error={fieldState.error?.message}
                  sx={({ palette }) => ({
                    '.MuiInput-root': {
                      backgroundColor: palette.common[900],
                    }
                  })}
                />
              )}
            />
            <TextInput
              disabled
              label='USD'
              value={usd}
            />
          </Stack>
          {!isEmpty(formState.errors.amount) && (
            <Typography
              sx={({ palette }) => ({
                color: palette.common.error,
                fontSize: 14,
                lineHeight: '21px',
              })}>
              {formState.errors.amount?.message}
            </Typography>
          )}
        </Stack>
        <Button
          fullWidth
          color='primary'
          variant='solid'
          type='submit'
          disabled={!formState.isDirty || !formState.isValid}
          sx={{ mb: 2 }}>
          Confirm
        </Button>
        <RedeemProcessingTerms sx={{ marginBottom: 2.5 }} />
        <RedeemCoinsFreePlayDisclaimer
          hasOfficialSweepstakesRulesLink
          sx={{ textAlign: 'center' }}
        />
      </Stack>
    </Stack>
  );
};
import {
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react';
import {
  ToastSnackbar
} from '../ui';
import {
  ToastContext,
  type Toast,
  type ToastColor,
  type ToastOptions,
} from './context';

// TODO: refactor me
export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<Toast>>([])

  const handleOpen = (type: ToastColor) => (data: ToastOptions): void => {
    setToasts(state =>
      [...state, {
        id: new Date().getTime(),
        type,
        message: data.message,
        autoHideDuration: data.autoHideDuration || 3000
      }]
    )
  }

  const handleClose = (): void => {
    const newToasts = toasts.slice(1);
    setToasts(newToasts);
  }

  const activeToast = useMemo(() => toasts[0], [toasts])

  return (
    <ToastContext.Provider
      value={{
        error: handleOpen('danger'),
        success: handleOpen('success'),
      }}>
      {children}
      {activeToast && (
        <ToastSnackbar
          key={activeToast.id}
          open={!!activeToast}
          onClose={handleClose}
          message={activeToast.message}
          autoHideDuration={activeToast.autoHideDuration}
          color={activeToast.type}
        />
      )}
    </ToastContext.Provider>
  );
};


import {
  lazy,
  useId,
  useRef,
  useState,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  Suspense
} from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Option,
  OptionProps,
  FormHelperText
} from '@mui/joy';

const KeyboardArrowDownIcon = lazy(() => import('@mui/icons-material/KeyboardArrowDown'));

export type OptionType = {
  value: string;
  label: string;
};

export type DropdownProps = Maybe<{
  label?: Maybe<string>;
  options?: Array<OptionType>;
  value?: Maybe<string>;
  defaultValue?: Maybe<string>;
  placeholder?: Maybe<string>;
  error?: Maybe<string>;
  disabled?: Maybe<boolean>;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  inputProps?: Maybe<{
    root?: Maybe<FormControlProps>;
    label?: Maybe<FormLabelProps>;
    select?: Maybe<SelectProps<OptionType, false>>;
    option?: Maybe<OptionProps>;
  }>
}>;

const DropdownComponent: ForwardRefRenderFunction<HTMLButtonElement, DropdownProps> = (props, ref) => {
  const selectId = useId();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const formControlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formControlRef.current && !formControlRef.current.contains(event.target as Node) && isFocused) {
        setIsFocused(false);
        props?.onBlur?.();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isFocused, props, props?.onBlur]);

  const handleChange = (_: unknown, newValue: Maybe<string>): void => {
    props?.onChange?.(newValue ?? '');
  };

  return (
    <FormControl
      ref={formControlRef}
      onFocus={() => setIsFocused(true)}
      disabled={props?.disabled ?? false}
      error={!!props?.error}
      sx={[{
        minHeight: 90,
      },
      ...(props?.inputProps?.root
        && Array.isArray(props?.inputProps?.root?.sx)
        ? props?.inputProps?.root?.sx
        : [props?.inputProps?.root?.sx])
      ]}
      {...props?.inputProps?.root}>
      {props?.label && (
        <FormLabel
          htmlFor={selectId}
          sx={[
            { marginBottom: 0.5 },
            ...(props?.inputProps?.label
              && Array.isArray(props?.inputProps?.label?.sx)
              ? props?.inputProps?.label?.sx
              : [props?.inputProps?.label?.sx])
          ]}
          {...props?.inputProps?.label}>
          {props?.label}
        </FormLabel>
      )}
      <Select
        id={selectId}
        startDecorator={props?.inputProps?.select?.startDecorator}
        value={props?.value}
        placeholder={props?.placeholder}
        defaultValue={props?.defaultValue}
        disabled={props?.disabled ?? false}
        onChange={handleChange}
        indicator={(
          <Suspense>
            <KeyboardArrowDownIcon />
          </Suspense>
        )}
        slotProps={{
          root: {
            sx: [({ palette }) => ({
              height: 40,
              color: palette.common.white,
              backgroundColor: palette.common[475],
              borderColor: `${palette.common[925]} !important`,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 8,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: palette.common[475],
              },
              ...(props?.error && ({
                borderColor: palette.common.error
              }))
            }),
            ...(props?.inputProps?.select
              && Array.isArray(props?.inputProps?.select?.sx)
              ? props?.inputProps?.select?.sx
              : [props?.inputProps?.select?.sx])
            ]
          },
          button: {
            ref,
            'aria-labelledby': `${selectId}-label ${selectId}`,
          },
          listbox: {
            sx: ({ palette }) => ({
              zIndex: 99999,
              borderRadius: 12,
              border: 'none',
              background: palette.background.body,
              boxShadow: `0px 0px 16px 0px ${palette.common[300]}`,
              borderColor: palette.common[475],
              borderWidth: 1,
              width: '100%'
            })
          },
          indicator: {
            sx: {
              [`&.Mui-expanded`]: {
                transform: 'rotate(-180deg)',
              },
              '& .MuiSvgIcon-root': {
                height: 24,
                width: 24
              }
            },
          }
        }}>
        {props?.options?.map(
          ({ label, value }, index) => (
            <Option
              key={`${label}-${index}`}
              value={value || ''}
              sx={[({ palette }) => ({
                '&.MuiOption-highlighted': {
                  backgroundColor: palette.common[475],
                },
                '&.MuiOption-root:hover': {
                  backgroundColor: palette.common[575],
                },
                '&.MuiOption-root:active': {
                  backgroundColor: palette.common[475],
                },
              }),
              ...(props?.inputProps?.option
                && Array.isArray(props?.inputProps?.option?.sx)
                ? props?.inputProps?.option?.sx
                : [props?.inputProps?.option?.sx])
              ]}>
              {label}
            </Option>
          ))}
      </Select>
      {
        props?.error && (
          <FormHelperText sx={{ marginTop: 0.5 }}>
            {props?.error}
          </FormHelperText>
        )
      }
    </FormControl >
  );
};

export const Dropdown = forwardRef<HTMLButtonElement, DropdownProps>(DropdownComponent);
import {
  FC,
  Fragment
} from 'react';
import {
  StackProps,
  Typography
} from '@mui/joy';
import {
  Link
} from '@shared/ui';

const OfficialSweepstakesRulesLink: FC = () => (
  <Link
    color='primary'
    href='/official-promotions-rules'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    }}>
    Official Promotions Rules
  </Link>
);

const TermsConditionsLink: FC = () => (
  <Link
    color='primary'
    href='/terms-and-conditions'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }}>
    Terms & Conditions
  </Link>
);

export type FreePlayDisclaimerProps = StackProps & {
  hasOfficialSweepstakesRulesLink?: boolean;
};

export const RedeemCoinsFreePlayDisclaimer: FC<FreePlayDisclaimerProps> = ({ sx, hasOfficialSweepstakesRulesLink }) => (
  <Typography
    sx={[({ palette }) => ({
      color: palette.common[150],
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }),
    ...(Array.isArray(sx) ? sx : [sx])
    ]}>
    It’s always Free to play our Promotional Coins games. No purchase necessary!&nbsp;
    {hasOfficialSweepstakesRulesLink && (
      <Fragment>
        <OfficialSweepstakesRulesLink />&nbsp;and&nbsp;
      </Fragment>
    )}
    <TermsConditionsLink />
    &nbsp;apply
  </Typography>
);
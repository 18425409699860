import {
  useMemo,
  useCallback,
} from 'react';
import JSConfetti from 'js-confetti';

class JSConfettiSingleton {
  private static instance: JSConfetti | null = null;
  private constructor() { }
  static getInstance(): JSConfetti {
    if (!JSConfettiSingleton.instance) {
      JSConfettiSingleton.instance = new JSConfetti();
    }
    return JSConfettiSingleton.instance;
  }
};

export type ConfettiConfig = {
  confettiRadius?: number;
  confettiNumber?: number;
  confettiColors?: string[];
  emojis?: string[];
  emojiSize?: number;
};

export type UseConfetti = {
  fire(config?: ConfettiConfig): Promise<void>;
  fireCoins(): Promise<void>;
};

export const useConfetti = (defaultConfig?: ConfettiConfig): UseConfetti => {
  const jsConfetti = useMemo(() => JSConfettiSingleton.getInstance(), []);
  const fire = useCallback(
    async (config?: ConfettiConfig): Promise<void> => {
      await jsConfetti.addConfetti({ ...defaultConfig, ...config });
    },
    [jsConfetti, defaultConfig]
  );

  const fireCoins = useCallback(async (): Promise<void> => {
    await fire({ emojis: ['🟡'], emojiSize: defaultConfig?.emojiSize ?? 50 });
  }, [fire, defaultConfig?.emojiSize]);

  return { fire, fireCoins };
};


import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';

export type RedeemCoinsState = {
  coinRedeem: string | number;
};

const initialState: RedeemCoinsState = {
  coinRedeem: 0
};

export const redeemCoinsSlice = createSlice({
  name: 'redeem-coins',
  initialState,
  reducers: {
    storeCoinRedeem: (state: RedeemCoinsState, action: PayloadAction<string | number>) => {
      state.coinRedeem = action.payload;
    },
    resetCoinRedeem: (state: RedeemCoinsState) => {
      state.coinRedeem = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState);
  },
});

export const {
  storeCoinRedeem,
  resetCoinRedeem,
} = redeemCoinsSlice.actions;

export const redeemCoinsReducer = redeemCoinsSlice.reducer;
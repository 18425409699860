import {
  baseApi,
  baseOmegaApi,
  SESSION_TAG,
  type OmegaApiResponse,
} from '@shared/api';
import {
  mapFullProfile,
  mapToPlayerDetails,
  type PlayerDetails
} from '../lib';
import {
  type FullProfile
} from '../model';

export type UpdateEmailSubscription = {
  subscribe: boolean;
};

export type UpdatePlayerInfo = {
  email: Maybe<string>;
  mobilePhone: Maybe<string>;
};

export type GepPlayerDetailsResponse = Pick<OmegaApiResponse, 'status'> & PlayerDetails;
export type GetPlayerInfoResponse = Pick<OmegaApiResponse, 'status'> & FullProfile;

export type UserDocumentsResponse = Pick<OmegaApiResponse, 'status'> & {
  userDocuments: Maybe<Array<unknown>>;
};

export const playerApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    balance: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/getBalance' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    getPlayerInfo: build.query<GetPlayerInfoResponse, void>({
      query: () => ({ url: 'ips/getPlayerInfo' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
      transformResponse: (response: unknown) => {
        const mappedProfile = mapFullProfile(response);
        const omegaResponse = response as OmegaApiResponse;
        return { ...mappedProfile, status: omegaResponse.status };
      },
    }),
    updatePlayerInfo: build.query<OmegaApiResponse, Partial<UpdatePlayerInfo>>({
      query: (params) => ({ url: 'ips/updatePlayerInfo', params }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: false },
    }),
    getLoyaltyPoints: build.query<OmegaApiResponse, void>({
      query: () => ({ url: '/ips/loyaltyPointsStatus' }),
      extraOptions: { auth: true, showLoader: false },
    }),
    getUserDocuments: build.query<UserDocumentsResponse, void>({
      query: () => ({ url: '/ips/getUserDocuments' }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});


export const playerDetailsApiSlicer = baseApi.injectEndpoints({
  endpoints: build => ({
    getPlayerDetails: build.query<GepPlayerDetailsResponse, void>({
      query: () => ({ url: '/api/ips/get-player-details' }),
      extraOptions: { auth: true, showLoader: true },
      transformResponse: (response: { loyaltyPoints: unknown; playerInfo: unknown }) => {
        const result = mapToPlayerDetails(response);
        return result as GepPlayerDetailsResponse;
      }
    })
  }),
})

export const {
  useGetPlayerDetailsQuery,
  useLazyGetPlayerDetailsQuery,
} = playerDetailsApiSlicer;

export const {
  useLazyBalanceQuery,
  useLazyGetPlayerInfoQuery,
  useBalanceQuery,
  useGetPlayerInfoQuery,
  useLazyUpdatePlayerInfoQuery,
  useGetLoyaltyPointsQuery,
  useLazyGetLoyaltyPointsQuery,
  useLazyGetUserDocumentsQuery,
  useGetUserDocumentsQuery,
} = playerApiSlicer;





import {
  baseOmegaApi,
  type OmegaApiResponse
} from '@shared/api';
import {
  toQueryString
} from '@shared/lib';
import {
  mapUserConsent,
  mapPublicConsent,
  type UserConsent,
  type PublicConsent,
} from '../lib';

export type ConsentsToSaveParams = {
  sessionKey: string;
  consentedVersions?: Array<number>;
  unConsentedVersions?: Array<number>;
};

type GetUserConsentsParams = {
  sessionKey: string;
};

type GetUserConsentsResponse = OmegaApiResponse & {
  userConsents: Array<unknown>;
};

type GetPublicConsentsResponse = OmegaApiResponse & {
  consents: Array<unknown>;
};

export const consentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    makeSaveConsents: build.mutation<OmegaApiResponse, ConsentsToSaveParams>({
      query: (params: ConsentsToSaveParams) => ({
        url: `/ips/user/consents/save${toQueryString(params, { allowDuplicates: true })}`, method: 'POST'
      }),
      extraOptions: { auth: false, showLoader: false },
      transformResponse: (response: OmegaApiResponse) => {
        return response;
      },
    }),
    getUserConsents: build.query<Array<UserConsent>, { sessionKey: string }>({
      query: (params: GetUserConsentsParams) => ({ url: '/ips/user/consents', params }),
      extraOptions: { auth: false, showLoader: false },
      transformResponse: (response: GetUserConsentsResponse) => {
        const userConsents = response.userConsents.map(mapUserConsent);
        return userConsents;
      },
    }),
    getPublicConsents: build.query<Array<PublicConsent>, void>({
      query: () => ({ url: '/ips/consents' }),
      extraOptions: { auth: false, showLoader: false },
      transformResponse: (response: GetPublicConsentsResponse) => {
        const consents = response.consents.map(mapPublicConsent);
        return consents;
      }
    }),

  }),
});

export const {
  useGetUserConsentsQuery,
  useGetPublicConsentsQuery,
  useMakeSaveConsentsMutation,
  useLazyGetUserConsentsQuery,
  useLazyGetPublicConsentsQuery,
} = consentsApiSlice;

import { FC } from 'react';

import { styled } from '@mui/joy';

export interface LogoProps {
  type: 'mobile' | 'desktop' | 'menu' | 'menu-mobile';
}

export const DesktopLogo = styled('img')(({ theme }) => ({
  width: '13.125rem',
}));

export const MobileLogo = styled('img')(({ theme }) => ({
  width: '3.75rem',
}));

export const NavLogo = styled('img')(({ theme }) => ({
  width: '7.375rem',
}));

export const NavLogoMobile = styled('img')(({ theme }) => ({
  width: '2.5rem',
}));

export const Logo: FC<LogoProps> = ({ type = 'desktop' }) => {
  return (
    <>
      {type === 'desktop' && <DesktopLogo src='/assets/webp/logo_horizontal.webp' alt='logo' />}
      {type === 'mobile' && <MobileLogo src='/assets/webp/logo_vertical.webp' alt='logo' />}
      {type === 'menu' && <NavLogo src='/assets/webp/logo_vertical.webp' alt='logo' />}
      {type === 'menu-mobile' && <NavLogoMobile src='/assets/webp/logo_min.webp' alt='logo' />}
    </>
  );
};

import {
  baseApi,
} from '@shared/api';
import {
  mapLandingPageState
} from '../lib';
import {
  type LandingPageState
} from '../model';

export const landingPageApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getLandingPage: build.query<LandingPageState, void>({
      query: () => ({ url: '/api/landing-page' }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => mapLandingPageState(response),
    })
  }),
})

export const { useGetLandingPageQuery } = landingPageApiSlice;

import {
  useRef,
  useState,
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react';
import {
  ConsentKeyEnum,
} from '../lib';
import {
  PublicConsentsConfirmationModal
} from './public-consents-confirmation-modal.component';

export type PublicConsentsConfirmationContext = {
  confirm?(): Promise<Maybe<Array<number>>>;
};

export const PublicConsentsConfirmationContext = createContext<Maybe<PublicConsentsConfirmationContext>>(null);

export const usePublicConsentsConfirmation = (): PublicConsentsConfirmationContext => {
  const context = useContext(PublicConsentsConfirmationContext);
  if (!context) throw new Error('usePublicConsentsConfirmation must be used within a PublicConsentsConfirmationProvider');
  return context;
}

export const WithPublicConsentsConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const confirmationResolverRef = useRef<Maybe<(value: Maybe<Array<number>> | PromiseLike<Maybe<Array<number>>>) => void>>(null);

  const handleConfirm = (): Promise<Maybe<Array<number>>> => {
    return new Promise<Maybe<Array<number>>>(resolve => {
      confirmationResolverRef.current = resolve;
      setOpen(true);
    });
  };

  const handleConfirmed = (consentVersionIds: Array<number>): void => {
    setOpen(false);
    confirmationResolverRef.current?.(consentVersionIds);
  };

  const handleClose = (): void => {
    setOpen(false);
    confirmationResolverRef.current?.(null);
  };

  return (
    <PublicConsentsConfirmationContext.Provider value={{ confirm: handleConfirm }}>
      {children}
      <PublicConsentsConfirmationModal
        open={open}
        onClose={handleClose}
        onConsentIdsConfirmed={handleConfirmed}
        allowed={[ConsentKeyEnum.AgeAndGeo, ConsentKeyEnum.TermsAndConditions]}
      />
    </PublicConsentsConfirmationContext.Provider>
  );
};
import {
  lazy,
  memo,
  Suspense,
  type FC,
  type SyntheticEvent,
} from 'react';
import Snackbar, {
  SnackbarProps,
  SnackbarCloseReason
} from '@mui/joy/Snackbar';
import {
  IconButton
} from '@shared/ui';
import {
  type ToastColor
} from '../lib';
import {
  ToastIcon
} from './toast-icon.component';

const CloseIcon = lazy(() => import('@mui/icons-material/Close'));

export type ToastSnackbarProps = SnackbarProps & {
  message: string;
  onClose: () => void;
  color: ToastColor;
};

export const ToastSnackbar: FC<ToastSnackbarProps> = memo(({ color, message, onClose, ...props }) => {
  const handleClose = (_: Event | SyntheticEvent<any, Event> | null, reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };
  return (
    <Snackbar
      color={color}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      startDecorator={<ToastIcon type={color} />}
      endDecorator={<IconButton variant='plain' onClick={onClose} sx={{
        ml: 'auto',
        color: 'inherit',
        '--Icon-color': 'unset',
        "&:hover": {
          backgroundColor: 'transparent',
          color: 'inherit',
        }
      }}>
        <Suspense>
          <CloseIcon />
        </Suspense>
      </IconButton>}
      {...props}>
      {message}
    </Snackbar>
  );
});

import {
  AnyAction,
  Middleware,
  MiddlewareAPI,
  ThunkDispatch
} from '@reduxjs/toolkit';
import {
  gamesSlice,
  setGamesPageCategoryIds,
  type GamesState
} from '@entities/games';
import {
  gamesPageApiSlice
} from '../api';

type RootState = { [gamesSlice.name]: GamesState }
type AppDispatch = ThunkDispatch<RootState, object, AnyAction>

export const applyGameCategoryIdsMiddleware: Middleware<unknown, RootState> = (store: MiddlewareAPI<AppDispatch, RootState>) => next => action => {
  const result = next(action)

  if (gamesPageApiSlice.endpoints.getGamesPage.matchFulfilled(action)) {
    store.dispatch(setGamesPageCategoryIds(action.payload.categoryIds))
  }

  return result
}

import {
  FC
} from 'react';
import {
  Box,
  Typography
} from '@mui/joy';
import {
  Button,
  ButtonProps
} from '@shared/ui';

export type PaymentMethodButtonProps = ButtonProps & {
  hint: string;
  iconSrc: Url;
  onClick?(): void;
};

export const PaymentMethodButton: FC<PaymentMethodButtonProps> = ({
  hint,
  iconSrc,
  onClick,
  ...props
}) => (
  <Button
    color='neutral'
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: '8px',
      maxWidth: 212,
      width: '100%',
      height: 156,
      paddingBlock: '24px',
      paddingInline: '16px',
    }}
    onClick={onClick}
    {...props}>
    <Box
      component='img'
      loading='lazy'
      src={iconSrc}
      sx={({ breakpoints }) => ({
        maxHeight: 80,
        maxWidth: 180,
        minWidth: 114,
        objectFit: 'contain',
        [breakpoints.down(441)]: {
          maxWidth: 140,
        }
      })}
    />
    <Typography
      sx={{
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
      }}>
      {hint}
    </Typography>
  </Button>
);
import {
  mapper
} from '@shared/lib';

export enum ConsentKeyEnum {
  AgeAndGeo = 'age-and-geo',
  SmsSubscribed = 'sms-subscribed',
  TermsAndConditions = 'terms-and-conditions',
};

export type PublicConsent = {
  id: number;
  name: string;
  version: number;
  key: ConsentKeyEnum;
  isMandatory: boolean;
  consentVersionId: number;
};

type MapPublicConsent = (data: any) => PublicConsent;
export const mapPublicConsent: MapPublicConsent = mapper([
  { name: 'id', },
  { name: 'key' },
  { name: 'name' },
  { name: 'version' },
  { name: 'isMandatory' },
  { name: 'consentVersionId' },
]);

export const BANK_LIST: string[] = [
  "JPMorgan Chase",
  "Bank of America",
  "Citigroup",
  "Wells Fargo",
  "Goldman Sachs",
  "Morgan Stanley",
  "U.S. Bancorp",
  "PNC Financial Services",
  "TD Bank, N.A.",
  "Truist Financial",
  "Capital One",
  "Charles Schwab Corporation",
  "The Bank of New York Mellon",
  "State Street Corporation",
  "BMO USA",
  "American Express",
  "HSBC Bank USA",
  "USAA",
  "First Citizens BancShares",
  "Citizens Financial Group",
  "Fifth Third Bank",
  "M&T Bank",
  "UBS",
  "Huntington Bancshares",
  "Ally Financial",
  "Barclays",
  "KeyCorp",
  "Ameriprise",
  "Santander Bank",
  "RBC Bank",
  "Regions Financial Corporation",
  "Northern Trust",
  "Discover Financial",
  "Synchrony Financial",
  "Deutsche Bank",
  "New York Community Bank",
  "Mizuho Financial Group",
  "Raymond James Financial",
  "First Horizon National Corporation",
  "Western Alliance Bancorporation",
  "Comerica",
  "Webster Bank",
  "CIBC Bank USA",
  "East West Bank",
  "Popular, Inc.",
  "BNP Paribas",
  "John Deere Bank",
  "Wintrust Financial",
  "Valley Bank",
  "Synovus",
  "Old National Bank",
  "Columbia Bank",
  "Cullen/Frost Bankers, Inc.",
  "Pinnacle Financial Partners",
  "BOK Financial Corporation",
  "FNB Corporation",
  "UMB Financial Corporation",
  "South State Bank",
  "Associated Banc-Corp",
  "Prosperity Bancshares",
  "EverBank",
  "Midland",
  "Sumitomo Mitsui Banking Corporation",
  "Stifel",
  "BankUnited",
  "Hancock Whitney",
  "SoFi",
  "Banc of California",
  "Fulton Financial Corporation",
  "First National of Nebraska",
  "Texas Capital Bank",
  "Commerce Bancshares",
  "United Bank (West Virginia)",
  "First Interstate BancSystem",
  "Glacier Bancorp",
  "WaFd Bank",
  "FirstBank Holding Co",
  "United Community Bank",
  "Simmons Bank",
  "Arvest Bank",
  "Ameris Bancorp",
  "BCI Financial Group",
  "Eastern Bank",
  "Atlantic Union Bank",
  "Provident Bank of New Jersey",
  "Bank of Hawaii",
  "First Hawaiian Bank",
  "Axos Financial",
  "Cathay Bank",
  "Home BancShares",
  "Beal Bank",
  "Customers Bancorp",
  "WSFS Bank",
  "Pinnacle Bancorp",
  "Independent Bank",
  "First BanCorp",
  "Merchants Bank",
  "Central Bancompany",
  "Independent Bank Group",
  "WesBanco",
  "Other"
];
import {
  createSlice,
  type PayloadAction,
  type ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {
  UserStateEnum,
  type CarouselSliderItem
} from '@shared/types';
import {
  type GameCategory
} from '@entities/games';
import {
  landingPageApiSlice
} from '../api';

export type LandingPageTitlePlayInThreeSteps = {
  combinedText: string;
  linkKey: string;
  anchorText: string;
  href?: Url;
  underline: 'hover' | 'always' | 'none';
};

export type LandingPageStepPlayItem = {
  iconSrc: Url;
  title: string;
  description: string;
};

export type LandingPagePlayInThreeSteps = {
  title: LandingPageTitlePlayInThreeSteps;
  steps: Array<LandingPageStepPlayItem>;
};

export type ActionItem = {
  text: string;
  url: Url;
  userState: UserStateEnum;
};

export type LandingPageState = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
  playInThreeSteps: Maybe<LandingPagePlayInThreeSteps>;
  carouselSliderTop: Maybe<Array<CarouselSliderItem>>;
  carouselSliderMiddle: Maybe<Array<CarouselSliderItem>>;
  carouselSliderBottom: Maybe<Array<CarouselSliderItem>>;
  gamesByCategoriesBlockTop: Maybe<Array<GameCategory>>;
  gamesByCategoriesBlockBottom: Maybe<Array<GameCategory>>;
  action: Maybe<Array<ActionItem>>;
};

const initialState: LandingPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  playInThreeSteps: null,
  carouselSliderTop: null,
  carouselSliderMiddle: null,
  carouselSliderBottom: null,
  gamesByCategoriesBlockBottom: null,
  gamesByCategoriesBlockTop: null,
  action: null,
};

export const landingPageSlice = createSlice({
  name: 'landing-page',
  initialState,
  reducers: {
    initializeState: (state: LandingPageState, payload: PayloadAction<LandingPageState>) => {
      state = { ...payload.payload };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<LandingPageState>) => {
    builder.addMatcher(
      landingPageApiSlice.endpoints.getLandingPage.matchFulfilled,
      (state: LandingPageState, action: PayloadAction<any>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.playInThreeSteps = action.payload?.playInThreeSteps;
        state.carouselSliderTop = action.payload?.carouselSliderTop;
        state.carouselSliderMiddle = action.payload?.carouselSliderMiddle;
        state.carouselSliderBottom = action.payload?.carouselSliderBottom;
        state.gamesByCategoriesBlockBottom = action.payload?.gamesByCategoriesBlockBottom;
        state.gamesByCategoriesBlockTop = action.payload?.gamesByCategoriesBlockTop;
        state.action = action.payload.action;
      }
    );
  },
});

export const { initializeState } = landingPageSlice.actions;
export const landingPageReducer = landingPageSlice.reducer;

import {
  Middleware
} from '@reduxjs/toolkit';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  resetStore
} from '@shared/store-reset';
import {
  signOut,
} from '@entities/session';
import {
  appRouter
} from '../app-router';

export const handleNotLoggedInErrorMiddleware: Middleware =
  ({ dispatch }) => next => (action: unknown) => {
    const responseStatus = (action as { payload: { status: OmegaApiResponseStatusEnum } }).payload?.status;
    if (responseStatus === OmegaApiResponseStatusEnum.NotLoggedIn) {

      const currentLocation = window.location;
      const currentPath = currentLocation.pathname;
      const searchParams = currentLocation.search;

      if (currentPath !== '/login') {
        const fullPath = `${currentPath}${searchParams}`;
        sessionStorage.setItem('historyPath', fullPath);
      }

      dispatch(signOut());
      dispatch(resetStore());
      appRouter.navigate('/login');
    }
    return next(action);
  };

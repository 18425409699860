import {
  useRef,
  useState,
  ReactNode,
  useEffect,
  type FC,
} from 'react';
import {
  Box,
  Stack,
  Button,
  Checkbox,
  Typography,
} from '@mui/joy';
import {
  CloseIcon,
  IconButton,
  SimpleCircularLoader,
  useToaster
} from '@shared/ui';
import {
  isDefined,
  formatCurrency,
} from '@shared/lib';
import {
  OmegaApiResponseStatusEnum,
  ResponseMessageEnum
} from '@shared/api';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  worldPayIFrameConfig,
  maskCardDigitsIfClean,
  type CardSelectorItem,
} from '../lib';
import {
  type UpdatePaymentParams,
  useLazyUpdatePaymentQuery
} from '../api';
import {
  type CheckoutResult
} from '../model';
import {
  StoredCreditCardWorldPaySelector
} from './stored-credit-card-worldpay-selector.component';

export type CheckoutPaymentResultWorldPay = {
  id: string;
  bin: string;
  type: 'MC' | 'DI' | 'VI'; // MC - MasterCard, DI - Discover, VI - Visa
  expYear: string;
  expMonth: string;
  orderId: string;
  message: 'Success';
  response: string;
  lastFour: string;
  firstSix: string;
  checkoutId: string;
  vantivTxnId: string;
  reportGroup: string;
  targetServer: string;
  responseTime: string;
  paypageRegistrationId: string;
  invokeMerchantCallback: boolean;
};

export type BillingInfo = {
  billingEmail: Maybe<Email>;
  billingPhone: Maybe<Phone>;
  billingCity: Maybe<string>;
  billingState: Maybe<string>;
  billingCountry: Maybe<string>;
  billingAddress1: Maybe<string>;
  billingLastName: Maybe<string>;
  billingFirstName: Maybe<string>;
  billingPostalCode: Maybe<string>;
};

export type CheckoutInfoWorldPay = {
  payPageId: string;
  paymentId: number;
  reportGroup: string;
  merchantTxId: number;
};

export type CheckoutWorldPayFormProps = {
  amount: number;
  billingInfo: BillingInfo;
  checkoutInfo: CheckoutInfoWorldPay;
  submitButtonLabelSlot?: ReactNode;
  disclaimerSlot?: ReactNode;
  onClose?(): void;
  onCheckoutFail?(redeemResult?: CheckoutResult): void;
  onCheckoutSuccess?(redeemResult?: CheckoutResult): void;
};

export const CheckoutWorldPayForm: FC<CheckoutWorldPayFormProps> = ({
  amount,
  billingInfo,
  checkoutInfo,
  submitButtonLabelSlot,
  disclaimerSlot,
  onClose,
  onCheckoutFail,
  onCheckoutSuccess
}) => {
  const { merchantTxId: orderId, payPageId, paymentId: id, reportGroup } = checkoutInfo;

  const toast = useToaster();
  const cardRef = useRef<Maybe<CardSelectorItem>>(null);
  const eProtectIFrameClientRef = useRef<Maybe<any>>(null);
  const saveCardNumberRef = useRef<boolean>(true);
  const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
  const [showIFrameLoader, setShowIFrameLoader] = useState<boolean>(true);
  const [updatePaymentQuery] = useLazyUpdatePaymentQuery();

  useEffect(() => {
    const handleMessage = async (event: { data: 'checkoutWithEnter' }) => {
      if (event.data === 'checkoutWithEnter')
        eProtectIFrameClientRef.current?.getPaypageRegistrationId({ id, orderId });
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line
  }, [eProtectIFrameClientRef.current]);

  const setupIFrame = (): void => {
    const callback = async ({
      id,
      expMonth,
      expYear,
      lastFour,
      checkoutId,
      paypageRegistrationId,
      message: iframeValidationMessage,
      type,
    }: CheckoutPaymentResultWorldPay): Promise<void> => {
      if (iframeValidationMessage !== ResponseMessageEnum.Success) {
        toast.error({ message: iframeValidationMessage, autoHideDuration: 1000 });
        return;
      }

      const updatePaymentParams = {
        amount,
        paymentId: id,
        ...billingInfo,
        ...(isDefined(cardRef.current) && {
          checkoutId,
          paymentInformationId: cardRef.current!.id,
          paymentMethod: cardRef.current!.paymentMethod,
          ...(isDefined(cardRef.current?.type) && { cardType: cardRef.current?.type })
        }),
        ...(!isDefined(cardRef.current) && {
          registrationId: paypageRegistrationId,
          expiryDate: expMonth + expYear,
          last4Digit: lastFour,
          paymentMethod: PaymentMethodKeyEnum.WorldPayCNPCard,
          saveCardOnFile: saveCardNumberRef.current,
          cardType: type,
        })
      } as UpdatePaymentParams;

      const updatePaymentResponse = await updatePaymentQuery(updatePaymentParams);
      const updatePaymentPaymentId = updatePaymentResponse.data!.paymentId;
      const updatePaymentReferenceNo = updatePaymentResponse.data!.referenceNo;

      const checkoutResult = {
        amount: amount.toString(),
        cardNumber: maskCardDigitsIfClean(cardRef.current?.last4Digit ?? lastFour),
        ...(isDefined(updatePaymentReferenceNo) && { referenceId: updatePaymentReferenceNo }),
        ...(isDefined(id) && { paymentId: updatePaymentPaymentId })
      };

      const successUpdatePaymentMessage = updatePaymentResponse.data!.message === ResponseMessageEnum.Success
      const successUpdatePaymentStatus = [
        OmegaApiResponseStatusEnum.Success,
        OmegaApiResponseStatusEnum.ContinueToPaymentSite
      ].includes(updatePaymentResponse.data!.status);

      if (successUpdatePaymentStatus || successUpdatePaymentMessage) onCheckoutSuccess?.(checkoutResult);
      else onCheckoutFail?.(checkoutResult);
    };

    const eProtectiframeClient = new window.EprotectIframeClient({
      div: 'eProtectiframe',
      style: 'checkoutform3',
      callback,
      reportGroup,
      paypageId: payPageId,
      checkoutIdMode: isDefined(cardRef.current),
      ...worldPayIFrameConfig
    });


    const iframe = document.getElementById('eProtectiframe')?.querySelector('iframe');
    if (iframe) {
      iframe.onload = () => {
        setTimeout(() => {
          setShowIFrameLoader(false);
          setShowCheckoutForm(true);
        }, 1000);
      };
    }

    eProtectiframeClient.autoAdjustHeight();
    eProtectIFrameClientRef.current = eProtectiframeClient;
  };

  const handleCardSelected = (card: Maybe<CardSelectorItem>): void => {
    cardRef.current = card;
    setupIFrame();
  };

  const handleNoStoredCards = (): void => {
    setupIFrame();
  };

  const handleStoredCardsFound = (): void => {
    setShowIFrameLoader(false);
    setShowCheckoutForm(false);
  };

  const handleAddNewCard = (): void => {
    setShowIFrameLoader(true);
    setupIFrame();
  };

  const handleCheckoutSubmit = (): void => {
    if (cardRef.current) eProtectIFrameClientRef.current?.getCheckoutId({ id, orderId });
    else eProtectIFrameClientRef.current?.getPaypageRegistrationId({ id, orderId });
  };

  const handleChangeSaveCardNumber = (): void => {
    saveCardNumberRef.current = !saveCardNumberRef.current;
  };

  return (
    <Stack
      flexDirection='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        padding: 3,
        [breakpoints.down(490)]: {
          paddingInline: 2,
          paddingTop: 2,
          paddingBottom: 4,
        },
        [breakpoints.down(390)]: {
          paddingInline: 1,
          paddingBottom: 2,
        },
      })}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          width: '100%',
          marginBottom: 3,
        }}>
        <Typography
          level='h3'
          fontSize='1.25rem'>
          Checkout
        </Typography>
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <Stack
        sx={{
          direction: 'column',
          width: '100%',
          height: '100%',
          overflowY: 'auto'
        }}>
        <StoredCreditCardWorldPaySelector
          amount={`${amount}`}
          submitButtonLabelSlot={submitButtonLabelSlot}
          onCardSelected={handleCardSelected}
          onNoStoredCards={handleNoStoredCards}
          onStoredCardsFound={handleStoredCardsFound}
          onAddNewCard={handleAddNewCard}
        />
        {showIFrameLoader && (
          <Stack direction='column'
            alignItems='center'
            justifyContent='center'
            sx={{
              height: '100%',
              width: '100%',
              marginTop: '-24px'
            }}>
            <SimpleCircularLoader />
          </Stack>
        )}
        <Stack
          sx={{
            visibility: 'hidden',
            height: '0%',
            ...(showCheckoutForm && ({
              height: '100%',
              visibility: 'visible'
            }))
          }}>
          <Box
            component='div'
            id='eProtectiframe'
            sx={{
              height: '100%',
              marginBottom: 2,
              '&>iframe': { height: '100%', minHeight: 331 },
            }}
          />
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{
              paddingInline: 1,
              rowGap: 1
            }}>
            <Box
              sx={{
                gap: 1,
                display: 'grid',
                gridTemplateColumns: '26px 1fr',
              }}>
              {disclaimerSlot}
              <Checkbox
                id='save-my-card'
                disabled={showIFrameLoader}
                defaultChecked={saveCardNumberRef.current}
                onClick={handleChangeSaveCardNumber}
                sx={{
                  gridColumnStart: 1,
                  gridColumnEnd: 2,
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
              <Typography
                component='label'
                htmlFor='save-my-card'
                sx={({ palette }) => ({
                  gridColumnStart: 2,
                  gridColumnEnd: 3,
                  color: palette.common[150],
                  fontWeight: 400,
                  lineHeight: '150%',
                  textAlign: 'justify',
                  cursor: 'pointer'
                })}>
                I want to save my Card details for future purchases
              </Typography>
            </Box>
            <Button
              fullWidth
              type='submit'
              color='primary'
              disabled={showIFrameLoader}
              onClick={handleCheckoutSubmit}>
              {submitButtonLabelSlot
                ? submitButtonLabelSlot
                : `Pay ${formatCurrency(amount)}`}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
import {
  lazy,
  useId,
  useRef,
  useState,
  useEffect,
  forwardRef,
  Suspense,
  type ReactNode,
} from 'react';
import {
  FormControlProps,
  FormLabelProps,
  AutocompleteOptionProps as JoyAutocompleteOptionProps,
  Autocomplete as JoyAutocomplete,
  FormLabel,
  FormControl,
  FormHelperText
} from '@mui/joy';

const KeyboardArrowDownIcon = lazy(() => import('@mui/icons-material/KeyboardArrowDown'));

export type AutocompleteOptionType = {
  value: string;
  label: string;
};

export type AutocompleteProps = Partial<{
  label?: Maybe<string>;
  options: Array<AutocompleteOptionType>;
  value?: Maybe<string>;
  defaultValue?: Maybe<string>;
  placeholder?: Maybe<string>;
  error?: Maybe<string>;
  disabled?: Maybe<boolean>;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  startDecorators?: ReactNode;
  endDecorators?: ReactNode;
  inputProps?: Maybe<{
    root?: Maybe<FormControlProps>;
    label?: Maybe<FormLabelProps>;
    option?: Maybe<JoyAutocompleteOptionProps>;
  }>
}>;

export const Autocomplete = forwardRef<HTMLButtonElement, AutocompleteProps>((props, ref) => {
  const selectId = useId();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const formControlRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formControlRef.current && !formControlRef.current.contains(event.target as Node)) {
        if (isFocused) {
          setIsFocused(false);
          props?.onBlur?.();

        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocused, props, props?.onBlur]);

  const handleChange = (event: any, newValue: Maybe<string>): void => {
    props?.onChange?.(newValue ?? '');
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <FormControl
      ref={formControlRef}
      onFocus={() => setIsFocused(true)}
      disabled={props?.disabled ?? false}
      error={!!props?.error}
      sx={[{
        minHeight: 90,
      },
      ...(props?.inputProps?.root
        && Array.isArray(props?.inputProps?.root?.sx)
        ? props?.inputProps?.root?.sx
        : [props?.inputProps?.root?.sx])
      ]}
      {...props?.inputProps?.root}>
      {props?.label && (
        <FormLabel
          htmlFor={selectId}
          sx={[
            { marginBottom: 0.5 },
            ...(props?.inputProps?.label
              && Array.isArray(props?.inputProps?.label?.sx)
              ? props?.inputProps?.label?.sx
              : [props?.inputProps?.label?.sx])
          ]}
          {...props?.inputProps?.label}>
          {props?.label}
        </FormLabel>
      )}
      <JoyAutocomplete
        id={selectId}
        value={props?.value}
        inputValue={inputValue}
        placeholder={props?.placeholder ?? ''}
        defaultValue={props?.defaultValue}
        startDecorator={props?.startDecorators}
        endDecorator={props?.endDecorators}
        disabled={props?.disabled ?? false}
        onChange={handleChange}
        popupIcon={(
          <Suspense>
            <KeyboardArrowDownIcon />
          </Suspense>
        )}
        onInputChange={handleInputChange}
        options={props.options?.map(option => option.label) ?? []}
        slotProps={{
          root: {
            sx: [({ palette }) => ({
              height: 40,
              color: palette.common.white,
              backgroundColor: palette.common[475],
              borderColor: `${palette.common[925]} !important`,
              borderStyle: 'solid',
              borderWidth: 1,
              borderRadius: 8,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: palette.common[475],
              },
              ...(props?.error && ({
                borderColor: palette.common.error
              }))
            }),
            ]
          },
          listbox: {
            sx: ({ palette }) => ({
              zIndex: 99999,
              borderRadius: 12,
              border: 'none',
              background: palette.background.body,
              boxShadow: `0px 0px 16px 0px ${palette.common[300]}`,
              borderColor: palette.common[475],
              borderWidth: 1,
              width: '100%'
            })
          },
          popupIndicator: {
            sx: {
              '&:hover': {
                background: 'transparent'
              },
              '& .MuiSvgIcon-root': {
                height: 24,
                width: 24
              }
            },
          },
          clearIndicator: {
            sx: {
              '&:hover': {
                background: 'transparent'
              },
              '& .MuiSvgIcon-root': {
                height: 24,
                width: 24
              }
            },
          }
        }}
      />
      {props?.error && (
        <FormHelperText sx={{ marginTop: 0.5 }}>
          {props?.error}
        </FormHelperText>
      )}
    </FormControl>
  );
});
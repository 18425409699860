import {
  baseApi,
  HOME_PAGE_TAG
} from '@shared/api';
import {
  mapLobbyPageState
} from '../lib';
import {
  LobbyPageState
} from '../model';

export const lobbyPageApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getLobbyPage: build.query<LobbyPageState, void>({
      query: () => ({ url: '/api/home-page' }),
      extraOptions: { showLoader: false, auth: true },
      providesTags: [HOME_PAGE_TAG],
      transformResponse: (response: any) => mapLobbyPageState(response),
    })
  }),
})

export const { useGetLobbyPageQuery } = lobbyPageApiSlice;

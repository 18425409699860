import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from 'reselect';
import {
  type GamesState,
  gamesSlice
} from '@entities/games';
import {
  GameItem
} from '@entities/games/model';
import {
  selectGamesByCategoryKey,
  selectSelectedCategoryKey,
  gamesFilteredByCategoriesSlice,
  selectIsLoading as selectIsLoadingByCategory,
  type GamesFilterByCategoryState,
} from '@features/games-filter-by-category';
import {
  selectGames as selectGamesBySearch,
  selectIsLoading as selectIsLoadingBySearch,
} from '@features/games-filter-by-search';
import {
  selectGames as selectGamesByFavorite,
  selectIsLoading as selectIsLoadingByFavorite,
} from '@features/games-filter-by-favorite';
import {
  gamesListWidgetSlice
} from './slice';
import {
  FilterTypeEnum,
  type GamesListState
} from './types';

type RootState = {
  [gamesSlice.name]: GamesState;
  [gamesListWidgetSlice.name]: GamesListState;
  [gamesFilteredByCategoriesSlice.name]: GamesFilterByCategoryState;
};

export const selectActiveFilterType = (state: RootState): FilterTypeEnum => (
  state[gamesListWidgetSlice.name].activeFilterType
);

export const selectGamesByCategory = createSelector(
  [selectSelectedCategoryKey, state => state],
  (selectedCategoryKey: Maybe<string>, state: RootState) => selectGamesByCategoryKey(selectedCategoryKey)(state)
);

export const selectGamesByActiveFilter = createSelector(
  [selectActiveFilterType, selectGamesByCategory, selectGamesBySearch, selectGamesByFavorite],
  (
    activeFilterType: FilterTypeEnum,
    gamesByCategory: Maybe<GameItem[]>,
    gamesBySearch: Maybe<GameItem[]>,
    gamesByFavorite: Maybe<GameItem[]>
  ) => {
    if (activeFilterType === FilterTypeEnum.Category) return gamesByCategory;
    else if (activeFilterType === FilterTypeEnum.Search) return gamesBySearch;
    else if (activeFilterType === FilterTypeEnum.Favorite) return gamesByFavorite;
    return [];
  }
);

export const selectGamesLoading = createSelector(
  [selectIsLoadingByCategory, selectIsLoadingBySearch, selectIsLoadingByFavorite],
  (
    gamesByCategory: boolean,
    gamesBySearch: boolean,
    gamesByFavorite: boolean
  ) => {
    return gamesByCategory || gamesBySearch || gamesByFavorite;
  }
);

export const useGamesListWidgetSelector: TypedUseSelectorHook<any> = useSelector;

import {
  useRef,
  useEffect,
  type FC,
} from 'react';
import {
  Box,
  Stack,
  styled,
  Typography,
} from '@mui/joy';
import {
  Swiper,
  SwiperRef,
  SwiperSlide,
} from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  useGetGameWinnerListQuery
} from '@entities/game-winners';
import {
  useLaunchGame
} from '@features/launch-game';
import {
  GameWinnerItem,
  selectGameWinners,
  useGameWinnersSelector,
} from '../model';
import {
  getWinnerName,
  getFormattedWinningAmount,
} from '../lib';

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  width: '100%',

  '.swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },

  '.swiper-slide': {
    width: 'auto',
  },

  [theme.breakpoints.down(912)]: {
    width: 'calc(100% + 16px)',
  },
}));

export const GameWinnersCarousel: FC = () => {
  const winners = useGameWinnersSelector(selectGameWinners());
  useGetGameWinnerListQuery();
  const { launchGame } = useLaunchGame();

  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    if (!swiperRef.current || !winners.length) return;
    setTimeout(() => swiperRef.current!.swiper?.slideNext(3000), 0);
    let isMoving = true;

    const callback = (eventName: string) => {
      if (eventName === 'touchStart') isMoving = false;
      else if (eventName === 'touchEnd') isMoving = true;
      else if ((eventName === 'slideNextTransitionEnd' || eventName === 'slidePrevTransitionEnd') && isMoving) {
        swiperRef.current!.swiper?.slideNext(3000);
      }
      else if (eventName === 'transitionEnd' && isMoving) setTimeout(() => swiperRef.current!.swiper?.slideNext(3000), 0);
    };
    swiperRef.current.swiper?.onAny(callback);

    return () => {
      //eslint-disable-next-line
      swiperRef.current?.swiper?.offAny(callback);
    }
  }, [winners]);

  const handleSlideClick = (gameId: number) => () => {
    launchGame(gameId);
  };

  return !!winners.length && (
    <Stack gap={2}>
      <Typography
        sx={({ palette, breakpoints }) => ({
          fontSize: 28,
          fontWeight: 600,
          lineHeight: '36px',
          color: palette.common.white,
          [breakpoints.down(912)]: {
            fontSize: 24,
            lineHeight: '30px',
          }
        })}>
        Latest winners
      </Typography>

      <StyledSwiper
        spaceBetween={12}
        slidesPerView={'auto'}
        loop
        ref={swiperRef}>
        {winners.map((winner: GameWinnerItem, index: number) => (
          <SwiperSlide key={index}>
            <Stack
              onClick={handleSlideClick(winner.gameId)}
              direction='row'
              alignItems='center'
              sx={({ palette }) => ({
                gap: 2,
                width: 311,
                paddingBlock: 1,
                borderRadius: 8,
                cursor: 'pointer',
                paddingInline: 1.5,
                boxSizing: 'border-box',
                background: palette.common[475],
                border: `1px solid ${palette.common[300]}`,
                ':hover': {
                  border: `1px solid ${palette.common[875]}`,
                }
              })}>
              <Box
                sx={{
                  width: 100,
                  height: 75,
                  borderRadius: 4,
                  background: `url(${winner.gameBannerUrl}), lightgray 50% / cover no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }} />

              <Stack gap={0.5}>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common.white,
                  })}>
                  {getWinnerName(winner)}
                </Typography>

                <Typography
                  sx={({ palette }) => ({
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[825],
                  })}>
                  {getFormattedWinningAmount(winner)}
                </Typography>
              </Stack>
            </Stack>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Stack>
  );
};

import {
  baseApi,
} from '@shared/api';
import {
  mapBonusConfig,
  mapChestsConfig
} from '../lib';
import {
  type BonusConfig,
  type ChestConfig,
  BonusTriggerTypeEnum,
} from '../model';

export type MakeBonusPlanAwardsParams = {
  partyId: number;
  bonusPlanId: number;
  triggerType: BonusTriggerTypeEnum;
};

export type MakeBonusPlanAwards = {
  message: string;
  partyId: number;
  success: boolean;
};

export type MakeBonusPlanAwardsData = {
  error?: string;
  message?: string;
  hasFailedResults?: boolean;
  code?: 'SUCCESS' | string;
  awards?: Array<MakeBonusPlanAwards>;
};

export type BonusConfiguration = {
  bonusConfig: BonusConfig;
  chests: Array<ChestConfig>;
};

export const bonusTriggerConfigApiSlice = baseApi.injectEndpoints({
  endpoints: build => ({
    getBonusConfiguration: build.query<BonusConfiguration, void>({
      query: () => ({ url: '/api/bonus-configuration' }),
      extraOptions: { auth: false, showLoader: false },
      transformResponse: (response: { data: unknown }) => {
        return {
          bonusConfig: mapBonusConfig(response.data),
          chests: mapChestsConfig(response.data),
        }
      }
    }),
    makeBonusPlanAwards: build.mutation<MakeBonusPlanAwardsData, Maybe<MakeBonusPlanAwardsParams>>({
      query: (params?: MakeBonusPlanAwardsParams) => ({ url: '/api/bonus-plans/awards', method: 'POST', params }),
      extraOptions: { auth: true, showLoader: true },
      transformResponse: (response: MakeBonusPlanAwardsData) => {
        return response;
      }
    })
  }),
});

export const {
  useMakeBonusPlanAwardsMutation,
  useGetBonusConfigurationQuery,
  useLazyGetBonusConfigurationQuery
} = bonusTriggerConfigApiSlice;

import {
  createSlice,
  type PayloadAction,
  type ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  ConsentKeyEnum,
  ConsentStatusEnum,
  consentsApiSlice,
} from '@entities/consents';
import {
  managePromotionalSubscriptionsApiSlicer
} from '../api';

export type EmailPromotionSubscriptionState = {
  emailPromotionSubscription: boolean;
  smsPromotionSubscription: boolean;
};

const initialState: EmailPromotionSubscriptionState = {
  emailPromotionSubscription: false,
  smsPromotionSubscription: false
};

export const managePromotionalSubscriptionsSlice = createSlice({
  name: 'manage-promotional-subscriptions',
  initialState,
  reducers: {
    setSmsPromotionSubscription: (state: EmailPromotionSubscriptionState, action: PayloadAction<boolean>) => {
      state.smsPromotionSubscription = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<EmailPromotionSubscriptionState>) => {
    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.getEmailSubscriptionState.matchFulfilled,
      (state, action) => {
        if (action?.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.emailPromotionSubscription = action.payload.subscribeToEmail === 'TRUE'
        }
      });

    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.updateEmailSubscription.matchFulfilled,
      state => {
        state.emailPromotionSubscription = !state.emailPromotionSubscription
      });

    builder.addMatcher(
      consentsApiSlice.endpoints.getUserConsents.matchFulfilled,
      (state, action) => {
        const emailConsent = action.payload.find(({ key }) => key === ConsentKeyEnum.SmsSubscribed);
        if (emailConsent) {
          state.smsPromotionSubscription = emailConsent.consentStatus === ConsentStatusEnum.Consented;
        }
        else {
          state.smsPromotionSubscription = false;
        }
      });
  }
});

export const { setSmsPromotionSubscription } = managePromotionalSubscriptionsSlice.actions;
export const managePromotionalSubscriptionsReducer = managePromotionalSubscriptionsSlice.reducer;

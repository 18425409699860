import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  GetDynamicPageApiNameErrors,
  dynamicPageApiSlice
} from '../api';
import {
  type DynamicPageState
} from './types';

const initialState: DynamicPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  name: null,
  contentType: null,
  content: null,
  pageNotFound: false,
  kycRequired: false,
  authenticationRequired: false,
  playerRegistrationRequired: false,
};

export const dynamicPageSlice = createSlice({
  name: 'dynamic-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DynamicPageState>) => {
    builder.addMatcher(
      dynamicPageApiSlice.endpoints.getDynamicPage.matchFulfilled,
      (state: DynamicPageState, action: PayloadAction<DynamicPageState>) => {
        state.title = action.payload.title;
        state.keywords = action.payload.keywords;
        state.description = action.payload.description;
        state.canonical = action.payload.canonical;
        state.name = action.payload.name;

        state.content = action.payload.content;
        state.contentType = action.payload.contentType;

        state.kycRequired = false;
        state.pageNotFound = false;
        state.authenticationRequired = false;
        state.playerRegistrationRequired = false;
      },
    );
    builder.addMatcher(
      dynamicPageApiSlice.endpoints.getDynamicPage.matchRejected,
      (state: DynamicPageState, action: PayloadAction<unknown>) => {
        Object.assign(state, initialState, {
          kycRequired: action.payload === GetDynamicPageApiNameErrors.KycRequired,
          pageNotFound: action.payload === GetDynamicPageApiNameErrors.NotFoundError,
          authenticationRequired: action.payload === GetDynamicPageApiNameErrors.AuthRequired,
          playerRegistrationRequired: action.payload === GetDynamicPageApiNameErrors.PlayerRegistrationRequired
        });
      },
    );
  }
});

export const dynamicPageReducer = dynamicPageSlice.reducer;
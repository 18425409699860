import { mapper } from '@shared/lib';
import { type SidebarMenu } from '../model';
import { mapSidebarMenuItem } from './map-sidebar-menu-item';

type MapSidebarMenu = (data: any) => SidebarMenu;
export const mapSidebarMenu: MapSidebarMenu = mapper([
  {
    name: 'logo',
    from: 'logo.data.attributes.url',
    transform: (logoUrl: any) => ({ variant1SizeImgSrc: logoUrl }),
  },
  {
    name: 'banner',
    from: 'banner.data.attributes.url',
    transform: (logoUrl: any) => ({ variant1SizeImgSrc: logoUrl }),
  },
  {
    name: 'menuItems',
    from: 'menuItems',
    transform: (menuItemsData: Array<any>) => {
      const nonOrderedItems = menuItemsData?.map(mapSidebarMenuItem);
      const orderedItems = nonOrderedItems.sort(({ order: firstItemOrder }, { order: secondItemOrder }) =>
        firstItemOrder - secondItemOrder);
      return orderedItems;
    }
  },
]);
export const maskCardDigitsIfClean = (input: string): string => {
  const isFourDigits = /^\d{4}$/.test(input);
  const hasBullets = /•/.test(input);
  if (isFourDigits && !hasBullets) {
    return `•••• •••• •••• ${input}`;
  } else if (!hasBullets && input.length >= 5 && input.length <= 17) {
    const maskedSection = '•'.repeat(input.length - 4);
    const visibleSection = input.slice(-4);
    return `${maskedSection}${visibleSection}`;
  }
  return input;
};

import {
  type FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  Stack,
  Button,
  Divider,
  type StackProps,
  type ButtonProps
} from '@mui/joy';

export type PlayNowButtonProps = & {
  allowOrBlock?: boolean;
  inputProps?: {
    root?: StackProps;
    button?: Omit<ButtonProps, 'onClick'>;
  }
};

export const PlayNowButton: FC<PlayNowButtonProps> = ({
  allowOrBlock = false,
  inputProps = {},
}) => {
  const navigate = useNavigate();
  const handleNavigate = (): void => {
    navigate('/lobby', { replace: true });
  };
  return (
    <Stack gap={.5} {...inputProps?.root ?? {}}>
      {allowOrBlock && <Divider>or</Divider>}
      <Button onClick={handleNavigate} {...inputProps?.button ?? {}}>
        Play Now
      </Button>
    </Stack>
  );
};
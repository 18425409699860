import {
  FC
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  IconButton,
  MenuIcon,
} from '@shared/ui';
import {
  AuthenticatedOnlyContent
} from '@entities/session'
import {
  openSidebarMenu
} from '../model';

export const SidebarMenuButton: FC = () => {
  const dispatch = useDispatch();

  const handleToggleSidebarMenu = (): void => {
    dispatch(openSidebarMenu());
  };

  return (
    <AuthenticatedOnlyContent>
      <IconButton
        size='sm'
        sx={{
          height: 'min-content'
        }}
        onClick={handleToggleSidebarMenu}>
        <MenuIcon
          sx={({ palette }) => ({
            fontSize: 20,
            color: palette.common[1065]
          })}
        />
      </IconButton>
    </AuthenticatedOnlyContent>
  );
};
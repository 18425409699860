import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  removeQueryParam,
  addOrUpdateQueryParam,
} from './utilities';

export type UseModalQueryParam = {
  openModal(modalParam: string): void;
  closeModal(modalParam: string): void;
  toggleModal(closeParam: string, openParam?: string): void;
};

export const useModalQueryParam = (): UseModalQueryParam => {
  const navigate = useNavigate();
  const location = useLocation();

  const modifyQueryParam = (param: string, value?: string): void => {
    let queryString = value
      ? addOrUpdateQueryParam(location.search, param, value)
      : removeQueryParam(location.search, param);
    navigate(`?${queryString}`, { replace: true });
  };

  const openModal = (modalParam: string) => {
    modifyQueryParam(modalParam, 'true');
  };

  const closeModal = (modalParam: string) => {
    modifyQueryParam(modalParam);
  };

  const toggleModal = (closeParam: string, openParam?: string): void => {
    let queryString = removeQueryParam(location.search, closeParam);
    if (openParam) {
      queryString = addOrUpdateQueryParam(queryString, openParam, 'true');
    }
    navigate(`?${queryString}`, { replace: true });
  };

  return {
    openModal,
    closeModal,
    toggleModal
  };
};
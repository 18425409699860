import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';

export enum TreasureChestStatusEnum {
  Closed = 'CLOSED',
  OpenClaimed = 'OPEN_CLAIMED',
  OpenUnclaimed = 'OPEN_UNCLAIMED',
};

export enum BonusStatusEnum {
  Active = 'ACTIVE',
  Queued = 'QUEUED',
};

export enum BonusTriggerTypeEnum {
  Coded = 'CODED',
  Manual = 'MANUAL',
  Deposit = 'DEPOSIT',
};

export type RewardBonusPlanId = {
  bonusIdSelectionRate: number;
  bonusId: number;
  successMessage: string;
};

export type BonusInfo = {
  id: number;
  planName: string;
  planId: number;
  status: BonusStatusEnum;
  triggerType: BonusTriggerTypeEnum;
  amountWageredNumber: number;
  wagerRequirementNumber: number;
  triggerDate: string;
  expiryDate: string;
};

export type ChestConfig = {
  active: boolean;
  title: string;
  repeat: number;
  steps: number;
  purchaseButtonText: string;
  rewardButtonTextClaimed: string;
  rewardButtonTextUnclaimed: string;
  rewardBonusPlanIds: Array<RewardBonusPlanId>;
  purchasesBonusPlanId: number;
  claimProgressText: string;
};

export type BonusConfig = {
  title: string;
  link: { text: string, slug: Url; };
};

export type ChestWithBonuses = ChestConfig & {
  rewardBonuses: Array<BonusInfo>;
  purchaseBonuses: Array<BonusInfo>;
  redeemableCoinAmount: Maybe<number>;
  nonRedeemableCoinAmount: Maybe<number>;
};

export type ClaimBonusCoinsState = {
  bonusConfig: Maybe<BonusConfig>;
  chests: Maybe<Array<ChestWithBonuses>>;
};

const initialState: ClaimBonusCoinsState = {
  bonusConfig: null,
  chests: [],
};

export const claimBonusCoinsSlice = createSlice({
  name: 'claim-bonus-coins',
  initialState,
  reducers: {
    setBonusConfig: (state: ClaimBonusCoinsState, action: PayloadAction<Maybe<BonusConfig>>) => {
      state.bonusConfig = action.payload;
    },
    setChests: (state: ClaimBonusCoinsState, action: PayloadAction<Maybe<Array<ChestWithBonuses>>>) => {
      state.chests = action.payload;
    },
  },
});

export const {
  setChests,
  setBonusConfig
} = claimBonusCoinsSlice.actions;

export const claimBonusCoinsReducer = claimBonusCoinsSlice.reducer;
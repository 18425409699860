import {
  baseOmegaApi,
  type OmegaApiResponse,
} from '@shared/api';
import {
  mapToCoinPackage,
  type CoinPackage
} from '../lib';

export type GetPackagesFromOmegaResponse = OmegaApiResponse & {
  data: Array<CoinPackage>;
};

export type GetCoinPackagesParams = {
  currency: 'USD' | string;
};

export const coinsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: build => ({
    getCoinPackages: build.query<Array<CoinPackage>, Maybe<GetCoinPackagesParams>>({
      query: (params?: GetCoinPackagesParams) => ({
        url: '/ips/getPackage',
        params: params ?? { currency: 'USD' }
      }),
      transformResponse: (response: GetPackagesFromOmegaResponse) => {
        return response.data.map(mapToCoinPackage)
      },
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});

export const {
  useGetCoinPackagesQuery,
  useLazyGetCoinPackagesQuery
} = coinsApiSlice;

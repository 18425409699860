import {
  mapper,
  mapActionItem,
  mapCarouselSliderItem,
} from '@shared/lib';
import {
  mapGameCategory
} from '@entities/games';
import {
  type LandingPageState
} from '../model';
import {
  mapPlayInThreeSteps
} from './map-play-in-three-steps';

type MapLandingPageState = (data: any) => LandingPageState;
export const mapLandingPageState: MapLandingPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  {
    name: 'playInThreeSteps',
    from: 'data.attributes.playInThreeSteps',
    transform: (playInThreeStepsData: any) => mapPlayInThreeSteps(playInThreeStepsData),
  },
  {
    name: 'carouselSliderTop',
    from: 'data.attributes.carouselSliderTop',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapCarouselSliderItem),
  },
  {
    name: 'carouselSliderMiddle',
    from: 'data.attributes.carouselSliderMiddle',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapCarouselSliderItem),
  },
  {
    name: 'carouselSliderBottom',
    from: 'data.attributes.carouselSliderBottom',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapCarouselSliderItem),
  },
  {
    name: 'gamesByCategoriesBlockTop',
    from: 'data.attributes.gamesByCategoriesBlockTop',
    transform: (gameCategoriesData: Maybe<Array<{ categ: { data: any } }>>) => {
      return gameCategoriesData?.map(({ categ }) => mapGameCategory(categ.data));
    }
  },
  {
    name: 'gamesByCategoriesBlockBottom',
    from: 'data.attributes.gamesByCategoriesBlockBottom',
    transform: (gameCategoriesData: Maybe<Array<{ categ: { data: any } }>>) => {
      return gameCategoriesData?.map(({ categ }) => mapGameCategory(categ.data));
    }
  },
  {
    name: 'action',
    from: 'data.attributes.action',
    transform: (data: Array<any>) => {
      if (!Array.isArray(data)) return null;
      return data?.map(actionItem => mapActionItem(actionItem));
    }
  },
]);

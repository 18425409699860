import {
  memo,
  type FC,
} from 'react';
import {
  Link,
  Stack,
  Divider
} from '@mui/joy';
import {
  Button
} from '@shared/ui';
import {
  type CoinPackage,
} from '../lib';
import {
  CoinPackageTitle
} from './coin-package-title.component';
import {
  CoinPackageSummary
} from './coin-package-summary.component';

export type CoinPackageCardProps = {
  data: CoinPackage;
  onPurchase: (coinPackage: CoinPackage) => void;
};

export const CoinPackageCard: FC<CoinPackageCardProps> = memo(({
  data,
  onPurchase
}) => {
  const {
    title,
    price,
    variant,
    currency,
    promoTAndCApplyUrl,
    redeemableCoinAmount,
    nonRedeemableCoinAmount
  } = data;
  const handlePurchase = (): void => {
    onPurchase(data);
  };
  return (
    <Stack
      sx={({ palette }) => ({
        padding: 2,
        width: '100%',
        cursor: 'pointer',
        borderRadius: '8px',
        backgroundColor: palette.common[475],
        transition: 'box-shadow 0.1s ease',
        ':hover': {
          boxShadow: `0 0 8px 0 ${palette.common[350]}}`,
        },
      })}
      onClick={handlePurchase}>
      <CoinPackageTitle
        text={title || undefined}
        variant={variant || undefined}
      />
      <Stack>
        <CoinPackageSummary
          variant={variant}
          smallLabel
          redeemableCoinAmount={redeemableCoinAmount}
          nonRedeemableCoinAmount={nonRedeemableCoinAmount}
        />
        <Divider
          inset='none'
          orientation='horizontal'
          sx={{ marginBlock: 1 }}
        />
        <Stack
          alignItems='center'
          gap={1}>
          <Button
            color='primary'
            sx={{ minWidth: 146, }}>
            {currency} {price}
          </Button>
          {promoTAndCApplyUrl && (
            <Link
              fontSize={12}
              color='neutral'
              underline='none'
              lineHeight='normal'
              justifyContent='center'
              href={promoTAndCApplyUrl}
              target='_blank'
              onClick={(e) => e.stopPropagation()}>
              Promo T&Cs Apply
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});
import { baseApi } from '@shared/api';
import { type GamesWithPagination, mapGamesWithPagination } from '@entities/games';

export const gamesFilterBySearchApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGames: build.query<GamesWithPagination, Record<string, any>>({
      query: (params: Record<string, any>) => ({ url: '/api/games', params }),
      extraOptions: { showLoader: true },
      transformResponse: (response: any) => mapGamesWithPagination(response),
    }),
  }),
});

import { ComponentType, Fragment } from 'react';
import { CircularLoader } from './circular-loader.component';

export const withLoaderRoot = <P extends object>(
  WrappedComponent: ComponentType<P>
) => (props: P) => (
  <Fragment>
    <WrappedComponent {...props} />
    <CircularLoader />
  </Fragment>
);

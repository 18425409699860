import {
  lazy,
  Suspense,
  useState,
  Fragment,
  type FC,
  useEffect,
} from 'react';
import {
  IconButton,
  Skeleton,
  Stack,
  Typography
} from '@mui/joy';
import {
  PlayNowButton
} from '@shared/ui';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  type CoinPackage
} from '@entities/coins';
import {
  type FullProfile
} from '@entities/session';
import {
  CheckoutFailedForm,
  usePaymentsSelector,
  PaymentMethodSelector,
  PaymentUnavailableMessage,
  PaymentMethodOrderSetTypeEnum,
  useGetPaymentMethodOrderSetsQuery,
  selectRecentlyUsedPaymentMethod,
  selectDepositPaymentMethodOrderSets,
  type PaymentMethod,
  CheckoutResult,
  CheckoutSuccessForm,
  selectDepositSinglePaymentMethod,
} from '@entities/payments';
import {
  PaymentProcessorWorldPay
} from './payment-processor-worldpay.component';
import {
  PaymentProcessorPaymentIQ
} from './payment-processor-paymentiq.component';

const ArrowBackIcon = lazy(() => import('@mui/icons-material/ArrowBack'));

type BuyCoinsFormView =
  'selector' |
  'checkout' |
  'success' |
  'failed';

export type BuyCoinsFormProps = {
  playerProfile: Maybe<FullProfile>
  purchase: Maybe<CoinPackage>;
  sessionKey: string;
  partyId: string;
  onClose?(): void;
  onStart?(): void;
  onFailed?(result: {
    purchase: Maybe<CoinPackage>;
    paymentMethod: Maybe<PaymentMethod>;
  }): void;
  onSuccess?(result: {
    purchase: Maybe<CoinPackage>;
    paymentMethod: Maybe<PaymentMethod>;
  }): void;
};

export const BuyCoinsForm: FC<BuyCoinsFormProps> = ({
  onStart,
  onClose,
  onFailed,
  onSuccess,
  partyId,
  purchase,
  sessionKey,
  playerProfile,
}) => {
  const { refetch, isFetching } = useGetPaymentMethodOrderSetsQuery(undefined, { refetchOnMountOrArgChange: true });

  const { paymentMethods, recently, single } = usePaymentsSelector(state => ({
    paymentMethods: selectDepositPaymentMethodOrderSets(PaymentMethodOrderSetTypeEnum.Deposit)(state),
    recently: selectRecentlyUsedPaymentMethod(state),
    single: selectDepositSinglePaymentMethod(state),
  }));

  const [view, setView] = useState<Maybe<BuyCoinsFormView>>(null);
  const [paymentMethod, setPaymentMethod] = useState<Maybe<PaymentMethod>>(single || null);
  const [checkoutResult, setCheckoutResult] = useState<Maybe<CheckoutResult>>(null);

  // TODO: refactor me: remove from here
  useEffect(() => {
    if (!isFetching && single) {
      setPaymentMethod(single);
      setView('checkout');
    } else if (!isFetching && !single) {
      setView('selector');
    }
  }, [isFetching, single]);

  const handlePaymentMethodSelection = (method: PaymentMethod): void => {
    setPaymentMethod(method);
    setView('checkout');
  };

  const handlePaymentSuccess = (result?: CheckoutResult): void => {
    if (paymentMethod?.key) {
      const triggerPaymentCheckoutSuccess = ({
        [PaymentMethodKeyEnum.WorldPayCNPCard]: () => {
          setCheckoutResult(result);
          setView('success');
          onSuccess?.({ purchase, paymentMethod });
        },
        [PaymentMethodKeyEnum.PaymentIQ]: () => {
          // No need to add setView because PaymentIQ has his own success form
          onSuccess?.({ purchase, paymentMethod });
        },
        [PaymentMethodKeyEnum.AptPay]: () => {
          console.warn('Not implemented yet');
        },
        [PaymentMethodKeyEnum.Unipaas]: () => {
          console.warn('Not implemented yet');
        }
      })[paymentMethod.key];
      triggerPaymentCheckoutSuccess?.();
    }
  };

  const handlePaymentFailed = (result?: CheckoutResult): void => {
    setCheckoutResult(result);
    setView('failed');
    onFailed?.({ purchase, paymentMethod });
  };

  const handlePaymentUnavailable = async (): Promise<void> => {
    setView('selector');
    setPaymentMethod(null);
    refetch();
  };

  return (
    <Fragment>
      {isFetching ? (
        <Skeleton
          variant="rectangular"
          height='600px'
          width='100%'
        />
      ) : (
        <Fragment>
          {view === 'selector' && (
            <PaymentMethodSelector
              recently={recently}
              recommended={paymentMethods?.recommended}
              alternative={paymentMethods?.alternative}
              onSelect={handlePaymentMethodSelection}
              titleSlot={(
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='start'
                  width='100%'
                  gap={2}>
                  <IconButton onClick={onClose}>
                    <Suspense>
                      <ArrowBackIcon />
                    </Suspense>
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 600,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                    }}>
                    Select a payment method
                  </Typography>
                </Stack>
              )}
            />
          )}
          {view === 'checkout' && paymentMethod && (
            ({
              [PaymentMethodKeyEnum.WorldPayCNPCard]: (
                <PaymentProcessorWorldPay
                  purchase={purchase!}
                  playerProfile={playerProfile}
                  onClose={onClose}
                  onStart={onStart}
                  onFail={handlePaymentFailed}
                  onSuccess={handlePaymentSuccess}
                />
              ),
              [PaymentMethodKeyEnum.PaymentIQ]: (
                <PaymentProcessorPaymentIQ
                  partyId={partyId}
                  purchase={purchase!}
                  sessionKey={sessionKey}
                  onStart={onStart}
                  onClose={onClose}
                  onFail={handlePaymentFailed}
                  onSuccess={handlePaymentSuccess}
                />
              ),
              [PaymentMethodKeyEnum.Unipaas]: (
                <PaymentUnavailableMessage
                  paymentMethodName='Unipaas'
                  onBack={handlePaymentUnavailable}
                />
              ),
              [PaymentMethodKeyEnum.AptPay]: (
                <PaymentUnavailableMessage
                  paymentMethodName='AptPay'
                  onBack={handlePaymentUnavailable}
                />
              ),
            })[paymentMethod.key]
          )}
          {view === 'success' && (
            <CheckoutSuccessForm
              checkoutResult={checkoutResult}
              onClose={onClose}
              receiptFooterSlot={
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  rowGap={2}>
                  <Typography
                    sx={({ palette }) => ({
                      marginTop: 3,
                      fontSize: 18,
                      color: palette.common[100],
                    })}>
                    Receipt of your purchase
                  </Typography>
                  <PlayNowButton
                    inputProps={{
                      root: { sx: { width: '100%' } },
                      button: { color: 'success', variant: 'solid', }
                    }}
                  />
                </Stack>
              }
            />
          )}
          {view === 'failed' && (
            <CheckoutFailedForm
              paymentMethod={paymentMethod}
              checkoutResult={checkoutResult}
              onClose={onClose}
              onTryAgain={onClose}
              onBackupPayment={handlePaymentMethodSelection}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

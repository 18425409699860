import {
  lazy,
  useMemo,
  Suspense,
  type FC,
} from 'react';
import {
  Button,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency, isEmpty
} from '@shared/lib';
import {
  useGetPaymentMethodsQuery
} from '../api';
import {
  mapPaymentMethod
} from '../lib';
import type {
  PaymentMethod,
  CheckoutResult
} from '../model';
import {
  PaymentMethodsCard
} from './payment-methods-card.component';
import {
  PaymentMethodButton
} from './payment-method-button.component';

const InfoOutlinedIcon = lazy(() => import('@mui/icons-material/InfoOutlined'));

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  backgroundColor: theme.palette.common[475],
}));

const CardContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 24,
  [theme.breakpoints.down(490)]: {
    paddingTop: 16,
    paddingInline: 16,
    paddingBottom: 32,
  },
}));

const CardHeader = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: 24,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export type CheckoutFailedFormProps = {
  cardNumberSlot?: string;
  checkoutResult: Maybe<CheckoutResult>;
  paymentMethod: Maybe<PaymentMethod>;
  onClose?: () => void;
  onTryAgain?(amount: number): void;
  onBackupPayment?(paymentMethod: PaymentMethod): void;
};

export const CheckoutFailedForm: FC<CheckoutFailedFormProps> = ({
  cardNumberSlot = 'Card number',
  checkoutResult,
  paymentMethod,
  onBackupPayment,
  onTryAgain,
  onClose,
}) => {
  const joyTheme = useJoyTheme();
  const { isFetching, data: response } = useGetPaymentMethodsQuery(undefined, { refetchOnMountOrArgChange: true });

  const backupPaymentMethods: Maybe<Array<PaymentMethod>> = useMemo(() => {
    const paymentMethods = response?.data as unknown as Array<PaymentMethod>;
    if (isEmpty(paymentMethods)) return null;

    const filteredPaymentMethods = paymentMethods
      .map(paymentMethod => mapPaymentMethod(paymentMethod))
      .filter(({ key }: PaymentMethod) => key === paymentMethod?.backupPayment1 || key === paymentMethod?.backupPayment2);

    return isEmpty(filteredPaymentMethods) ? null : filteredPaymentMethods;
  }, [response?.data, paymentMethod]);

  const handleTryAgain = (): void => {
    const amount = checkoutResult?.amount
      ? Number(checkoutResult?.amount)
      : 0;
    onTryAgain?.(amount);
  };

  const handleSelect = (paymentMethod: PaymentMethod) => () => {
    onBackupPayment?.(paymentMethod);
  };

  return !isFetching && (
    <Container>
      <CardContent>
        <CardHeader>
          <Typography
            level='h3'
            fontSize='1.25rem'>
            Checkout
          </Typography>
          {onClose && (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </CardHeader>
        <Stack
          direction='column'
          justifyContent='space-between'
          width='100%'
          height='100%'>
          <Stack
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              rowGap: 2,
              marginBottom: 4
            }}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
              sx={({ palette }) => ({
                height: 78,
                width: '100%',
                padding: 2,
                columnGap: 1,
                backgroundColor: palette.common[900],
                borderRadius: 4,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: palette.common.error,
              })}>
              <Suspense>
                <InfoOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: joyTheme.palette.common.error
                  }}
                />
              </Suspense>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '23px',
                  color: palette.common.error,
                })}>
                The transaction failed. Please contact support, or try again.
              </Typography>
            </Stack>
            <Typography
              sx={({ palette }) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 44,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                color: palette.common.white,
                backgroundColor: palette.common[900],
                borderRadius: 8,
              })}>
              {formatCurrency(checkoutResult?.amount ?? 0)}
            </Typography>

            <Stack width='100%' rowGap={2}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={({ palette }) => ({
                  paddingBlock: 1,
                  width: '100%',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: palette.common[925],
                })}>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  {cardNumberSlot}
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  {checkoutResult?.cardNumber}
                </Typography>
              </Stack>

              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={({ palette }) => ({
                  paddingBlock: 1,
                  width: '100%',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  borderBottomColor: palette.common[925],
                })}>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  Purchase amount
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  })}>
                  {formatCurrency(checkoutResult?.amount ?? 0)}
                </Typography>
              </Stack>
              {checkoutResult?.referenceId && (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Reference ID
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.referenceId}
                  </Typography>
                </Stack>
              )}
              {checkoutResult?.paymentId && (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={({ palette }) => ({
                    paddingBlock: 1,
                    width: '100%',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: palette.common[925],
                  })}>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    Payment ID
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      color: palette.common.white,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    })}>
                    {checkoutResult?.paymentId}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack>
            {backupPaymentMethods && (
              <PaymentMethodsCard label='Have you tried using?'>
                {backupPaymentMethods.map(backupPaymentMethod => (
                  <PaymentMethodButton
                    key={backupPaymentMethod.id}
                    hint={backupPaymentMethod.displayName}
                    iconSrc={backupPaymentMethod.iconSrc}
                    onClick={handleSelect(backupPaymentMethod)}
                  />
                ))}
              </PaymentMethodsCard>
            )}
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              sx={{
                paddingBlock: 1,
              }}>
              <Divider
                orientation='horizontal'
                sx={{ width: 127 }}>
                or
              </Divider>
            </Stack>
            <Button
              fullWidth
              onClick={handleTryAgain}>
              Try again
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Container>
  );
};
import {
  useSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  sessionSlice,
  selectUserStatus,
  type SessionState
} from '@entities/session';
import {
  type GamesItem
} from '@entities/games';
import {
  promotionDetailsPageSlice
} from './slice';
import {
  type FaqBlock,
  type ActionItem,
  type HowItWorksBlock,
  type PromotionDetailsPageState,
  type PromotionDetailsPageUserCarouselSliderItem,
} from './types';

type RootState = {
  [promotionDetailsPageSlice.name]: PromotionDetailsPageState;
  [sessionSlice.name]: SessionState;
};

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.description
);

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.keywords
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.canonical
);

export const selectHowItWorksBlock = (state: RootState): Maybe<HowItWorksBlock> => (
  state[promotionDetailsPageSlice.name]?.howItWorksBlock
);

export const selectFaqBlock = (state: RootState): Maybe<Exclude<FaqBlock, 'title'>> => (
  state[promotionDetailsPageSlice.name]?.faqBlock
);

export const selectGames = (state: RootState): Maybe<Array<GamesItem>> => (
  state[promotionDetailsPageSlice.name]?.games
);

export const selectShouldRedirectToApp = (state: RootState): boolean => (
  state[promotionDetailsPageSlice.name]?.pageNotFound
);

export const selectAction = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<ActionItem>> => (
    state[promotionDetailsPageSlice.name]?.action
  ),
  (userStatus, action) => {
    const _action = action?.find(actionItem => actionItem.userState === userStatus);
    return _action;
  },
  { devModeChecks: { identityFunctionCheck: 'never' } }
);

export const selectSlider = createSelector(
  selectUserStatus,
  (state: RootState) => (state[promotionDetailsPageSlice.name]?.slider),
  (userState, slider) => {
    const _slider = slider as unknown as Maybe<Array<PromotionDetailsPageUserCarouselSliderItem & { actions: Array<ActionItem>, }>>;
    const sliderWithActionByUserStatus = _slider?.map(({ actions, ...props }) => {
      const actionByUserStatus = actions?.find(actionItem => actionItem.userState === userState);
      return ({ ...props, action: actionByUserStatus });
    });
    return sliderWithActionByUserStatus;
  },
  { devModeChecks: { identityFunctionCheck: 'never' } }
);

export const usePromotionDetailsPageSelector: TypedUseSelectorHook<RootState> = useSelector;


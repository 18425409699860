export type Header = {
  logo: {
    variant1SizeImgSrc: Url;
    variant2SizeImgSrc: Url;
  };
};

export type SocialMedia = {
  href: string;
  icon: string;
}

export type FollowUs = {
  title: string;
  socialMedia: SocialMedia[];
}

export type PageFooterNavigationItem = {
  title: string;
  items: Array<{ text: string; slug: string }>;
};

export type Footer = {
  logo: {
    variant1SizeImgSrc: Url;
  };
  followUs: FollowUs;
  promotionLegalNote: string;
  navigationItems: Array<PageFooterNavigationItem>;
  disclaimer: string;
};

export enum AuthVisibilityEnum {
  AuthorizedOnly = 'authorized-only',
  UnauthorizedOnly = 'unauthorized-only',
  AllUsers = 'all-users'
};

export type SidebarMenuItem = {
  id: number;
  text: string;
  slug: Url;
  iconSrc: Url;
  order: number,
  authVisibility?: AuthVisibilityEnum;
};

export type StickyNavigationItem = SidebarMenuItem & {
  centerButton: boolean;
};

export type SidebarMenu = {
  logo: {
    variant1SizeImgSrc: Url;
  };
  banner: {
    variant1SizeImgSrc: Url;
  };
  menuItems: Array<SidebarMenuItem>;
};

export type StickyNavigation = {
  menuItemsAuth: Array<StickyNavigationItem>;
  menuItemsNotAuth: Array<StickyNavigationItem>;
};

export type LayoutsState = {
  header: Maybe<Header>;
  footer: Maybe<Footer>;
  sidebarMenu: Maybe<SidebarMenu>;
  stickyNavigation: Maybe<StickyNavigation>;
  signInBanner: Maybe<Url>;
  signUpBanner: Maybe<Url>;

  nonAuthenticatedHeader: Maybe<Header>;
  nonAuthenticatedFooter: Maybe<Footer>;
  nonAuthenticatedSignInBanner: Maybe<Url>;
  nonAuthenticatedSignUpBanner: Maybe<Url>;
};

import {
  lazy,
  Suspense,
  type FC
} from 'react';
import {
  IconButton
} from '@mui/joy';
import {
  useLaunchGame
} from '../lib';

const PlayArrowRoundedIcon = lazy(() => import('@mui/icons-material/PlayArrowRounded'));

export type LaunchGameProps = {
  id: number;
};

// TODO: refactoring needed
export const LaunchGame: FC<LaunchGameProps> = ({ id }) => {
  const { launchGame } = useLaunchGame();

  const handlePlayGame = (): void => {
    launchGame(id);
  };

  return (
    <IconButton
      sx={(theme) => ({
        background: theme.colorSchemes.dark.palette.common[300],
        color: theme.colorSchemes.dark.palette.common.white,
        borderRadius: '50%',
        fontSize: '1.5rem',
        minWidth: '2rem',
        minHeight: '2rem',
      })}
      variant='solid'
      onClick={handlePlayGame}>
      <Suspense>
        <PlayArrowRoundedIcon />
      </Suspense>
    </IconButton>
  );
};

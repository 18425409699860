import {
  baseApi,
  DYNAMIC_PAGE_TAG
} from '@shared/api';
import {
  buildStrapiQuery,
  type Filter
} from '@shared/lib';
import {
  type TournamentDetailsPageState
} from '../model';
import {
  mapTournamentDetailsPageState
} from '../lib';

export type GetTournamentDetailsPageArgs = {
  filters: Filter;
  isKycPassed?: boolean;
  isAuthenticated?: boolean;
  isPlayer?: boolean;
};

export enum GetTournamentDetailsPageApiNameErrors {
  KycRequired = 'KycRequired',
  AuthRequired = 'AuthRequired',
  NotFoundError = 'NotFoundError',
  PlayerRegistrationRequired = 'PlayerRegistrationRequired'
};

export const tournamentDetailsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTournamentDetailsPage: build.query<TournamentDetailsPageState, GetTournamentDetailsPageArgs>({
      query: (args: GetTournamentDetailsPageArgs) => {
        const { filters, ...params } = args;
        return ({ url: `/api/tournament-details-pages?${buildStrapiQuery(filters)}`, params })
      },
      extraOptions: { showLoader: true },
      providesTags: [DYNAMIC_PAGE_TAG],
      transformResponse: (response: { data: unknown[] }) => {
        return mapTournamentDetailsPageState(response.data[0])
      },
      transformErrorResponse: (error: { data: { error: { name: GetTournamentDetailsPageApiNameErrors } } }) => {
        return error?.data?.error?.name || 'UnknownError';
      },
    })
  }),
});

export const {
  useGetTournamentDetailsPageQuery
} = tournamentDetailsPageApiSlice;

import {
  SESSION_TAG,
  baseOmegaApi,
} from '@shared/api';
import {
  type OmegaApiResponse
} from '@shared/api';
import {
  TransactionTypeEnum,
  type Transaction
} from '../model';

export type GetTransactionsRequest = {
  startDateTime: string;
  endDateTime: string;
  tranType: TransactionTypeEnum;
  pageNum: number;
  pageSize: number;
  sessionKey: string;
  currency: string;
};

export type GetTransactionsResponse = Partial<OmegaApiResponse> & {
  transactionList: Array<Transaction>;
  totalRecords: number;
};

export const transactionsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<GetTransactionsResponse, GetTransactionsRequest>({
      query: (args: GetTransactionsRequest) => ({ url: '/ips/getTransactionHistoryByCurrency', params: args }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: true, auth: true }
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery
} = transactionsApiSlicer;

import {
  type PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  gameWinnersApiSlice
} from '../api';
import {
  type GameWinnersState
} from './types';

export const initialState: GameWinnersState = {
  winners: [],
};

export const gameWinnersSlice = createSlice({
  name: 'game-winners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      gameWinnersApiSlice.endpoints.getGameWinnerList.matchFulfilled,
      (state: GameWinnersState, action: PayloadAction<Pick<GameWinnersState, 'winners'>>) => {
        state.winners = action.payload.winners;
      }
    );
  },
});

export const gameWinnersReducer = gameWinnersSlice.reducer;

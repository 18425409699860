import {
  lazy,
  memo,
  useState,
  Suspense,
  type FC,
} from 'react';
import {
  Tooltip
} from '@mui/joy';
import {
  IconButton
} from '@shared/ui';

const InfoOutlinedIcon = lazy(() => import('@mui/icons-material/InfoOutlined'));

export const CoinRedemptionRulesInfo: FC = memo(() => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const handleClose = (): void => {
    setOpenTooltip(false);
  };
  const handleOpen = (): void => {
    setOpenTooltip(true);
  };
  return (
    <Tooltip
      title='Amount of coins to be played through in a Promotional Game at least once'
      placement='bottom-start'
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      slotProps={{
        root: {
          open: openTooltip,
          sx: ({ zIndex, palette }) => ({
            borderRadius: 8,
            fontSize: 12,
            background: palette.common[925],
            boxShadow: 'none',
            paddingInline: 1.5,
            paddingBlock: 1,
            zIndex: zIndex.tooltip,
            maxWidth: 251
          })
        }
      }}>
      <IconButton
        size='sm'
        sx={{
          padding: 0,
          marginLeft: .6,
          borderRadius: 45,
          minHeight: 'min-content',
          minWidth: 'min-content',
        }}>
        <Suspense>
          <InfoOutlinedIcon sx={{ fontSize: 14 }} />
        </Suspense>
      </IconButton>
    </Tooltip>
  );
});
import {
  baseOmegaApi
} from '@shared/api';
import {
  mapGameWinners
} from '../lib';
import {
  type GameWinnersState
} from '../model';

export const gameWinnersApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getGameWinnerList: build.query<Pick<GameWinnersState, 'winners'>, void>({
      query: () => ({
        url: '/ips/getGameWinnerList', params: {
          period: 7,
          size: 50,
          domain: import.meta.env.VITE_DOMAIN,
          uniqueWinner: true,
        }
      }),
      extraOptions: { showLoader: false },
      transformResponse: (response: any) => mapGameWinners(response),
    }),
  }),
});

export const { useGetGameWinnerListQuery } = gameWinnersApiSlice;

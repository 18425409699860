import {
  useSelector,
  TypedUseSelectorHook
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  sessionSlice,
  selectShortProfile,
  type SessionState
} from '@entities/session';
import {
  claimBonusCoinsSlice,
  type ClaimBonusCoinsState,
  type ChestWithBonuses,
  type BonusConfig,
} from './slice';

type RootState = {
  [claimBonusCoinsSlice.name]: ClaimBonusCoinsState;
  [sessionSlice.name]: SessionState;
};

export const selectBonusConfig = (state: RootState): Maybe<BonusConfig> => {
  return state[claimBonusCoinsSlice.name]?.bonusConfig
};

export const selectBonusChests = (state: RootState): Maybe<Array<ChestWithBonuses>> => {
  return state[claimBonusCoinsSlice.name]?.chests
};

export const selectCanShowBonusConfig = createSelector(
  [selectShortProfile, selectBonusChests],
  (shortProfile, chests) => {
    const isSomeChestActive = chests?.some(chest => chest.active);

    return Boolean(shortProfile?.partyId && isSomeChestActive && chests?.length);
  }
);

export const useClaimBonusCoinsSelector: TypedUseSelectorHook<RootState> = useSelector;
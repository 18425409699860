import { styled } from '@mui/joy';
import { alpha } from '@mui/system';
import { Swiper as SwiperReact } from 'swiper/react';

export const Swiper = styled(SwiperReact)<{ roundedBorders?: boolean; }>(({ theme, roundedBorders = false }) => ({
  width: '100%',
  [theme.breakpoints.up(768)]: {
    height: 462.22,
    aspectRatio: '36/13',
    '.swiper-pagination-bullet': {
      height: '1rem',
      width: '1rem',
      backgroundColor: alpha(theme.colorSchemes.dark.palette.common.white, 0.4),
    },
  },
  [theme.breakpoints.down(768)]: {
    aspectRatio: '179/130',
    '.swiper-pagination-bullet': {
      display: 'none',
    },
    borderRadius: '0.5rem',
  },
  '.swiper-pagination-bullet': {
    backgroundColor: alpha(theme.colorSchemes.dark.palette.common.white, 0.4),
  },
  '.swiper-pagination-bullet-active': {
    backgroundColor: theme.colorSchemes.dark.palette.common.white,
  },
  ...(roundedBorders && { borderRadius: '0.5rem' })
}));

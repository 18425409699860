import {
  createSelector
} from '@reduxjs/toolkit';
import {
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux';
import {
  UserStateEnum,
  type CarouselSliderItem,
} from '@shared/types';
import {
  selectUserStatus
} from '@entities/session';
import {
  type GameCategory
} from '@entities/games';
import {
  lobbyPageSlice,
  type ActionItem,
  type LobbyPageState,
  type LobbyPageStepPlayItem,
  type LobbyPageTitlePlayInThreeSteps,
} from './slice';

type RootState = {
  [lobbyPageSlice.name]: LobbyPageState;
};

export const selectHomePageMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[lobbyPageSlice.name]?.keywords
);

export const selectHomePageMetaTagTitle = (state: RootState): Maybe<string> => (
  state[lobbyPageSlice.name]?.title
);

export const selectHomePageMetaTagDescription = (state: RootState): Maybe<string> => (
  state[lobbyPageSlice.name]?.description
);

export const selectHomePageMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[lobbyPageSlice.name]?.canonical
);

export const selectHomePageTitlePlayInThreeSteps = (state: RootState): Maybe<LobbyPageTitlePlayInThreeSteps> => (
  state[lobbyPageSlice.name]?.playInThreeSteps?.title
);

export const selectHomePageStepsPlayInThreeSteps = (state: RootState): Maybe<Array<LobbyPageStepPlayItem>> => (
  state[lobbyPageSlice.name]?.playInThreeSteps?.steps
);

export const selectSlider = createSelector(
  selectUserStatus,
  (state: RootState): Array<CarouselSliderItem> => (
    state[lobbyPageSlice.name]?.slider ?? []
  ),
  (userState, sliderItems) => {
    const result = sliderItems.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });
    return result;
  }
);

export const selectHomePageGamesByCategoriesBlockTop = (state: RootState): Maybe<Array<GameCategory>> => (
  state[lobbyPageSlice.name]?.gamesByCategoriesBlockTop
);

export const selectHomePageGamesByCategoriesBlockBottom = (state: RootState): Maybe<Array<GameCategory>> => (
  state[lobbyPageSlice.name]?.gamesByCategoriesBlockBottom
);

export const selectTournamentWidgetTitle = (
  state: RootState
): Maybe<string> => (
  state[lobbyPageSlice.name].tournamentWidgetTitle
);

export const selectAction = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<ActionItem>> => state[lobbyPageSlice.name]?.action,
  (userStatus: Maybe<UserStateEnum>, action: Maybe<Array<ActionItem>>) => {
    const currentAction = action?.find(actionItem => actionItem.userState === userStatus);
    return currentAction;
  },
  { devModeChecks: { identityFunctionCheck: 'never' } }
);

export const useLobbyPageSelector: TypedUseSelectorHook<RootState> = useSelector;

import {
  type FC,
} from 'react';
import {
  styled
} from '@mui/joy';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  BonusTriggerTypeEnum,
  type ChestWithBonuses,
} from '../model';
import {
  BonusChestItem
} from './bonus-chest-item.component';

export type BonusChestsListProps = {
  partyId: number;
  isFetching: boolean;
  chests: Array<ChestWithBonuses>;
  triggerType: BonusTriggerTypeEnum;
  onBuy?: (purchasesBonusPlanId: number) => void;
  onClaimedFailed?(message?: string): void;
  onClaimedSuccess?(message?: string): void;
};

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  height: '100%',
  width: '100%',

  ['&.swiper-wrapper']: {
    justifyContent: 'space-between'
  }
}));

const StyledSwiperSlide = styled(SwiperSlide)({
  width: 'fit-content',
  height: 'fit-content',
});

export const BonusChestsList: FC<BonusChestsListProps> = ({
  chests,
  partyId,
  isFetching,
  triggerType,
  onBuy,
  onClaimedFailed,
  onClaimedSuccess
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isTablet = useMediaQuery(theme.breakpoints.down(978));
  return (
    <StyledSwiper
      spaceBetween={8}
      slidesPerView={isMobile ? 'auto' : isTablet ? 2 : 3}>
      {chests.map((chest, index) => (
        <StyledSwiperSlide key={chest.purchasesBonusPlanId}>
          <BonusChestItem
            chest={chest}
            isFetching={isFetching}
            partyId={partyId}
            triggerType={triggerType}
            chestNumber={index + 1}
            onBuy={onBuy}
            onClaimedFailed={onClaimedFailed}
            onClaimedSuccess={onClaimedSuccess}
          />
        </StyledSwiperSlide>
      ))}
    </StyledSwiper>
  );
};
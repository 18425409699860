import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { useDispatch } from 'react-redux';
import { RouterNavigateOptions } from '@remix-run/router';
import { To } from 'react-router-dom';
import { selectIsAuthenticated, signOut, useLazyCheckLoggedInQuery, useSessionSelector } from '@entities/session';

const INTERVAL_TIME = 5 * 60 * 1000;

type CheckLoggedInProps = {
  children: ReactNode;
  navigate: (to: To | null, opts?: RouterNavigateOptions) => Promise<void>;
  debug?: boolean;
};

type WindowActivityEvent = keyof WindowEventMap;

export const CheckLoggedIn: FC<CheckLoggedInProps> = ({ children, navigate, debug = false }) => {
  const lastActivityRef = useRef<number>(new Date().getTime());
  const _storageKey = '_lastActivity';

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const [checkLoggedIn] = useLazyCheckLoggedInQuery();

  const isAuth = useSessionSelector(selectIsAuthenticated);

  const parseLastActivityString = useCallback((activityStr?: string | null) => {
    if (!activityStr) return null;
    const lastActivity = +activityStr;
    const now = activity();
    if (lastActivity == null || lastActivity > now || lastActivity <= 0 || isNaN(lastActivity)) {
      return null;
    }
    return lastActivity;
  }, []);

  const activity = (): number => {
    return new Date().getTime();
  };

  const onUserActivity = useCallback(() => {
    const now = activity();
    if (debug) console.log('activity - resetting last activity to ', now);
    window.localStorage.setItem(_storageKey, now.toString());
    lastActivityRef.current = now;
  }, [debug]);

  const onStorage = useCallback(
    ({ key, storageArea, newValue }: StorageEvent) => {
      if (key === _storageKey && storageArea === window.localStorage) {
        if (debug) console.log('remote tab activity - resetting last activity to ', newValue);
        const lastActivity = parseLastActivityString(newValue);
        if (lastActivity !== null) lastActivityRef.current = lastActivity;
      }
    },
    [debug, parseLastActivityString]
  );

  const isUserInactive = useCallback(async () => {
    if (!isAuth) return false;
    const now = activity();

    if (lastActivityRef.current + INTERVAL_TIME < now) {
      if (debug) {
        console.log('User inactive======', lastActivityRef.current, now);
      }
      const { data } = await checkLoggedIn();

      if (data?.loggedIn === 'TRUE') {
        return false;
      }

      dispatch(signOut());
      navigate('/?' + new URLSearchParams({ login: 'true' }).toString());
      return true;
    }

    return false;
  }, [checkLoggedIn, debug, dispatch, isAuth, navigate]);

  const onTimerElapsed = useCallback(() => {
    isUserInactive();
  }, [isUserInactive]);

  useEffect(() => {
    const windowEvents: WindowActivityEvent[] = ['focus', 'scroll', 'click', 'mousemove'];
    windowEvents.forEach((eventName) => {
      window.addEventListener(eventName, onUserActivity, false);
    });
    window.addEventListener('storage', onStorage, false);
    const intervalId = window.setInterval(onTimerElapsed, INTERVAL_TIME);

    return () => {
      windowEvents.forEach((eventName) => {
        window.removeEventListener(eventName, onUserActivity, false);
      });
      window.removeEventListener('storage', onStorage, false);
      window.clearInterval(intervalId);
    };
  }, [onTimerElapsed, onStorage, onUserActivity]);

  return children;
};

import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  gamesSlice
} from './slice';
import {
  FavoriteGame,
  GameCategory,
  type GamesState
} from './types';

type RootState = {
  [gamesSlice.name]: GamesState;
};

export const selectGameCategories = (state: RootState): Maybe<Array<GameCategory>> => (
  state[gamesSlice.name]?.categories
);

export const selectIsGameFavorite =
  (launchGameId: string, platformCode: string) =>
    (state: RootState): boolean => {
      const favoriteGames = state[gamesSlice.name].myGamesDataFromOmegaApi;
      return !!favoriteGames?.find(
        (favoriteGame: FavoriteGame) => favoriteGame.gameId === launchGameId && favoriteGame.platformCode === platformCode
      );
    };

export const selectIsFavoriteLoadingFromOmega = (state: RootState): boolean =>
  state[gamesSlice.name].isFavoriteLoadingFromOmega;

export const selectFavoriteGamesFromOmega = (state: RootState): Array<FavoriteGame> =>
  state[gamesSlice.name].myGamesDataFromOmegaApi;

export const selectGamesPageCategoryIds = (state: RootState): Array<number> => (
  state[gamesSlice.name].gamesPageCategoryIds ?? []
);

export const selectLastUpdated = (state: RootState): Maybe<string> => (
  state[gamesSlice.name].lastUpdated
);

export const useGamesSelector: TypedUseSelectorHook<RootState> = useSelector;

import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder
} from '@reduxjs/toolkit';
import {
  tournamentDetailsPageApiSlice,
  GetTournamentDetailsPageApiNameErrors
} from '../api';
import {
  type TournamentDetailsPageState
} from './types';

const initialState: TournamentDetailsPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  tournamentId: null,
  heading: null,
  slider: null,
  prizeGC: null,
  prizeSC: null,
  action: null,
  leaderTitle: null,
  htmlBlockTitle: null,
  htmlBlock: null,
  games: null,
};

export const tournamentDetailsPageSlice = createSlice({
  name: 'tournament-details-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TournamentDetailsPageState>) => {
    builder.addMatcher(
      tournamentDetailsPageApiSlice.endpoints.getTournamentDetailsPage.matchFulfilled,
      (state: TournamentDetailsPageState, action: PayloadAction<TournamentDetailsPageState>) => {
        state.title = action.payload.title;
        state.keywords = action.payload.keywords;
        state.description = action.payload.description;
        state.canonical = action.payload.canonical;
        state.tournamentId = action.payload.tournamentId;
        state.heading = action.payload.heading;
        state.slider = action.payload.slider;
        state.prizeGC = action.payload.prizeGC;
        state.prizeSC = action.payload.prizeSC;
        state.action = action.payload.action;
        state.leaderTitle = action.payload.leaderTitle;
        state.htmlBlockTitle = action.payload.htmlBlockTitle;
        state.htmlBlock = action.payload.htmlBlock;
        state.games = action.payload.games;
      }
    );
  },
});

export const tournamentDetailsPageReducer = tournamentDetailsPageSlice.reducer;

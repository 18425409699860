import {
  useSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  OMEGA_WS_PLAYER_INBOX_CHANNEL
} from '../config';
import {
  messagesSlice,
  type ChannelMessage,
  type MessagesState,
} from './slice';

type RootState = {
  [messagesSlice.name]: MessagesState;
};

export const selectMessagesInChannel = (channelId: string) => (state: RootState): Maybe<Array<ChannelMessage>> => (
  state[messagesSlice.name].channels[channelId]
);

export const selectMessages: (state: RootState) => Maybe<Array<ChannelMessage>> = createSelector(
  [selectMessagesInChannel(OMEGA_WS_PLAYER_INBOX_CHANNEL)],
  (messages: Maybe<Array<ChannelMessage>>): Maybe<Array<ChannelMessage>> => messages ? [...messages] : null
);

export const selectIsAnyUnreadMessage: (state: RootState) => boolean = createSelector(
  [selectMessagesInChannel(OMEGA_WS_PLAYER_INBOX_CHANNEL)],
  (messages: Maybe<Array<ChannelMessage>>): boolean => (
    !!(messages ?? []).find((message: ChannelMessage) => !message.isRead)
  )
);

export const selectOpenNotificationSidebar = (state: RootState): boolean => (
  state[messagesSlice.name].openNotificationSidebar
);

export const useMessagesSelector: TypedUseSelectorHook<RootState> = useSelector;

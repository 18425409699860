import {
  lazy,
  memo,
  Fragment,
  Suspense,
  type FC,
  type ReactNode,
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Stack,
  Divider
} from '@mui/joy';
import {
  Button,
  IconButton
} from '@shared/ui';
import {
  UserAvatarWithNickname
} from '@entities/session';

const CloseIcon = lazy(() => import('@mui/icons-material/Close'));
const AppLogo = lazy(() => import('@shared/ui').then((module) => ({ default: module.AppLogo })));

export type SidebarMenuHeaderProps = {
  isAuthenticated?: boolean;
  sidebarMenuLogoSrc?: Url;
  notificationsSidebarButtonSlot: ReactNode;
  onClose?: () => void;
};

export const SidebarMenuHeader: FC<SidebarMenuHeaderProps> = memo(({
  isAuthenticated = false,
  sidebarMenuLogoSrc,
  notificationsSidebarButtonSlot,
  onClose,
}) => {
  const navigate = useNavigate();
  const handleRedirectToSignIn = (): void => {
    navigate('/login');
  };
  const handleRedirectToSignUp = (): void => {
    navigate('/sign-up');
  };
  return (
    <Fragment>
      {/* Mobile */}
      <Stack
        direction='row'
        alignItems='center'
        sx={({ palette, breakpoints }) => ({
          display: 'none',
          justifyContent: sidebarMenuLogoSrc
            ? 'space-between'
            : 'flex-end',
          [breakpoints.down(491)]: {
            paddingTop: 3,
            paddingBottom: 2,
            display: 'flex',
            paddingInline: 2,
            backgroundColor: palette.common[475]
          },
        })}>
        {sidebarMenuLogoSrc && (
          <Suspense>
            <AppLogo
              src={sidebarMenuLogoSrc}
              sx={{
                width: 60,
                height: 30,
              }}
            />
          </Suspense>
        )}
        <Stack
          direction='row'
          gap={1}>
          {isAuthenticated ? (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              gap={2}>
              <UserAvatarWithNickname onClick={onClose} />
              {notificationsSidebarButtonSlot}
            </Stack>
          ) : (
            <Stack
              direction='row'
              alignItems='center'
              gap={1}>
              <Button
                color='primary'
                size='sm'
                sx={{ fontWeight: 700 }}
                onClick={handleRedirectToSignIn}>
                Log in
              </Button>
              <Button
                color='success'
                size='sm'
                sx={{ fontWeight: 700 }}
                onClick={handleRedirectToSignUp}>
                Sign Up
              </Button>
              <Divider orientation='vertical' />
            </Stack>
          )}
          <IconButton
            size='sm'
            onClick={onClose}
            sx={{
              blockSize: 'fit-content',
              marginLeft: 1
            }}>
            <Suspense>
              <CloseIcon
                sx={({ palette }) => ({
                  fontSize: 24,
                  color: palette.common.white
                })}
              />
            </Suspense>
          </IconButton>
        </Stack>
      </Stack>

      {/* Desktop */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
        sx={({ breakpoints }) => ({
          paddingTop: 3,
          paddingInline: 2,
          [breakpoints.down(491)]: {
            display: 'none',
          },
        })} >
        <IconButton
          size='sm'
          sx={{ blockSize: 'fit-content' }}
          onClick={onClose}>
          <Suspense>
            <CloseIcon sx={{ fontSize: 24 }} />
          </Suspense>
        </IconButton>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            width: '100%',
            height: isAuthenticated ? 32 : 60,
          }}>
          {sidebarMenuLogoSrc && (
            <Suspense>
              <AppLogo
                src={sidebarMenuLogoSrc}
                sx={{
                  width: isAuthenticated ? 60 : 118,
                  height: isAuthenticated ? 30 : 60,
                  marginLeft: isAuthenticated ? 2 : -4,
                }}
              />
            </Suspense>
          )}
          {isAuthenticated && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              width='100%'
              gap={2}>
              <UserAvatarWithNickname onClick={onClose} />
              {notificationsSidebarButtonSlot}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Fragment>
  )
});
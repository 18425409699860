import {
  lazy,
  memo,
  Fragment,
  Suspense,
  type FC,
  type ReactNode
} from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Box,
  Drawer,
  Divider,
  Stack,
  Typography
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  List,
  ListItemButton,
  ListItemDecorator
} from '@shared/ui';
import {
  isEmpty,
  useModalQueryParam
} from '@shared/lib';
import {
  resetStore
} from '@shared/store-reset';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  AuthenticatedOnlyContent,
  selectHasSCForbiddenTag,
  selectKycStatus,
  selectRegistrationStatus,
  UserKycStatusEnum
} from '@entities/session';
import {
  useLayoutSelector,
  selectSidebarMenuBanner,
  selectSidebarMenuLogo,
  SidebarMenuBanner,
} from '@entities/layouts';
import {
  useLazySignOutQuery,
  selectIsAuthenticated,
  useSessionSelector,
} from '@entities/session';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  useLiveChat
} from '@features/contact-live-support';
import {
  VipProgressBar
} from '@features/loyalty-progress-bar';
import {
  BalancesButtons
} from '@features/toggle-coins';
import {
  useSidebarMenuSelector,
  selectIsOpenSidebarMenu,
  selectMenuItems,
  closeSidebarMenu
} from '../model';
import {
  SidebarMenuHeader
} from './sidebar-menu-header.component';

const PersonIcon = lazy(() => import('@mui/icons-material/Person'));
const ChatIcon = lazy(() => import('@mui/icons-material/Chat'));
const InfoIcon = lazy(() => import('@mui/icons-material/Info'));
const LogoutIcon = lazy(() => import('@mui/icons-material/Logout'));
const RedeemIcon = lazy(() => import('@mui/icons-material/Redeem'));

export type SidebarMenuProps = {
  notificationsSidebarButtonSlot: ReactNode;
};

export const SidebarMenu: FC<SidebarMenuProps> = memo(({ notificationsSidebarButtonSlot }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = useModalQueryParam();
  const liveChat = useLiveChat();
  const [signOut, { isFetching }] = useLazySignOutQuery();
  const { sendDataToGtm } = useSendDataToGtm();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const hasSCForbiddenTag = useSessionSelector(selectHasSCForbiddenTag);
  const kycStatus = useSessionSelector(selectKycStatus);
  const userRegistrationStatus = useSessionSelector(selectRegistrationStatus);
  const isOpen = useSidebarMenuSelector(selectIsOpenSidebarMenu);
  const sidebarMenuBannerSrc = useLayoutSelector(selectSidebarMenuBanner);
  const sidebarMenuLogoSrc = useLayoutSelector(selectSidebarMenuLogo);
  const sidebarMenuItems = useSelector(selectMenuItems);

  const navigateTo = (slug: string): void => {
    dispatch(closeSidebarMenu());
    navigate(slug);
  };

  const handleClose = (): void => {
    dispatch(closeSidebarMenu());
  };

  const handleNavigateTo = (slug: Url) => (): void => {
    if (slug === '/redeem')
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_click',
          paymentMethod: 'Trustly',
        },
      });
    navigateTo(slug);
    dispatch(closeSidebarMenu());
  };

  const handleSignOut = async (): Promise<void> => {
    await signOut();
    liveChat?.logoutChat();
    dispatch(resetStore());
    navigate('/login');
  };

  const handleContactLiveSupport = (): void => {
    openModal('chat');
    dispatch(closeSidebarMenu());
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      slotProps={{
        content: {
          sx: ({ breakpoints, palette }) => ({
            width: 390,
            backgroundColor: palette.common[475],
            [breakpoints.down(491)]: {
              backgroundColor: palette.common[900],
              width: '100%',
            },
          }),
        },
      }}
      sx={{ zIndex: 15 }}>
      <SidebarMenuHeader
        isAuthenticated={isAuthenticated}
        sidebarMenuLogoSrc={sidebarMenuLogoSrc}
        notificationsSidebarButtonSlot={notificationsSidebarButtonSlot}
        onClose={handleClose}
      />
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          gap: 2,
          paddingBlock: 3,
          paddingInline: 2,
          [breakpoints.down(491)]: {
            gap: 2,
            paddingTop: 2,
          },
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            gap: 2,
            [breakpoints.down(491)]: { gap: 2 },
          })}>
          {isAuthenticated ? (
            <Fragment>
              <VipProgressBar />
              <BalancesButtons />
            </Fragment>
          ) : (
            sidebarMenuBannerSrc && (
              <SidebarMenuBanner imgSrc={sidebarMenuBannerSrc} />
            )
          )}
          {isAuthenticated && <Divider />}
          <List
            size='lg'
            sx={{
              gap: 0.25,
              paddingBlock: 0,
            }}>
            {sidebarMenuItems.map(({ id, text, slug, iconSrc }) => (
              <ListItemButton
                disabled={isFetching}
                key={id}
                variant={isMobile ? 'plain' : 'soft'}
                onClick={handleNavigateTo(slug)}
                sx={({ palette, breakpoints }) => ({
                  gap: 1,
                  [breakpoints.down(491)]: {
                    backgroundColor: palette.common[475]
                  },
                })}>
                <ListItemDecorator>
                  <Box
                    component='img'
                    loading='lazy'
                    src={iconSrc}
                    sx={{ width: 22 }}
                  />
                </ListItemDecorator>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    color: palette.common.white,
                  })}>
                  {text}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Stack>
        {!isEmpty(sidebarMenuItems) && <Divider />}
        <List
          size='lg'
          sx={{
            gap: 0.25,
            paddingBlock: 0,
          }}>
          <AuthenticatedOnlyContent>
            <Fragment>
              <ListItemButton
                disabled={isFetching}
                onClick={handleNavigateTo('/account')}
                variant={isMobile ? 'plain' : 'soft'}
                sx={({ palette, breakpoints }) => ({
                  gap: 1,
                  [breakpoints.down(491)]: {
                    backgroundColor: palette.common[475],
                  },
                })}>
                <ListItemDecorator>
                  <Suspense>
                    <PersonIcon sx={{ fontSize: 22 }} />
                  </Suspense>
                </ListItemDecorator>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    color: palette.common[150],
                  })}>
                  Account
                </Typography>
              </ListItemButton>
              {(kycStatus === UserKycStatusEnum.Pass && userRegistrationStatus === UserRegistrationStatusEnum.Player && !hasSCForbiddenTag) && (
                <ListItemButton
                  disabled={isFetching}
                  onClick={handleNavigateTo('/redeem')}
                  variant={isMobile ? 'plain' : 'soft'}
                  sx={({ palette, breakpoints }) => ({
                    gap: 1,
                    [breakpoints.down(491)]: {
                      backgroundColor: palette.common[475],
                    },
                  })}>
                  <ListItemDecorator>
                    <Suspense>
                      <RedeemIcon sx={{ fontSize: 22 }} />
                    </Suspense>
                  </ListItemDecorator>
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      color: palette.common[150],
                    })}>
                    Redeem
                  </Typography>
                </ListItemButton>
              )}
              <ListItemButton
                disabled={isFetching}
                onClick={handleNavigateTo('/how-it-works')}
                variant={isMobile ? 'plain' : 'soft'}
                sx={({ palette, breakpoints }) => ({
                  gap: 1,
                  [breakpoints.down(491)]: {
                    backgroundColor: palette.common[475],
                  },
                })}>
                <ListItemDecorator>
                  <Suspense>
                    <InfoIcon sx={{ fontSize: 22 }} />
                  </Suspense>
                </ListItemDecorator>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    color: palette.common[150],
                  })}>
                  How it works
                </Typography>
              </ListItemButton>
            </Fragment>
          </AuthenticatedOnlyContent>
          <ListItemButton
            disabled={isFetching}
            variant={isMobile ? 'plain' : 'soft'}
            onClick={handleContactLiveSupport}
            sx={({ palette, breakpoints }) => ({
              gap: 1,
              [breakpoints.down(491)]: {
                backgroundColor: palette.common[475],
              },
            })}>
            <ListItemDecorator>
              <Suspense>
                <ChatIcon sx={{ fontSize: 22 }} />
              </Suspense>
            </ListItemDecorator>
            <Typography
              sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common[150],
              })}>
              Live Support
            </Typography>
          </ListItemButton>
          {isAuthenticated && (
            <ListItemButton
              variant='plain'
              disabled={isFetching}
              onClick={handleSignOut}
              sx={{ gap: 1 }}>
              <ListItemDecorator>
                <Suspense>
                  <LogoutIcon sx={{ fontSize: 22 }} />
                </Suspense>
              </ListItemDecorator>
              <Typography
                sx={({ palette }) => ({
                  fontSize: 16,
                  color: palette.common[150],
                })}>
                Log out
              </Typography>
            </ListItemButton>
          )}
        </List>
      </Stack>
    </Drawer>
  );
});

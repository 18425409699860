import {
  useSelector,
  TypedUseSelectorHook
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  sessionSlice,
  SessionState,
  selectKycStatus,
  UserKycStatusEnum,
  selectIsAuthenticated,
  selectRegistrationStatus,
} from '@entities/session';
import {
  type RedeemCoinsState,
  redeemCoinsSlice
} from './slice';

type RootState = {
  [sessionSlice.name]: SessionState;
  [redeemCoinsSlice.name]: RedeemCoinsState;
};

export const selectIsWithdrawCoinsAllowed = createSelector([
  selectIsAuthenticated, selectRegistrationStatus, selectKycStatus
],
  (isAuthenticated, registrationStatus, kycStatus): boolean => {
    const allowRedeemCoins =
      registrationStatus === UserRegistrationStatusEnum.Player
      && kycStatus === UserKycStatusEnum.Pass
      && isAuthenticated;
    return allowRedeemCoins;
  })

export const selectCoinRedeem = (state: RootState): number => (
  state[redeemCoinsSlice.name].coinRedeem as unknown as number
);

export const useRedeemCoinsSelector: TypedUseSelectorHook<RootState> = useSelector;
import {
  lazy,
  memo,
  Suspense,
  type FC
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';

const SearchIcon = lazy(() => import('@mui/icons-material/SearchRounded'));

export const NoSearchResultsFound: FC = memo(() => {
  const theme = useTheme();
  return (
    <Stack
      alignItems='center'
      marginInline='auto'
      sx={{
        gap: '1.5rem',
        paddingBlock: '2.5rem',
      }}>
      <Suspense>
        <SearchIcon
          sx={{
            fontSize: '3.9375rem',
            color: theme.palette.common[475]
          }}
        />
      </Suspense>
      <Stack gap='0.5rem'>
        <Typography
          sx={({ palette }) => ({
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '1.125rem',
            letterSpacing: '0em',
            textAlign: 'center',
            color: palette.common.white,
          })}>
          Sorry, no results found
        </Typography>
        <Typography
          sx={({ palette }) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.125rem',
            letterSpacing: '0em',
            textAlign: 'center',
            color: palette.common.white,
          })}>
          Please, try searching for another game
        </Typography>
      </Stack>
    </Stack>
  );
});
import {
  type FC,
} from 'react';
import {
  Typography
} from '@mui/joy';

export const AptPayRestrictions: FC = () => (
  <Typography
    sx={({ palette }) => ({
      gridColumnStart: 1,
      gridColumnEnd: 3,
      padding: 2,
      borderRadius: 8,
      fontWeight: 400,
      lineHeight: '150%',
      width: '100%',
      color: palette.common[150],
      backgroundColor: palette.common[900],
      textAlign: 'justify',
    })}>
    Redemption requests are only supported via ACH bank transfers. Bank statement must be issued in your name.
    <br />
    Accounts outside the US are not supported at this time
  </Typography>
);
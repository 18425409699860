import { isDefined } from '../common';
import { get } from './get';
import { initObject } from './init-object';

interface MapperOption {
  name: string;
  from?: string | string[];
  transform?: (value: any, originalPayload: any) => any;
  shouldApply?: {
    condition: (value: any, obj: any) => boolean;
    field: string;
  };
}

const getMultiple = (obj: any, paths: string[]): any[] => {
  return paths.map(path => get(obj, path));
};

const getNewObjectFromPath = (value: any, newKey: string): Record<string, any> =>
  isDefined(value) ? initObject(newKey, value) : {};

const getTransformedObject = (
  transformFn: (value: any, originalPayload: any) => any,
  value: any,
  key: string,
  originalPayload: any
): Record<string, any> => initObject(key, transformFn(value, originalPayload));

const handleObjectMapperOption = (obj: any, mapperOption: MapperOption): Record<string, any> => {
  const path = mapperOption.from || mapperOption.name;
  const newKey = mapperOption.name;
  const value = Array.isArray(path) ? getMultiple(obj, path) : get(obj, path);

  if (value === undefined && mapperOption.from && !mapperOption.transform) {
    return {};
  }

  if (mapperOption.shouldApply) {
    const { condition, field } = mapperOption.shouldApply;
    const conditionValue = get(obj, field);
    if (
      (field && conditionValue === undefined) ||
      !condition(conditionValue, obj)
    )
      return {};
  }

  if (mapperOption.transform) {
    return getTransformedObject(mapperOption.transform, value, newKey, obj);
  }

  return getNewObjectFromPath(value, newKey);
};

export const mapper = <T extends object>(mapperOptions: MapperOption[]): (mapperObject: any) => T => {
  return (mapperObject: any): T =>
    mapperOptions.reduce((mappedOutput: any, mapperOption: MapperOption) => {
      if (!mapperOption) return mappedOutput;

      switch (typeof mapperOption) {
        case 'object':
          return { ...mappedOutput, ...handleObjectMapperOption(mapperObject, mapperOption) };
        case 'string':
          return { ...mappedOutput, ...getNewObjectFromPath(get(mapperObject, mapperOption), mapperOption) };
        default:
          return mappedOutput;
      }
    }, {} as T);
};

import {
  lazy,
  Fragment,
  Suspense,
  type FC,
} from 'react';
import {
  Stack,
  Divider,
  Typography,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  FollowUsBlock,
  useLayoutSelector,
  NavigationSection,
  selectPageFooterFollowUs,
  selectPageFooterDisclaimer,
  selectPageFooterNavigationItems,
  selectPageFooterPromotionLegalNote,
  selectPageFooterVariant1LogoImgSrc,
} from '@entities/layouts';

const AppLogo = lazy(() => import('@shared/ui').then((module) => ({ default: module.AppLogo })));

export type PageFooterProps = {
  allowOnlyForDesktop?: boolean;
  allowOnlyForTabletAndDesktop?: boolean;
};

export const PageFooter: FC<PageFooterProps> = ({
  allowOnlyForDesktop = false,
  allowOnlyForTabletAndDesktop = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.up(600));
  const isDesktop = useMediaQuery(theme.breakpoints.up(912));

  const followUs = useLayoutSelector(selectPageFooterFollowUs);
  const navigationItems = useLayoutSelector(selectPageFooterNavigationItems);
  const variant1LogoImgSrc = useLayoutSelector(selectPageFooterVariant1LogoImgSrc);
  const promotionLegalNote = useLayoutSelector(selectPageFooterPromotionLegalNote);
  const disclaimer = useLayoutSelector(selectPageFooterDisclaimer);

  const shouldDisplayFooter =
    (!allowOnlyForDesktop && !allowOnlyForTabletAndDesktop) ||
    (!allowOnlyForDesktop && isDesktop) ||
    (!allowOnlyForTabletAndDesktop && (isDesktop || isTablet));

  return shouldDisplayFooter && (
    <Stack
      component='footer'
      gap={3}
      sx={({ palette, breakpoints }) => ({
        width: '100%',
        maxWidth: 1280,
        paddingBlock: 6,
        backgroundColor: palette.common[475],
        [breakpoints.down(913)]: {
          paddingBlock: 3,
        },
      })}>
      <Stack
        direction='column'
        gap={2}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%',
            ...(isMobile && ({
              flexDirection: 'column',
              gap: 1,
            }))
          }}>
          {variant1LogoImgSrc && (
            <Suspense>
              <AppLogo
                src={variant1LogoImgSrc}
                sx={{
                  width: 210,
                  height: 35.4,
                  boxSizing: 'content-box',
                }}
              />
            </Suspense>
          )}
          <FollowUsBlock
            title={followUs?.title ?? ''}
            socialMedia={followUs?.socialMedia ?? []}
          />
        </Stack>
        {isMobile ? (
          <Fragment>
            <Stack direction='column' gap={2}>
              <NavigationSection data={navigationItems} />
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
            </Stack>
            <Stack
              direction='column'
              gap={2}
              width='100%'>
              <Typography
                sx={({ palette }) => ({
                  width: 'fit-content',
                  borderRadius: 8,
                  padding: .5,
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  color: palette.common[300],
                  backgroundColor: palette.common[900],
                })}>
                +21
              </Typography>
              <Divider />
              <Stack
                alignItems='flex-start'
                gap={2}
                width='100%'>
                {disclaimer && (
                  <Typography
                    sx={({ palette }) => ({
                      maxWidth: 677,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      color: palette.common[150]
                    })}>
                    {disclaimer}
                  </Typography>
                )}
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common[150]
                  })}>
                  &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                </Typography>
              </Stack>
            </Stack>
          </Fragment>
        ) : (
          <Fragment>
            <Stack
              direction='column'
              gap={2}>
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
              <NavigationSection data={navigationItems} />
            </Stack>
            <Stack
              direction='column'
              gap={4}
              width='100%'>
              <Divider />
              <Stack width='100%' gap={2}>
                {disclaimer && (
                  <Typography
                    sx={({ palette }) => ({
                      maxWidth: 677,
                      fontSize: 14,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px',
                      color: palette.common[150]
                    })}>
                    {disclaimer}
                  </Typography>
                )}
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  width='100%'>
                  <Typography
                    sx={({ palette }) => ({
                      fontSize: 16,
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: palette.common[150]
                    })}>
                    &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                  </Typography>
                  <Typography
                    sx={({ palette }) => ({
                      borderRadius: 8,
                      padding: .5,
                      fontSize: 16,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      color: palette.common[300],
                      backgroundColor: palette.common[900],
                    })}>
                    +21
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        )}
      </Stack>
    </Stack>
  );
};
import {
  memo,
  type FC
} from 'react';
import {
  Avatar,
  Stack,
  Typography,
  styled
} from '@mui/joy';
import {
  CoinPackageVariantEnum
} from '../lib';
import {
  goldCoinIcon,
  silverCoinIcon,
} from '../assets';
import {
  FreeSweepCoinLabel
} from './free-sweep-coin-label.component';

const CoinPackageLabel = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 'normal'
}));

export type CoinPackageSummaryProps = {
  smallLabel?: boolean;
  redeemableCoinAmount: number;
  nonRedeemableCoinAmount: number;
  variant: CoinPackageVariantEnum;
};

export const CoinPackageSummary: FC<CoinPackageSummaryProps> = memo(({
  variant,
  smallLabel,
  redeemableCoinAmount,
  nonRedeemableCoinAmount
}) => (
  <Stack
    sx={{
      gap: 1,
      ...(variant === CoinPackageVariantEnum.MostPopular && ({
        justifyContent: 'space-between',
        gap: 2,
      }))
    }}>
    {nonRedeemableCoinAmount && (
      <Stack
        direction='row'
        alignItems='center'
        gap={1}>
        <Avatar
          src={goldCoinIcon.default}
          sx={{ width: 16, height: 16 }}
        />
        <CoinPackageLabel>
          {nonRedeemableCoinAmount}
        </CoinPackageLabel>
      </Stack>
    )}
    {redeemableCoinAmount && (
      <Stack
        direction='row'
        alignItems='center'
        gap={1}>
        <Avatar
          src={silverCoinIcon.default}
          sx={{ width: 16, height: 16 }}
        />
        <CoinPackageLabel>
          {redeemableCoinAmount}
        </CoinPackageLabel>
        <FreeSweepCoinLabel
          variant={variant}
          smallLabel={smallLabel}
        />
      </Stack>
    )}
  </Stack>
));
import {
  useState,
  type FC,
  type ChangeEvent,
} from 'react'
import {
  createPortal
} from 'react-dom';
import dayjs from 'dayjs';
import {
  Box,
  Stack,
  Modal,
  Button,
  Checkbox,
  Typography,
  ModalDialog,
  type ModalProps
} from '@mui/joy';
import {
  ConsentKeyEnum
} from '../lib';
import {
  useGetPublicConsentsQuery
} from '../api';

export type PublicConsentsConfirmationModalProps = Omit<ModalProps, 'children'> & {
  allowed?: Array<ConsentKeyEnum>;
  onConsentIdsConfirmed?(consentVersionIds: Maybe<Array<number>>): void;
};

export const PublicConsentsConfirmationModal: FC<PublicConsentsConfirmationModalProps> = ({
  onConsentIdsConfirmed,
  allowed = Object.keys(ConsentKeyEnum),
  ...props
}) => {
  const { isFetching, data: consents } = useGetPublicConsentsQuery(undefined, { refetchOnMountOrArgChange: true });

  const allowedConsents = consents?.filter(({ key }) => allowed.includes(key));
  const [checked, setChecked] = useState<Record<number, boolean>>({});
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleChange = (consentVersionId: number) => (e: ChangeEvent<HTMLInputElement>): void => {
    const newSelectedConsents = { ...checked, [consentVersionId]: e.target.checked };
    setChecked(newSelectedConsents);

    if (allowedConsents) {
      const areAllConsentsChecked = allowedConsents?.every(({ consentVersionId }) => newSelectedConsents[consentVersionId]);
      if (areAllConsentsChecked) setDisabled(false);
      else setDisabled(true);
    }
  };

  const handleConsentsConfirmed = (): void => {
    if (allowedConsents) {
      const checkedConsentsIds = allowedConsents.reduce((acc, { consentVersionId, ...consentOtherProps }) => {
        if (checked[consentVersionId]) {
          acc.push(consentVersionId);
          return acc;
        } else {
          return acc.filter(id => id !== consentVersionId);
        }
      }, new Array<number>());
      onConsentIdsConfirmed?.(checkedConsentsIds);
    }
  };

  const handleNavigateToConsentsDescription = (key: ConsentKeyEnum) => (): void => {
    window.open(`${window.location.origin}/${key}`, '_blank');
  };

  const modalRoot = document.getElementById('modal-root')
  return modalRoot && createPortal((
    <Modal {...props}>
      <ModalDialog
        layout='center'
        sx={{
          width: '100%',
          maxWidth: 340,
        }}>
        <Stack
          sx={{
            gap: 2,
            padding: 3,
            width: '100%',
            height: '100%',
          }}>
          <Typography
            level='h3'
            fontSize={18}>
            Terms of use update {dayjs().format('YYYY-MM-DD')}
          </Typography>
          <Typography fontSize={14}>
            Our policies have been updated in order to improve your experience with SweepLuxe.
            Please review and accept the following policies:
          </Typography>
          <Stack
            gap={1}
            direction='column'>
            {allowedConsents?.map(({ id, key, name, version, consentVersionId }) => (
              <Stack
                key={id}
                gap={1}
                direction='row'
                alignItems='flex-start'>
                <Checkbox
                  disabled={isFetching}
                  checked={checked[consentVersionId] ?? false}
                  onChange={handleChange(consentVersionId)}
                  sx={{
                    gap: 1,
                    height: 36,
                    textAlign: 'justify',
                  }}
                />
                <Typography>
                  I have read and agree with&nbsp;&nbsp;
                  <Box
                    component='span'
                    onClick={handleNavigateToConsentsDescription(key)}
                    sx={({ palette }) => ({
                      cursor: 'pointer',
                      color: palette.common[300],
                      ':hover': { color: palette.common[350] }
                    })}>
                    {`${name} v${version}`}
                  </Box>
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Button
            fullWidth
            loading={isFetching}
            disabled={isFetching || disabled}
            onClick={handleConsentsConfirmed}>
            Confirm
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  ), modalRoot);
};
import {
  lazy,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  Suspense,
  type ReactElement,
} from 'react';
import {
  CountryData,
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input as JoyInput,
  InputProps as JoyInputProps,
  Select,
  Option,
  Typography,
} from '@mui/joy';

const ArrowDropUpIcon = lazy(() => import('@mui/icons-material/ArrowDropUp'));
const ArrowDropDownIcon = lazy(() => import('@mui/icons-material/ArrowDropDown'));

export type SupportedCountries = Omit<
  CountryIso2,
  'us' | 'ca' | 'mx' | 'br' | 'ee' | 'ua' | 'bg'
>;
export interface PhoneInputProps
  extends Omit<JoyInputProps, 'error' | 'value' | 'onChange'> {
  label?: string;
  error?: string;
  value?: string;
  startDecorator?: ReactElement;
  defaultCountry?: SupportedCountries;
  onChange?: (phone: Phone) => void;
};

/**
 * This component might trigger the warning: "A FormControl can contain only one control component." 
 * As detailed in issue #37764 on the MUI Joy UI GitHub repository, this warning is acknowledged and 
 * is currently classified as an enhancement for future updates, rather than a critical bug. It can be safely ignored. 
 * 
 * For more details, refer to the issue: [GitHub Issue #37764](https://github.com/mui/material-ui/issues/37764).
 */

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({
  onChange,
  onBlur,
  label,
  name,
  value,
  endDecorator,
  defaultCountry,
  error,
  color = 'neutral',
  disabled,
  ...props
}: PhoneInputProps, ref) => {
  const [isListboxOpen, setIsListboxOpen] = useState<boolean>(false);
  const [selectedIso2Country, setSelectedIso2Country] = useState<Maybe<SupportedCountries>>(defaultCountry);

  const countries: CountryData[] = useMemo(() => defaultCountries.filter((country: CountryData) => {
    const { iso2 } = parseCountry(country);
    return ['us', 'ca', 'mx', 'br', 'ee', 'ua', 'bg'].includes(iso2);
  }), []);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: defaultCountry as CountryIso2,
      value,
      countries,
      onChange: ({ phone, country: { iso2 } }) => {
        setSelectedIso2Country(iso2);
        onChange?.(phone);
      },
    });

  const handleSelectCounty = (event: any, value: any): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setCountry(value as CountryIso2, { focusOnInput: true });
  };

  const handleListboxOpenChanged = (isOpen: boolean): void => {
    setIsListboxOpen(isOpen);
  };

  useEffect(() => {
    setSelectedIso2Country(country.iso2);
  }, [country]);

  return (
    <FormControl
      disabled={disabled}
      error={!!error}
      sx={{ minHeight: 90 }}>
      {label && (
        <FormLabel sx={{ marginBottom: 0.5 }}>
          {label}
        </FormLabel>
      )}
      <JoyInput
        name={name}
        value={inputValue}
        error={!!error}
        color={color}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handlePhoneValueChange}
        slotProps={{
          input: {
            id: name,
            ref: ref = inputRef,
          }
        }}
        startDecorator={(
          <Select
            value={selectedIso2Country}
            onChange={handleSelectCounty}
            onListboxOpenChange={handleListboxOpenChanged}
            indicator={(
              <Suspense>
                {isListboxOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Suspense>
            )}
            slotProps={{
              root: {
                sx: ({ palette }) => ({
                  border: 'none',
                  padding: 0,
                  marginBlock: '-7px',
                  color: palette.common.white,
                  backgroundColor: palette.common[475],
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: palette.common[475],
                  },
                })
              },
              listbox: {
                sx: ({ colorSchemes }) => ({
                  zIndex: 99999,
                  borderRadius: 12,
                  border: 'none',
                  background: colorSchemes.dark.palette.background.body,
                  boxShadow: `0px 0px 16px 0px ${colorSchemes.dark.palette.common[300]}`,
                  borderColor: colorSchemes.dark.palette.common[475],
                  borderWidth: 1,
                })
              },
            }}
            renderValue={() => (
              <FlagImage iso2={country.iso2} />
            )}>
            {countries.map((country) => {
              const { iso2, name, dialCode } = parseCountry(country);
              return (
                <Option
                  key={iso2}
                  value={iso2 || ''}
                  sx={({ colorSchemes }) => ({
                    '&.MuiOption-highlighted': {
                      backgroundColor: colorSchemes.dark.palette.common[475],
                    },
                    '&.MuiOption-root:hover': {
                      backgroundColor: colorSchemes.dark.palette.common[575],
                    },
                    '&.MuiOption-root:active': {
                      backgroundColor: colorSchemes.dark.palette.common[475],
                    },
                  })}>
                  <FlagImage
                    key={iso2}
                    iso2={iso2}
                    style={{ marginRight: '8px' }}
                  />
                  <Typography>{name}</Typography>
                  <Typography>+{dialCode}</Typography>
                </Option>
              )
            })}
          </Select>
        )}
        endDecorator={endDecorator}
        {...props}
      />
      {(error) && (
        <FormHelperText sx={{ marginTop: 0.5 }}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
});
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/joy';
import { selectFullProfile, useSessionSelector } from '../model';
import { truncateUsername } from '../lib';
import { LoggedUserVipLevel } from './logged-user-vip-level.component';

export type UserAvatarWithNicknameProps = {
  onClick?: () => void;
};

export const UserAvatarWithNickname: FC<UserAvatarWithNicknameProps> = ({ onClick }) => {
  const navigate = useNavigate();
  const user = useSessionSelector(selectFullProfile);

  const handleNavigateToProfile = (): void => {
    onClick?.();
    navigate('/account');
  };

  return (
    <Stack direction='row' alignItems='center' gap={1} onClick={handleNavigateToProfile}>
      <LoggedUserVipLevel onClick={onClick} />
      <Typography
        sx={({ palette }) => ({
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 500,
          lineWeight: '20px',
          color: palette.common.white,
          ':hover': {
            cursor: 'pointer',
          },
        })}
      >
        {user && user?.nickname ? truncateUsername(user?.nickname, 10) : 'Nickname'}
      </Typography>
    </Stack>
  );
};

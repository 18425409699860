import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  dynamicPageSlice
} from './slice';
import {
  type DynamicPageState,
  type DynamicPageContentEnum,
  type DynamicPageContent
} from './types';

type RootState = {
  [dynamicPageSlice.name]: DynamicPageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[dynamicPageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[dynamicPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[dynamicPageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[dynamicPageSlice.name]?.canonical
);

export const selectDynamicPageContentType = (state: RootState): Maybe<DynamicPageContentEnum> => (
  state[dynamicPageSlice.name]?.contentType
);

export const selectDynamicPageContent = (state: RootState): Maybe<DynamicPageContent> => (
  state[dynamicPageSlice.name]?.content
);

export const selectShouldRedirectToApp = (state: RootState): boolean => (
  state[dynamicPageSlice.name]?.pageNotFound || state[dynamicPageSlice.name]?.authenticationRequired
);

export const selectShouldConfirmationIdentity = (state: RootState): boolean => (
  state[dynamicPageSlice.name]?.kycRequired || state[dynamicPageSlice.name]?.playerRegistrationRequired
);

export const selectShouldRenderPage = createSelector(
  [selectShouldRedirectToApp, selectShouldConfirmationIdentity],
  (shouldRedirectToApp, shouldConfirmationIdentity) => !shouldRedirectToApp && !shouldConfirmationIdentity
);

export const useDynamicPageSelector: TypedUseSelectorHook<RootState> = useSelector;
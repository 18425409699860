import {
  type FC
} from 'react'
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  useDispatch
} from 'react-redux';
import {
  styled,
  Stack,
} from '@mui/joy'
import {
  selectUserBalance,
  useLazyBalanceQuery,
  useSessionSelector
} from '@entities/session';
import {
  PaymentMethod,
  storeRecentlyUsedPaymentMethod
} from '@entities/payments';
import {
  storeCoinRedeem,
  resetCoinRedeem,
  selectCoinRedeem,
  CoinRedemptionForm,
  useRedeemCoinsSelector,
  CoinRedemptionCheckoutForm
} from '@features/redeem-coins'
import {
  useSendDataToGtm
} from '@features/collect-analytics';

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 16,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  [theme.breakpoints.down(491)]: {
    padding: 0,
  }
}));

export const WithdrawCoinsPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getBalanceQuery] = useLazyBalanceQuery();
  const { sendDataToGtm } = useSendDataToGtm();
  const [searchParams] = useSearchParams();

  const userBalance = useSessionSelector(selectUserBalance);
  const coinRedeem = useRedeemCoinsSelector(selectCoinRedeem);

  const isCheckout = searchParams.get('checkout') === 'true';

  const handleNavigateToLobby = (): void => {
    navigate('/lobby', { replace: true });
  };

  const handleNavigateToWithdrawCoins = (): void => {
    dispatch(resetCoinRedeem());
    navigate('/redeem', { replace: true });
  };

  const handleCoinRedeem = (amount: string | number): void => {
    dispatch(storeCoinRedeem(amount));
    navigate('/redeem?checkout=true', { replace: true });
  };

  const handleStart = (amount: string | number): void => {
    sendDataToGtm({
      dataLayer: {
        event: 'redeem_start',
        withdrawalAmount: amount,
        withdrawalCurrency: 'USD',
        withdrawalCount: '1',
        paymentMethod: 'Trustly'
      }
    })
  };

  const handleSuccess = async (result: {
    redeemAmount: Maybe<number>;
    paymentMethod: Maybe<PaymentMethod>;
  }): Promise<void> => {
    await getBalanceQuery();

    if (result?.paymentMethod) {
      dispatch(storeRecentlyUsedPaymentMethod(result?.paymentMethod));
    }

    if (result?.redeemAmount) {
      const withdrawalAmount = result.redeemAmount;
      const paymentMethod = result.paymentMethod?.displayName ?? 'Unknown';
      const event = 'redeem_success';
      sendDataToGtm({
        dataLayer: {
          event,
          withdrawalAmount,
          paymentMethod
        }
      });
    }
  };

  const handleRedeemFail = async (result: {
    redeemAmount: Maybe<number>;
    paymentMethod: Maybe<PaymentMethod>;
  }): Promise<void> => {

    await getBalanceQuery();

    if (result?.redeemAmount) {
      const event = 'redeem_fail';
      const paymentMethod = result.paymentMethod?.displayName ?? 'Unknown';
      sendDataToGtm({ dataLayer: { event, paymentMethod } });
    }
  };

  return (
    <Container>
      {isCheckout ? (
        <Stack
          sx={({ breakpoints, palette }) => ({
            gap: 24,
            maxWidth: 488,
            width: '100%',
            borderRadius: 12,
            overflow: 'hidden',
            backgroundColor: palette.common[475],
            [breakpoints.down(491)]: {
              width: '100%',
              maxWidth: '100%',
              borderRadius: 0,
              height: '100%',
            },
          })}>
          <CoinRedemptionCheckoutForm
            onClose={handleNavigateToWithdrawCoins}
            onStart={handleStart}
            onFailed={handleRedeemFail}
            onSuccess={handleSuccess}
            redeemAmount={coinRedeem}
          />
        </Stack>
      ) : (
        <Stack
          sx={({ breakpoints }) => ({
            margin: 2,
            maxWidth: 358,
            width: '100%',
            height: 'fit-content',
            borderRadius: 12,
            overflow: 'hidden',
            [breakpoints.down(491)]: {
              margin: 0,
              height: '100%',
              maxWidth: '100%',
              borderRadius: 0,
            }
          })}>
          <CoinRedemptionForm
            onClose={handleNavigateToLobby}
            onCoinRedeem={handleCoinRedeem}
            bonusBalance={userBalance?.bonusBalanceNumber ?? 0}
            withdrawableBalance={userBalance?.withdrawableBalanceNumber ?? 0}
          />
        </Stack>
      )}
    </Container>
  );
};
import {
  Component,
  type ComponentType
} from 'react';
import {
  Provider
} from 'react-redux';
import {
  PersistGate
} from 'redux-persist/integration/react';
import {
  resetActiveRequests
} from '@shared/ui';
import {
  store,
  persister
} from './store';

export const withStore = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return class StoreProvider extends Component<P> {
    render() {
      store.dispatch(resetActiveRequests());
      return (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persister}>
            <WrappedComponent {...this.props} />
          </PersistGate>
        </Provider>
      );
    }
  };
};

import {
  PaymentMethodKeyEnum
} from '@shared/types';

export type PaymentMethod = {
  id: number;
  displayName: string;
  iconSrc: Url;
  key: PaymentMethodKeyEnum;
  backupPayment1: PaymentMethodKeyEnum;
  backupPayment2: PaymentMethodKeyEnum;
};

export enum PaymentMethodOrderSetTypeEnum {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
};

export type PaymentMethodOrderSet = {
  id: number;
  name: string;
  type: PaymentMethodOrderSetTypeEnum;
  allowedTags: Array<string>;
  forbiddenTags: Array<string>;
  recommended: Array<PaymentMethod>;
  alternative: Array<PaymentMethod>;
};

export type CheckoutResult = {
  cardNumber?: string;
  amount: string;
  referenceId?: string;
  paymentId?: string;
  paymentMethod?: PaymentMethodKeyEnum;
};

export type PaymentsState = {
  paymentMethod: Maybe<PaymentMethod>;
  paymentMethodOrderSet: Array<PaymentMethodOrderSet>;
  type: Maybe<PaymentMethodOrderSetTypeEnum>;
  recentlyUsedPaymentMethod: Maybe<PaymentMethod>;
};

import {
  mapper
} from '@shared/lib';

export type LocationInfoByZipCode = {
  postCode: string;
  country: string;
  countryAbbreviation: string;
  city: string;
  longitude: string;
  state: string;
  stateAbbreviation: string;
  latitude: string;
};

type MapLocationInfoByZipCode = (data: any) => LocationInfoByZipCode;
export const mapLocationInfoByZipCode: MapLocationInfoByZipCode = mapper([
  { name: 'longitude', from: 'longitude' },
  { name: 'latitude', from: 'latitude' },

  {
    name: 'city',
    from: 'places',
    transform: (places: Array<{ ['place name']: string }>) => {
      return places[0]['place name']
    }
  },
  { name: 'postCode', from: 'post code' },
  { name: 'country', from: 'country' },
  { name: 'countryAbbreviation', from: 'country abbreviation' },
  {
    name: 'state',
    from: 'places',
    transform: (places: Array<{ state: string }>) => {
      return places[0]?.state
    }
  },
  {
    name: 'stateAbbreviation',
    from: 'places',
    transform: (places: Array<{ ['state abbreviation']: string }>) => {
      return places[0]['state abbreviation']
    }
  },
]);
import {
  baseOmegaApi
} from '@shared/api';
import {
  CurrencyCodesEnum
} from '@shared/types';
import {
  type BonusInfo
} from '../model';
import {
  mapBonusInfo,
} from '../lib';

export type GetBonusesParams = {
  endDate?: string;
  startDate?: string;
  currency?: CurrencyCodesEnum
};

export const bonusInfoApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: build => ({
    getBonuses: build.query<Array<BonusInfo>, Maybe<GetBonusesParams>>({
      query: (params?: Maybe<GetBonusesParams>) => ({ url: '/ips/getBonuses', ...(params && { params }) }),
      extraOptions: { auth: true },
      transformResponse: (response: { bonuses: Array<unknown> }) => {
        return response.bonuses.map(bonus => mapBonusInfo(bonus));
      }
    })
  })
});

export const {
  useGetBonusesQuery,
  useLazyGetBonusesQuery
} = bonusInfoApiSlice;

import {
  memo,
  type FC,
} from 'react';
import {
  Sheet,
  Stack,
  Link,
  styled
} from '@mui/joy';
import {
  Button
} from '@shared/ui';
import {
  CoinPackage,
  CoinPackageVariantEnum
} from '../lib';
import {
  CoinPackageTitle
} from './coin-package-title.component';
import {
  CoinPackageSummary
} from './coin-package-summary.component';

const Container = styled(Stack)<{ variant: CoinPackageVariantEnum; large?: boolean; }>(({
  variant,
  large,
  theme
}) => ({
  width: '100%',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  height: 'fit-content',
  borderRadius: 8,
  paddingTop: 40,
  paddingBottom: 11,
  paddingInline: 16,
  background: theme.palette.common[900],
  ...(variant === CoinPackageVariantEnum.MostPopular && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common[700]}`,
  })),
  ...(variant === CoinPackageVariantEnum.WelcomeRed && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common.error}`,
  })),
  ...(variant === CoinPackageVariantEnum.WelcomeYellow && ({
    boxShadow: `0px 0px 8px .5px ${theme.palette.common[1085]}`,
  })),
  ...(large && {
    paddingTop: 50,
    paddingBottom: 27,
    background: theme.palette.common[565],
    [theme.breakpoints.down(491)]: {
      background: variant !== CoinPackageVariantEnum.MostPopular
        ? theme.palette.common[475]
        : 'inherit',
    },
  }),
}));

const Content = styled(Sheet)(() => ({
  display: 'grid',
  gap: '5px',
  gridTemplateColumns: '1fr fit-content(100%)',
  background: 'transparent',
}));

export type LimitedCoinPackageCardProps = {
  data: CoinPackage;
  large?: boolean;
  isClaimed?: boolean;
  onPurchase: (coinPackage: CoinPackage) => void;
};

export const LimitedCoinPackageCard: FC<LimitedCoinPackageCardProps> = memo(({
  isClaimed,
  large,
  data,
  onPurchase
}) => {

  const {
    title,
    price,
    variant,
    currency,
    promoTAndCApplyUrl,
    redeemableCoinAmount,
    nonRedeemableCoinAmount
  } = data;

  const handlePurchase = (): void => {
    onPurchase(data);
  };

  return (
    <Container
      large={large || undefined}
      variant={variant || undefined}
      onClick={handlePurchase || undefined}>
      <CoinPackageTitle
        text={title}
        variant={variant}
      />
      <Content>
        <CoinPackageSummary
          variant={variant}
          redeemableCoinAmount={redeemableCoinAmount}
          nonRedeemableCoinAmount={nonRedeemableCoinAmount}
        />
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='space-around'
          gap={1}>
          <Button
            color={
              variant === CoinPackageVariantEnum.MostPopular
                ? 'success'
                : 'primary'}
            sx={{ minWidth: 146, }}>
            {isClaimed
              ? `${currency} ${price} Claim Now`
              : `${currency} ${price} Buy`}
          </Button>
          {promoTAndCApplyUrl && (
            <Link
              fontSize={12}
              color='neutral'
              underline='none'
              lineHeight='normal'
              justifyContent='center'
              href={promoTAndCApplyUrl}
              target='_blank'
              onClick={(e) => e.stopPropagation()}>
              Promo T&Cs Apply
            </Link>
          )}
        </Stack>
      </Content>
    </Container>
  );
});
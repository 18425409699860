import { FC } from 'react';
import {
  Stack,
  Typography,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/joy';
import { HtmlBlock } from '@shared/ui';

export type FaqBlockProps = {
  title?: Maybe<string>;
  items?: Maybe<Array<{
    summary: string;
    details: string;
  }>>;
};

export const FaqBlock: FC<FaqBlockProps> = ({ title, items }) => (
  <Stack
    direction='column'
    alignItems='flex-start'
    justifyContent='center'
    sx={{
      gap: 2,
      width: '100%',
    }}>
    {title && (
      <Typography sx={({ colorSchemes }) => ({
        width: '100%',
        fontSize: 28,
        fontWeight: 600,
        lineHeight: '36x',
        letterSpacing: 0,
        textAlign: 'start',
        color: colorSchemes.dark.palette.common.white
      })}>
        {title}
      </Typography>
    )}
    <AccordionGroup
      sx={{ width: '100%' }}>
      {items?.map(({ summary, details }) => (
        <Accordion
          key={summary}
          sx={({ colorSchemes }) => ({
            borderColor: colorSchemes.dark.palette.common[200]
          })}>
          <AccordionSummary
            sx={({ colorSchemes }) => ({
              paddingBlock: 1.25,
              paddingInline: 2,
              '&:hover': {
                backgroundColor: colorSchemes.dark.palette.common[575],
                '& .MuiAccordionSummary-button:not(.Mui-selected, [aria-selected="true"])': {
                  backgroundColor: colorSchemes.dark.palette.common[575]
                },
              },
              '&:active': {
                backgroundColor: colorSchemes.dark.palette.common[475],
                '& .MuiAccordionSummary-button:not(.Mui-selected, [aria-selected="true"])': {
                  backgroundColor: colorSchemes.dark.palette.common[475],
                },
              },
              '&.Mui-expanded': {
                paddingBlockEnd: 2.25,
              },
              '& .MuiAccordionSummary-indicator svg': {
                fontSize: 24,
                color: colorSchemes.dark.palette.common[300]
              },
            })}>
            <Typography sx={({ colorSchemes }) => ({
              fontSize: 18,
              fontWeight: 500,
              lineHeight: '27x',
              letterSpacing: 0,
              textAlign: 'start',
              color: colorSchemes.dark.palette.common.white
            })}>
              {summary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingInline: 2 }}>
            <HtmlBlock html={details} />
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionGroup>
  </Stack>
);
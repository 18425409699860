import { DynamicPageContentEnum } from '../model';
import { FaqContent } from '../ui/faq-content.component';
import { HtmlContent } from '../ui/html-content.component';
import { ContactUs } from '../ui/contact-us-content.component';

export const dynamicPagesMapper = {
  [DynamicPageContentEnum.Faq]: FaqContent,
  [DynamicPageContentEnum.ContactUs]: ContactUs,
  [DynamicPageContentEnum.Html]: HtmlContent,
  [DynamicPageContentEnum.NotLoaded]: null
};
import {
  FC,
  useMemo
} from 'react'
import {
  useSelector
} from 'react-redux';
import {
  Typography,
  LinearProgress,
  Stack,
  Skeleton
} from '@mui/joy';
import {
  CurrentVIPLevelEnum,
  selectCurrentVIPLevel,
  useGetLoyaltyPointsQuery,
  useSessionSelector
} from '@entities/session';
import {
  mapNextLevel
} from '../lib';
import {
  selectCurrentLoyaltyProgress,
} from '../model';

// TODO: refactoring needed
export const VipProgressBar: FC = () => {
  const { isFetching } = useGetLoyaltyPointsQuery();

  const currentVIPLevel = useSessionSelector(selectCurrentVIPLevel);
  const currentLoyaltyProgress = useSelector(selectCurrentLoyaltyProgress);

  const nextLevel = useMemo(() => mapNextLevel(currentVIPLevel), [currentVIPLevel]);
  const allowToShow = useMemo(() => currentVIPLevel !== CurrentVIPLevelEnum.Level8, [currentVIPLevel]);

  return allowToShow && (
    <Stack
      sx={({ palette, breakpoints }) => ({
        backgroundColor: palette.common[900],
        paddingBlock: '0.75rem',
        paddingLeft: '1rem',
        paddingRight: '1.75rem',
        width: '100%',
        borderRadius: '0.5rem',
        gap: '0.5rem',
        [breakpoints.down(491)]: {
          backgroundColor: palette.common[475],
        }
      })}>
      <Stack
        direction='row'
        justifyContent='space-between'>
        <Typography
          sx={({ palette }) => ({
            color: palette.common[300],
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            fontWeight: 600,
          })}>
          {isFetching ? <Skeleton variant='text' width='5rem' /> : currentVIPLevel}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            color: palette.common[150],
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            fontWeight: 400,
          })}>
          {isFetching ? <Skeleton variant='text' width='5rem' /> : nextLevel}
        </Typography>
      </Stack>
      <LinearProgress
        sx={({ palette, breakpoints }) => ({
          backgroundColor: palette.common[475],
          [breakpoints.down(491)]: {
            backgroundColor: palette.common[900],
          }
        })}
        determinate
        size='lg'
        value={currentLoyaltyProgress}
      />
    </Stack>
  );
};
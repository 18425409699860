import {
  FC,
  useState
} from 'react';
import {
  Stack,
  Skeleton
} from '@mui/joy';
import {
  useToaster
} from '@shared/ui';
import {
  PaymentMethodKeyEnum
} from '@shared/types';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  CheckoutResult,
  CheckoutAptPayForm
} from '@entities/payments';
import {
  PrepareWithdrawalResponse,
  ProcessWithdrawalResponse,
  useLazyCancelWithdrawalQuery,
  useLazyPrepareWithdrawalQuery,
  useLazyProcessWithdrawalQuery,
  useGetPaymentInformationQuery
} from '../api';
import {
  AptPayRestrictions
} from './aptpay-restrictions.component';

export type ConfirmCoinRedemptionAptPayFormProps = {
  redeemAmount: number
  onClose?: () => void;
  onStart?: (amount: string | number) => void;
  onSuccess?: (redeemResult?: CheckoutResult) => void;
  onFail?: (redeemResult?: CheckoutResult) => void;
};

export const ConfirmCoinRedemptionAptPayForm: FC<ConfirmCoinRedemptionAptPayFormProps> = ({
  redeemAmount,
  onClose,
  onStart,
  onFail,
  onSuccess
}) => {
  const toast = useToaster();

  const [prepareWithdrawalQuery] = useLazyPrepareWithdrawalQuery();
  const [processWithdrawalQuery] = useLazyProcessWithdrawalQuery();
  const [cancelWithdrawal] = useLazyCancelWithdrawalQuery();

  const { data: paymentInformationData, isFetching: isPaymentInformationLoading } = useGetPaymentInformationQuery({ type: 'BANK' }, { refetchOnMountOrArgChange: true });
  const [processWithdrawal, setProcessWithdrawal] = useState<Maybe<Partial<ProcessWithdrawalResponse>>>(null);

  const handleRedeemSuccess = async (redeemResult?: {
    bankName: string;
    bankAccountNumber: string;
    bankTransitNumber: string;
    bankAccountType: 'checking' | 'saving';
    paymentInformationId?: UniqueId;
  }): Promise<void> => {
    onStart?.(redeemAmount);

    const paymentMethod = PaymentMethodKeyEnum.AptPay;
    const successResponses = [OmegaApiResponseStatusEnum.Success, OmegaApiResponseStatusEnum.ContinueToPaymentSite];

    const prepareWithdrawalResponse = await prepareWithdrawalQuery({ paymentMethod, action: 'GET_EXCHANGE_INFO' });
    const { status: prepareWithdrawalStatus, conversionId } = prepareWithdrawalResponse.data as PrepareWithdrawalResponse;

    if (!successResponses.includes(prepareWithdrawalStatus)) {
      toast.error({ message: prepareWithdrawalStatus, autoHideDuration: 5000 });
      await handleClose();
      return;
    }

    const processWithdrawalResponse = await processWithdrawalQuery({
      paymentMethod,
      amount: redeemAmount,
      conversionId,
      ...redeemResult
    });
    const { status: processWithdrawalStatue, ...processWithdrawal } = processWithdrawalResponse.data as ProcessWithdrawalResponse;

    if (!successResponses.includes(processWithdrawalStatue)) {
      toast.error({ message: processWithdrawalStatue, autoHideDuration: 5000 });
      await handleClose();
      onFail?.({
        amount: String(redeemAmount),
        cardNumber: redeemResult?.bankAccountNumber,
        paymentMethod
      });
      return;
    } else {
      setProcessWithdrawal(processWithdrawal);
      onSuccess?.({
        amount: String(redeemAmount),
        paymentId: String(processWithdrawal.paymentId),
        cardNumber: redeemResult?.bankAccountNumber,
        paymentMethod
      });
    }
  };

  const handleClose = async (): Promise<void> => {
    await cancelWithdrawal({ paymentId: processWithdrawal?.paymentId });
    onClose?.();
  };

  return (
    <Stack
      sx={({ breakpoints, palette }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 488,
        backgroundColor: palette.common[925],
        [breakpoints.down(491)]: {
          borderRadius: 0,
          boxShadow: 'none',
        },
      })}>
      {isPaymentInformationLoading ? (
        <Skeleton
          variant="rectangular"
          height='500px'
          width='100%'
        />
      ) : (
        <CheckoutAptPayForm
          amount={redeemAmount}
          existingPaymentInformation={
            paymentInformationData?.filter(({ paymentMethod }) => paymentMethod === PaymentMethodKeyEnum.AptPay)
          }
          receiptFooterSlot={<AptPayRestrictions />}
          onClose={handleClose}
          onCheckoutSuccess={handleRedeemSuccess}
        />
      )}
    </Stack>
  );
};
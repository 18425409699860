import {
  lazy,
  memo,
  Suspense,
  type FC
} from 'react'
import {
  Stack,
  Typography,
  useTheme
} from '@mui/joy';

const CancelOutlinedIcon = lazy(() => import('@mui/icons-material/CancelOutlined'));
const CheckCircleOutlineIcon = lazy(() => import('@mui/icons-material/CheckCircleOutline'));

type VerificationStatusProps = {
  isVerified: boolean;
};

export const VerificationStatus: FC<VerificationStatusProps> = ({ isVerified }) => {
  const theme = useTheme();
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      gap={.5}>
      <Suspense>
        {isVerified
          ? <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.common[825] }} />
          : <CancelOutlinedIcon sx={{ fontSize: 16, color: theme.palette.common.error }} />}
      </Suspense>
      <Typography
        sx={({ palette }) => ({
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          color: isVerified ? palette.common[825] : palette.common.error
        })}>
        {isVerified ? 'Verified' : 'Not verified'}
      </Typography>
    </Stack>
  );
};
export enum OmegaApiResponseStatusEnum {
  Fail = 'FAIL',
  Success = 'SUCCESS',
  NotFound = 'NOT_FOUND',
  FailUnPw = 'FAIL_UN_PW',
  ValidationFail = 'VALIDATION_FAIL',
  NotLoggedIn = 'NOT_LOGGED_IN_ERROR',
  InvalidPassword = 'INVALID_PASSWORD',
  PackageNotFond = 'PACKAGE_NOT_FOUND',
  FailQuickOpenStatus = 'FAIL_QUICK_OPEN_STATUS',
  ContinueToPaymentSite = 'CONTINUE_TO_PAYMENT_SITE',
};

export enum ResponseMessageEnum {
  Success = 'Success'
};

export type OmegaApiResponse = {
  message?: Maybe<string>;
  advices?: Array<unknown>;
  additionalInfo?: Array<unknown>;
  status: OmegaApiResponseStatusEnum;
  errors?: Array<{ field: string, error: string }>;
};

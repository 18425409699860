import {
  useSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  tournamentsSlice
} from './slice';
import {
  type Tournament,
  type TournamentsState,
  type TournamentRanking,
} from './types';

type RootState = {
  [tournamentsSlice.name]: TournamentsState;
};

export const selectIsLoading = (state: RootState): boolean => {
  return state[tournamentsSlice.name].isLoading;
};
export const selectTournaments = (state: RootState): Array<Tournament> => {
  return state[tournamentsSlice.name].publicTournaments;
};
export const selectTournamentRankings = (state: RootState): Array<TournamentRanking> => {
  return state[tournamentsSlice.name].tournamentRankings;
};

export const useTournamentsSelector: TypedUseSelectorHook<RootState> =
  useSelector;

import {
  baseOmegaApi
} from '@shared/api';
import {
  OMEGA_CHRONOS_API
} from '../config';

export type GetMessagesHistoryPayload = {
  partyId: string;
  sessionKey: string;
  channel: string;
  size: number;
};

export const messagesApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getMessagesHistory: build.query<any, GetMessagesHistoryPayload>({
      query: (payload: GetMessagesHistoryPayload) => ({
        url: `${OMEGA_CHRONOS_API}user/${payload.partyId}/message`,
        params: { size: payload.size, channels: payload.channel },
        headers: { sessionKey: payload.sessionKey },
      }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});
